<template>
  <div
    class="view view_Dashboard"
    v-if="doRender"
  >
    <viewTabsTop v-if="tabs.length > 1" :tabs="tabs" />
    <!-- CONTENT -->
    <v-container
      v-if="offline"
    >
      <v-alert
        dense transition="scale-transition"
        type="error"
        class="ma-2"
        style="font-size:80%;"
      >
        <b>KEINE NETZWERKVERBINDUNG</b>
        <br>
        Die angezeigten Daten können veraltet sein.
        Bevor Änderungen vorgenommen werden, vergewissern Sie sich, dass die Daten aktuell sind.
      </v-alert>
    </v-container>
    <v-progress-linear
      indeterminate
      v-if="loading"
      absolute
    ></v-progress-linear>
    <transition
      v-if="
        currentTab.code === ''
        && tabs.filter((o) => o.erlaubt === 'x|000|000').length > 0
      "
      name="custom-classes-transition"
      :enter-active-class="transitionIn"
      :leave-active-class="transitionOut"
    >
      <Widgets
        v-if="save.xtime"
        :prop_settings="save.settings"
        :prop_widgets="save.widgets"
      />
    </transition>
    <transition
      v-else-if="
        currentTab.code === 'set'
        && tabs.filter((o) => o.erlaubt === 'x|000|set').length > 0
      "
      name="custom-classes-transition"
      :enter-active-class="transitionIn"
      :leave-active-class="transitionOut"
    >
      <Einstellungen
        v-if="save.xtime"
        :prop_settings="save.settings"
        :prop_widgets="save.widgets"
        @savesettings="tryGetOrSaveSettings($event);"
      />
    </transition>
    <p v-else>
      Zugriff verweigert
    </p>
    <!-- CONTENT -->
    <viewTabsBottom v-if="tabs.length > 1" :tabs="tabs" />
  </div>
</template>

<script>
import 'animate.css';
import $ from 'jquery';
import ViewTabsTop from '@/components/ViewTabsTop.vue';
import ViewTabsBottom from '@/components/ViewTabsBottom.vue';
import Einstellungen from '@/components/Funktionen/000/000-Dashboard/Einstellungen.vue';
import Widgets from '@/components/Funktionen/000/000-Dashboard/Widgets.vue';

export default ({
  name: 'Dashboard',
  components: {
    ViewTabsTop,
    ViewTabsBottom,
    Einstellungen,
    Widgets,
  },
  data: () => ({
    loading: false,
    offline: false,
    save: {},
    doRender: false,
    tabs: [],
    mytabs: [
      {
        icon: 'mdi-view-dashboard-outline',
        title: 'Dashboard',
        route: '/000',
        erlaubt: 'x|000|000',
      },
      {
        icon: 'mdi-tune',
        title: 'Einstellungen',
        route: '/000-set',
        erlaubt: 'x|000|set',
      },
    ],
    currentTab: {
      code: '',
      query: {},
    },
  }),
  computed: {
    transitionIn() {
      let result = '';
      if (this.$vuetify.breakpoint.mdAndDown) {
        result = 'animate__animated animate__fadeInLeft';
      }
      return result;
    },
    transitionOut() {
      let result = '';
      if (this.$vuetify.breakpoint.mdAndDown) {
        result = 'animate__animated animate__fadeOutLeft';
      }
      return result;
    },
  },
  methods: {
    async tryGetOrSaveSettings(dataToSave) {
      if (dataToSave) {
        this.$store.commit('main/switchIsSaving', true);
      }
      this.loading = true;
      if (localStorage.getItem('f000')) {
        const help = JSON.parse(localStorage.getItem('f000'));
        if (help.save) {
          this.save = help.save;
          // this.loading = false;
        }
      }
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/000/000/?func=getSettings`,
        type: 'post',
        data: {
          code: this.$store.state.login.session.code,
          dataToSave: JSON.stringify(dataToSave),
        },
        success: (response) => {
          if (response.status === 'accessError') {
            this.$store.commit('login/backendErrorSession');
          } else {
            this.offline = false;
            this.save.xtime = response.save.xtime;
            this.save.settings = response.save.settings;
            this.save.widgets = response.save.widgets;
            let help = {
              save: {},
            };
            if (localStorage.getItem('f000')) {
              help = JSON.parse(localStorage.getItem('f000'));
            }
            help.save = this.save;
            localStorage.setItem('f000', JSON.stringify(help));
          }
        },
        timeout: 10000,
        error: () => {
          // Einstellungen konnten nicht geladen werden
          if (dataToSave) {
            this.$store.commit('main/alert', {
              typ: 'error',
              text: `Die Einstellungen konnten nicht gespeichert werden.
              Bitte die Netzwerkverbindung prüfen.`,
            }, { root: true });
          }
          this.offline = true;
        },
        complete: () => {
          this.loading = false;
          if (dataToSave) {
            this.$store.commit('main/switchIsSaving', false);
          }
        },
      });
    },
    setCurrentTab(route) {
      this.currentTab.code = route.path.replace(this.$router.history.current.path.substring(0, 4), '').replace('-', '');
      this.currentTab.query = route.query;
      this.doRender = true;
    },
  },
  created() {
    this.tryGetOrSaveSettings();
    if (this.$store.state.main.save.mainmenu_links
      .some((e) => e.route === this.$router.history.current.path.substring(0, 4))) {
      // Wenn Zugriff auf diese View erlaubt
      // erlaube das Rendern
      this.setCurrentTab(this.$router.history.current);
    } else {
      // Wenn Zugriff auf diese View NICHT erlaubt
      // Leite zur ersten erlaubten Seite um
      this.$router.push({ path: this.$store.state.main.save.mainmenu_links[0].route });
      window.location.reload();
    }
    // Tabs erstellen:
    this.mytabs.forEach((tab) => {
      if (
        this.$store.state.main.save.user.rechte.find((element) => {
          let bool = false;
          if (element.includes(tab.erlaubt)) {
            bool = true;
          }
          return bool;
        })
      ) {
        this.tabs.push(tab);
      }
    });
    // Wenn der aktuelle Tabs nicht erlaubt ist, zum ersten erlaubten Tab navigieren:
    if (
      this.tabs.filter((o) => o.route === this.$router.history.current.path).length <= 0
      && this.tabs.length > 0
    ) {
      this.$router.push({ path: this.tabs[0].route });
    }
  },
  beforeRouteUpdate(to, from, next) {
    // Wenn ein neuer Tab innerhalb dieser Seite aufgerufen wird
    if (
      to.path.substring(1, 4) === '000'
    ) {
      // Wenn ein Tab der Seite 000 aufgerufen wird.
    }
    this.setCurrentTab(to);
    next();
  },
});
</script>

<style>
</style>
