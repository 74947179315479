<template>
  <v-container fluid class="ma-0 pa-0 d-flex justify-center">
    <F110Termin
      :idevent="parseInt(currentTab.query.e)"
      :calValue="value"
    />
    <v-container class="ma-0 pa-0">
      <v-progress-linear
        v-if="$store.state.f110.loading.getEvents"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <div>
        <div style="position:sticky;top:0px;z-index:1;background-color:#ffffff;">
          <div
            class="d-flex justify-space-around align-center"
          >
            <v-btn
              x-small
              class="ma-2"
              @click="$store.dispatch('f110/tryGetOrSaveSettings', {})"
              :loading="$store.state.f110.loading.getEvents"
            >
              <v-icon small :left="$vuetify.breakpoint.mdAndUp">mdi-update</v-icon>
              <span v-if="$vuetify.breakpoint.mdAndUp">Aktualisieren</span>
            </v-btn>
            <v-btn
              x-small
              class="ma-2"
              @click="eventHeute();"
            >
              <v-icon small :left="$vuetify.breakpoint.mdAndUp">mdi-calendar-today</v-icon>
              <span v-if="$vuetify.breakpoint.mdAndUp">heute</span>
            </v-btn>
            <!-- <v-btn
              x-small
              class="ma-2"
            >
              <v-icon small :left="$vuetify.breakpoint.mdAndUp">mdi-calendar-search</v-icon>
              <span v-if="$vuetify.breakpoint.mdAndUp">suchen...</span>
            </v-btn> -->
            <v-btn
              x-small color="primary"
              class="ma-2"
              @click="$router.push({ path: '/110?e=0' });"
              v-if="(this.$store.state.f110.store.kalender.stats.allowEditKategorien.length > 0)"
            >
              <v-icon small :left="$vuetify.breakpoint.mdAndUp">mdi-calendar-plus</v-icon>
              <span v-if="$vuetify.breakpoint.mdAndUp">hinzufügen</span>
            </v-btn>
            <v-btn
              v-if="type === 'week' || type === 'day'"
              x-small
              class="ma-2"
              @click="type = 'month';"
            >
              <v-icon small left>mdi-calendar-month</v-icon>
              Monat
            </v-btn>
            <v-btn
              v-if="type === 'month'"
              x-small
              class="ma-2"
              @click="type = 'week';"
            >
              <v-icon small left>mdi-calendar-week</v-icon>
              Woche
            </v-btn>
          </div>
          <v-sheet
            tile
            height="54"
            class="d-flex align-center justify-space-between"
          >
            <v-btn
              icon
              class="ma-2"
              @click="$refs.calendar.prev()"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <div class="d-flex mt-5">
            <v-select
              v-model="valueMonat"
              :items="valueMonate"
              item-text="name"
              item-value="nummer"
              dense
              outlined
              hide-details
              class="mx-2"
              label="Monat"
            ></v-select>
            <v-text-field
              v-model="valueJahr"
              dense outlined
              class="mx-2"
              label="jahr"
            />
            </div>
            <v-btn
              icon
              class="ma-2"
              @click="$refs.calendar.next()"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-sheet>
        </div>
        <!-- <v-sheet height="calc(100vh - 230px)" style="position:relative;min-height:700px;"> -->
        <v-sheet>
          <v-calendar
            ref="calendar"
            v-model="value"
            :weekdays="weekday"
            :type="type"
            :events="alleEvents"
            :event-overlap-mode="mode"
            :event-overlap-threshold="30"
            :event-color="getEventColor"
            first-time="00:00"
            :interval-format="intervalFormat"
            event-more-text="+{0} Termin/e"
            :event-more="false"
            @click:more="eventWeitere"
            @click:event="eventTermin"
            @click:date="eventTag"
            @change="getEvents"
            locale="de-DE"
          >
            <template v-slot:day-label-header="{ }">
              a
            </template>
            <template v-slot:event="{ event }">
              <div class="px-2">{{ event.name }}</div>
            </template>
          </v-calendar>
        </v-sheet>
      </div>
    </v-container>
  </v-container>
</template>

<script>
import F110Termin from '@/components/Funktionen/100/F110Kalender/F110Termin.vue';

export default {
  name: 'F110Terminkalender',
  components: {
    F110Termin,
  },
  props: {
    currentTab: {
      type: Object,
      default() { return {}; },
    },
  },
  data: () => ({
    start: {},
    end: {},
    type: 'month',
    types: [
      { name: 'Monat', type: 'month' },
      { name: 'Woche', type: 'week' },
      { name: 'Tag', type: 'day' },
    ],
    mode: 'stack',
    weekday: [1, 2, 3, 4, 5, 6, 0],
    value: '',
    lastValue: '',
    valueMonat: '',
    valueMonate: [],
    valueJahr: '',
    valueJahre: [],
    events: [],
    colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
    names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
  }),
  computed: {
    alleEvents() {
      // Events aus Store laden:
      const { events } = this.$store.state.f110;
      // Geburtstage ermitteln:
      if (this.$store.state.f110.store.settings.geburtstage) {
        this.$store.state.f110.store.settings.geburtstage.forEach((g) => {
          if (
            parseInt(g.monat, 10) >= this.start.month
            && parseInt(g.monat, 10) <= this.end.month
          ) {
            let kategorie = {
              farbe: '#FFFFFF',
            };
            if (
              this.$store.state.f110.store.kalender.kategorien
                .filter(
                  (o) => o.id === this.$store.state.f110.store
                    .settings.id_kalenderkategorie_geburtstag,
                ).length > 0
            ) {
              [kategorie] = this.$store.state.f110.store.kalender.kategorien
                .filter(
                  (o) => o.id === this.$store.state.f110.store
                    .settings.id_kalenderkategorie_geburtstag,
                );
            }
            const neuID = `geburtstag-${g.id_nutzer}-${this.start.year}-${g.monat}-${g.tag}`;
            const alter = this.start.year - g.jahr;
            g.alter = alter;
            if (
              !events.filter((o) => o.id === neuID).length >= 1
              && alter >= 0
            ) {
              events.push({
                id: neuID,
                geburtstag: g,
                jahr: this.start.year,
                name: `*${g.name} (${alter})`,
                start: new Date(`${this.start.year}-${g.monat}-${g.tag}T00:00:00`).getTime(),
                end: new Date(`${this.start.year}-${g.monat}-${g.tag}T23:59:00`).getTime(),
                color: kategorie.farbe,
                timed: false,
              });
            }
          }
        });
      }
      return events;
    },
  },
  watch: {
    value(neu) {
      if (neu === '') {
        const today = new Date();
        this.valueJahr = String(today.getYear() + 1900);
        this.valueMonat = String(today.getMonth() + 1).padStart(2, '0');
      } else {
        const v = neu.split('-');
        if (v[0] !== this.valueJahr) {
          [this.valueJahr] = v;
        }
        if (v[1] !== this.valueMonat) {
          [, this.valueMonat] = v;
        }
      }
    },
    valueJahr(neu) {
      if (this.value === '') {
        this.value = `${neu}-${this.valueMonat}-01`;
      } else {
        const v = this.value.split('-');
        this.value = `${neu}-${v[1]}-${v[2]}`;
      }
    },
    valueMonat(neu) {
      if (this.value === '') {
        this.value = `${this.valueJahr}-${neu}-01`;
      } else {
        const v = this.value.split('-');
        this.value = `${v[0]}-${neu}-${v[2]}`;
      }
    },
  },
  methods: {
    getEvents({ start, end }) {
      this.start = start;
      this.end = end;
    },
    getEventColor(event) {
      return event.color;
    },
    eventHeute() {
      const heute = new Date();
      const jahr = heute.getFullYear();
      const monat = String(heute.getMonth() + 1).padStart(2, '0');
      const tag = String(heute.getDate()).padStart(2, '0');
      this.value = `${jahr}-${monat}-${tag}`;
    },
    eventWeitere(event) {
      // console.log('weitere', event);
      this.value = event.date;
      this.type = 'day';
    },
    eventTermin(event) {
      // console.log('Termin', event);
      this.value = event.day.date;
      if (String(event.event.id).includes('geburtstag')) {
        console.log(event.event);
        this.$store.commit('main/alert', {
          text: `<b>${event.event.geburtstag.ident_nutzer}</b> hat am
          ${event.event.geburtstag.tag}.${event.event.geburtstag.monat}.${event.event.jahr}
          Geburtstag und wird <b>${event.event.geburtstag.alter}!</b>`,
        }, { root: true });
      } else {
        this.$router.push({ path: `/110?e=${event.event.id}` });
      }
    },
    eventTag() {
      if (this.lastValue === this.value) {
        this.type = 'day';
      }
      this.lastValue = this.value;
    },
    intervalFormat(event) {
      if (event.time < '24:00') {
        return event.time;
      }
      const t = event.time.split(':');
      return `${String((t[0] - 24)).padStart(2, '0')}:00`;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
  },
  created() {
    this.$store.dispatch('f110/tryGetOrSaveSettings', {});
    for (let i = 1; i <= 12; i += 1) {
      this.valueMonate.push({
        name: new Date(`2000-${String(i).padStart(2, '0')}-01`).toLocaleString('de-DE', { month: 'long' }),
        nummer: String(i).padStart(2, '0'),
      });
    }
    for (let i = 1900; i <= 3000; i += 1) {
      this.valueJahre.push(String(i));
    }
    const today = new Date();
    this.value = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
    this.valueJahr = String(today.getYear() + 1900);
    this.valueMonat = String(today.getMonth() + 1).padStart(2, '0');
  },
};
</script>
