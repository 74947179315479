var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.doRender)?_c('div',{staticClass:"view view_Benutzerverwaltung"},[(_vm.tabs.length > 1)?_c('viewTabsTop',{attrs:{"tabs":_vm.tabs}}):_vm._e(),(
    (_vm.currentTab.code === '' || _vm.currentTab.code === '000')
    && _vm.tabs.filter(function (o) { return o.erlaubt === 'x|005|000'; }).length > 0
  )?_c('Benutzer'):(
    _vm.currentTab.code === '010'
    && _vm.tabs.filter(function (o) { return o.erlaubt === 'x|005|010'; }).length > 0
  )?_c('Nutzerparameter'):(
    _vm.currentTab.code === '020'
    && _vm.tabs.filter(function (o) { return o.erlaubt === 'x|005|020'; }).length > 0
  )?_c('Nutzergruppen'):_c('p',[_vm._v(" Zugriff verweigert ")]),(_vm.tabs.length > 1)?_c('viewTabsBottom',{attrs:{"tabs":_vm.tabs}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }