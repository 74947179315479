<template>
  <div
    class="view view_Kinder"
    v-if="doRender"
  >
    <viewTabsTop v-if="tabs.length > 1" :tabs="tabs" />
    <!-- CONTENT -->
    <v-container
      v-if="offline"
    >
      <v-alert
        dense transition="scale-transition"
        type="error"
        class="ma-2"
        style="font-size:80%;"
      >
        <b>KEINE NETZWERKVERBINDUNG</b>
        <br>
        Die angezeigten Daten können veraltet sein.
        Bevor Änderungen vorgenommen werden, vergewissern Sie sich, dass die Daten aktuell sind.
      </v-alert>
    </v-container>
    <v-progress-linear
      indeterminate
      v-if="loading"
      absolute
    ></v-progress-linear>
    <transition
      v-if="
        currentTab.code === ''
        && tabs.filter((o) => o.erlaubt === 'x|200|000|000').length > 0
      "
      name="custom-classes-transition"
      :enter-active-class="transitionIn"
      :leave-active-class="transitionOut"
    >
      <Kinderverwaltung
        v-if="save.xtime"
        :prop_klassen="save.klassen"
        :prop_settings="save.settings"
        :prop_kinder="save.kinder"
        :prop_eltern="save.eltern"
        :offline="this.offline"
        :prop_kindUpdated="kindUpdated"
        @saveSettings="tryGetOrSaveSettings($event);"
      />
    </transition>
    <transition
      v-else-if="
        currentTab.code === '100'
        && tabs.filter((o) => o.erlaubt === 'x|200|100').length > 0
      "
      name="custom-classes-transition"
      :enter-active-class="transitionIn"
      :leave-active-class="transitionOut"
    >
      <Elternverwaltung
        :prop_eltern="save.eltern"
        :prop_kinder="save.kinder"
        :prop_klassen="save.klassen"
        :prop_kindUpdated="kindUpdated"
        :prop_query="this.currentTab.query"
        @saveSettings="tryGetOrSaveSettings($event);"
      />
    </transition>
    <transition
      v-else-if="
        currentTab.code === '200'
        && tabs.filter((o) => o.erlaubt === 'x|200|200').length > 0
      "
      name="custom-classes-transition"
      :enter-active-class="transitionIn"
      :leave-active-class="transitionOut"
    >
      <Statistik
        :prop_kinder="save.kinder"
        :prop_anwesenheiten="save.anwesenheiten"
        :prop_anwesenheitenMonate="save.anwesenheitenMonate"
        :prop_anwesenheitenDokumente="save.anwesenheitenDokumente"
        :prop_klassen="save.klassen"
        @neuAnwesenheiten="updateAnwesenheiten($event);"
      />
    </transition>
    <transition
      v-else-if="
        currentTab.code === 'set'
        && tabs.filter((o) => o.erlaubt === 'x|200|set').length > 0
      "
      name="custom-classes-transition"
      :enter-active-class="transitionIn"
      :leave-active-class="transitionOut"
    >
      <Einstellungen
        v-if="save.xtime"
        :prop_klassen="save.klassen"
        :prop_settings="save.settings"
        @saveSettings="tryGetOrSaveSettings($event);"
      />
    </transition>
    <p v-else>
      Zugriff verweigert
    </p>
    <!-- CONTENT -->
    <viewTabsBottom v-if="tabs.length > 1" :tabs="tabs" />
  </div>
</template>

<script>
import 'animate.css';
import $ from 'jquery';
import ViewTabsTop from '@/components/ViewTabsTop.vue';
import ViewTabsBottom from '@/components/ViewTabsBottom.vue';

import Kinderverwaltung from '@/components/Funktionen/200/200/Kinderverwaltung.vue';
import Elternverwaltung from '@/components/Funktionen/200/200/Elternverwaltung.vue';
import Statistik from '@/components/Funktionen/200/200/Statistik.vue';
import Einstellungen from '@/components/Funktionen/200/200/Einstellungen.vue';

export default ({
  name: 'Kinder',
  components: {
    ViewTabsTop,
    ViewTabsBottom,
    Kinderverwaltung,
    Elternverwaltung,
    Statistik,
    Einstellungen,
  },
  data: () => ({
    loading: false,
    offline: false,
    save: {},
    kindUpdated: false,
    doRender: false,
    tabs: [],
    mytabs: [
      {
        icon: 'mdi-human-child',
        title: 'Kinder',
        route: '/200',
        erlaubt: 'x|200|000|000',
      },
      {
        icon: 'mdi-human-male-female-child',
        title: 'Eltern',
        route: '/200-100',
        erlaubt: 'x|200|100',
      },
      {
        icon: 'mdi-chart-bar',
        title: 'Statistik',
        route: '/200-200',
        erlaubt: 'x|200|200',
      },
      {
        icon: 'mdi-tune',
        title: 'Einstellungen',
        route: '/200-set',
        erlaubt: 'x|200|set',
      },
    ],
    currentTab: {
      code: '',
      query: {},
    },
  }),
  computed: {
    transitionIn() {
      let result = '';
      if (this.$vuetify.breakpoint.mdAndDown) {
        result = 'animate__animated animate__fadeInLeft';
      }
      return result;
    },
    transitionOut() {
      let result = '';
      if (this.$vuetify.breakpoint.mdAndDown) {
        result = 'animate__animated animate__fadeOutLeft';
      }
      return result;
    },
  },
  methods: {
    async tryGetOrSaveSettings(dataToSave) {
      if (dataToSave) {
        // console.log(dataToSave);
        this.kindUpdated = false;
        if (!dataToSave.dataToSave.hideLoadingAnimation !== false) {
          this.$store.commit('main/switchIsSaving', true);
        }
      }
      this.loading = true;
      if (localStorage.getItem('f200')) {
        const help = JSON.parse(localStorage.getItem('f200'));
        if (help.save) {
          this.save = help.save;
          // this.loading = false;
        }
      }
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/200/200/?func=getSettings`,
        type: 'post',
        data: {
          code: this.$store.state.login.session.code,
          dataToSave: JSON.stringify(dataToSave),
        },
        success: (response) => {
          this.offline = false;
          if (response.status === 'accessError') {
            this.$store.commit('login/backendErrorSession');
          } else if (response.save) {
            /*
              Wenn neue Daten übergeben wurden:
            */
            /* if (dataToSave) {
              if (dataToSave.dataToSave.data.eltern) {
                response.save.eltern
                  .filter((o) => o.id === dataToSave.dataToSave.data.eltern.id)[0]
                  .dialog_edit = true;
              }
            } */
            if (this.currentTab.query.e) {
              if (
                response.save.eltern
                  .filter((o) => o.id === this.currentTab.query.e).length > 0
              ) {
                response.save.eltern
                  .filter((o) => o.id === this.currentTab.query.e)[0].dialog_edit = true;
              }
            }
            this.save.settings = response.save.settings;
            this.$store.commit('f200/setStateStore', { name: 'settings', val: response.save.settings });
            this.save.klassen = response.save.klassen;
            this.$store.commit('f200/setStateStore', { name: 'klassen', val: response.save.klassen });
            this.save.kinder = response.save.kinder;
            this.$store.commit('f200/setStateStore', { name: 'kinder', val: response.save.kinder });
            this.save.eltern = response.save.eltern;
            this.$store.commit('f200/setStateStore', { name: 'eltern', val: response.save.eltern });
            this.save.anwesenheiten = response.save.anwesenheiten;
            this.$store.commit('f200/setStateStore', { name: 'anwesenheiten', val: response.save.anwesenheiten });
            this.save.anwesenheitenMonate = response.save.anwesenheitenMonate;
            this.$store.commit('f200/setStateStore', { name: 'anwesenheitenMonate', val: response.save.anwesenheitenMonate });
            this.save.anwesenheitenDokumente = response.save.anwesenheitenDokumente;
            this.$store.commit('f200/setStateStore', { name: 'anwesenheitenDokumente', val: response.save.anwesenheitenDokumente });
            this.save.xtime = response.save.xtime;
            this.$store.commit('f200/setStateStore', { name: 'v', val: response.save.xtime });
            if (this.save.settings.dataOffline) {
              let help = {
                save: {},
              };
              if (localStorage.getItem('f200')) {
                help = JSON.parse(localStorage.getItem('f200'));
              }
              help.save = this.save;
              localStorage.setItem('f200', JSON.stringify(help));
            } else {
              localStorage.removeItem('f200');
            }
          } else {
            // Es wurden keine neuen Daten übergeben
          }
          if (dataToSave) {
            /* this.$store.commit('main/alert', {
              typ: 'success',
              text: 'Erfolgreich gespeichert',
            }, { root: true }); */
            if (dataToSave.dataToSave.type === 'anwesenheitsstatus') {
              /* console.log(response);
              console.log(
                this.save.kinder
                  .filter((o) => o.id === dataToSave.dataToSave.data.id_kind)[0],
              ); */
            }
            if (
              (dataToSave.dataToSave.data.kind && dataToSave.dataToSave.data.success === 'kindUpdated')
              || (dataToSave.dataToSave.data.eltern && dataToSave.dataToSave.data.success === 'kindUpdated')
            ) {
              this.kindUpdated = true;
            }
          }
        },
        timeout: 15000,
        error: () => {
          // Einstellungen konnten nicht geladen werden
          if (dataToSave) {
            this.$store.commit('main/alert', {
              typ: 'error',
              text: 'Die Einstellungen konnten nicht gespeichert werden. Bitte die Netzwerkverbindung prüfen.',
            }, { root: true });
          }
          this.offline = true;
        },
        complete: () => {
          this.loading = false;
          if (dataToSave) {
            if (!dataToSave.dataToSave.hideLoadingAnimation) {
              this.$store.commit('main/switchIsSaving', false);
            }
          }
        },
      });
    },
    updateAnwesenheiten(neu) {
      if (neu.anwesenheiten) {
        this.save.anwesenheiten = neu.anwesenheiten;
      }
      if (neu.anwesenheitenMonate) {
        this.save.anwesenheitenMonate = neu.anwesenheitenMonate;
      }
      if (neu.anwesenheitenDokumente) {
        this.save.anwesenheitenDokumente = neu.anwesenheitenDokumente;
      }
      if (this.save.settings.dataOffline) {
        let help = {
          save: {},
        };
        if (localStorage.getItem('f200')) {
          help = JSON.parse(localStorage.getItem('f200'));
        }
        help.save = this.save;
        localStorage.setItem('f200', JSON.stringify(help));
      } else {
        localStorage.removeItem('f200');
      }
    },
    setCurrentTab(route) {
      this.currentTab.code = route.path.replace(this.$router.history.current.path.substring(0, 4), '').replace('-', '');
      this.currentTab.query = route.query;
      this.doRender = true;
    },
  },
  created() {
    this.tryGetOrSaveSettings();
    if (this.$store.state.main.save.mainmenu_links
      .some((e) => e.route === this.$router.history.current.path.substring(0, 4))) {
      // Wenn Zugriff auf diese View erlaubt
      // erlaube das Rendern
      this.setCurrentTab(this.$router.history.current);
    } else {
      // Wenn Zugriff auf diese View NICHT erlaubt
      // Leite zur ersten erlaubten Seite um
      this.$router.push({ path: this.$store.state.main.save.mainmenu_links[0].route });
      localStorage.removeItem('f200');
      localStorage.removeItem('f200_filterClass');
      localStorage.removeItem('f200_timeAnm');
      window.location.reload();
    }
    // Tabs erstellen:
    this.mytabs.forEach((tab) => {
      if (
        this.$store.state.main.save.user.rechte.find((element) => {
          let bool = false;
          if (element.includes(tab.erlaubt)) {
            bool = true;
          }
          return bool;
        })
      ) {
        this.tabs.push(tab);
      }
    });
    // Wenn der aktuelle Tabs nicht erlaubt ist, zum ersten erlaubten Tab navigieren:
    if (
      this.tabs.filter((o) => o.route === this.$router.history.current.path).length <= 0
      && this.tabs.length > 0
    ) {
      this.$router.push({ path: this.tabs[0].route });
    }
  },
  beforeRouteUpdate(to, from, next) {
    // Wenn ein neuer Tab innerhalb dieser Seite aufgerufen wird
    if (
      to.path.substring(1, 4) === '200'
      && !(to.path === '/200-100' && from.path === to.path)
    ) {
      this.tryGetOrSaveSettings();
    }
    this.setCurrentTab(to);
    next();
  },
});
</script>

<style>
</style>
