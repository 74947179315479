/* eslint-disable no-console */

import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n'
        + 'For more details, visit https://goo.gl/AFskqB',
      );
    },
    registered() {
      console.log('Service worker has been registered.');
    },
    cached() {
      console.log('Content has been cached for offline use.');
      if (document.getElementById('main_loading_update').classList.contains('show')) {
        document.getElementById('main_loading_update').classList.remove('show');
        document.getElementById('automaticUpdateInfo').classList.remove('show');
      }
    },
    updatefound() {
      console.log('New content is downloading.');
      document.getElementById('main_loading_update').classList.add('show');
      document.getElementById('manualUpdateInfo').classList.remove('show');
      document.getElementById('automaticUpdateInfo').classList.add('show');
    },
    updated() {
      console.log('New content is available.');
      document.getElementById('main_loading_update').classList.add('show');
      document.getElementById('manualUpdateInfo').classList.remove('show');
      document.getElementById('automaticUpdateInfo').classList.add('show');
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.');
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    },
    notificationclick(event) {
      console.log(event.notification);
    },
  });
}
