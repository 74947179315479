<template>
    <v-container>
      <v-form
      v-model="valid"
      >
        <v-tabs
          fixed-tabs
          v-model="tabs_eigenschaftenRechte"
          class="d-md-none mb-6"
        >
          <v-tab>Eigenschaften</v-tab>
          <v-tab>Rechte</v-tab>
        </v-tabs>
        <v-row>
          <v-col
            cols="12"
            md="6"
            v-if="
              tabs_eigenschaftenRechte === 0 ||
              $vuetify.breakpoint.mdAndUp
            "
          >
            <!-- EIGENSCHAFTEN -->
            <v-container
              class="text-title text--secondary text-uppercase text-center d-none d-md-block"
            >
              Eigenschaften
            </v-container>
            <v-row class="my-0">
              <v-col cols="12" class="py-0">
                <v-row class="my-0">
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      label="Nutzername"
                      prepend-icon="mdi-account"
                      v-model="user.nn"
                      :rules="[
                        $store.state.main.inputValidationRules.required,
                        $store.state.main.inputValidationRules.nutzername,
                      ]"
                      class="pa-0 ma-0 mb-2"
                      :disabled="disable"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="10" class="py-0">
                    <v-text-field
                      label="Passwort"
                      prepend-icon="mdi-key"
                      v-model="userPW"
                      class="pa-0 ma-0"
                      :rules="[
                        $store.state.main.inputValidationRules.pw,
                      ]"
                      @keyup="userChanged = true;"
                      :disabled="disable"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" class="py-0">
                    <v-dialog
                      v-model="dialog_resetPW"
                      width="500"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn x-small class="py-4" color="error"
                          v-bind="attrs"
                          v-on="on"
                          :disabled="user.id === '' || disable"
                        >
                          <v-icon small>mdi-lock-reset</v-icon>
                        </v-btn>
                      </template>

                      <v-card>
                        <v-card-title class="text-h5 grey lighten-2">
                          Sicher?
                        </v-card-title>

                        <v-card-text class="pt-4">
                          Es wird ein zufälliges Passwort generiert und angezeigt.
                          Dieses sollte vom Benutzer so bald wie möglich zu einem
                          beliebigen, sicheren Passwort geändert werden.
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-btn
                            color="primary"
                            text
                            @click="dialog_resetPW = false;"
                          >
                            Nichts tun
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="primary"
                            text
                            @click="tryResetPW();dialog_resetPW = false;"
                          >
                            zurücksetzen
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
                <SelectNutzergruppen
                  v-model="user.gruppen"
                  label="Nutzergruppen"
                  :disabled="disable"
                />
              </v-col>
            </v-row>
            <EditUserParameter
              :user="user"
              :disabled="disable"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
            v-if="
              tabs_eigenschaftenRechte === 1 ||
              $vuetify.breakpoint.mdAndUp
            "
          >
            <!-- RECHTE -->
            <v-container
             class="text-title text--secondary text-uppercase text-center d-none d-md-block"
            >
              Rechte
            </v-container>
            <p class="text-caption text--secondary">
              Der Nutzer besitzt automatisch die folgenden Rechte:<br>
              (Diese können durch Rechte von Gruppen ergänzt werden)
            </p>
            <v-container
            class="pa-0"
            >
              <v-skeleton-loader
                v-if="rechte.length <= 0"
                type="list-item-three-line"
              ></v-skeleton-loader>
              <v-treeview
                v-else
                selectable open-all
                item-disabled="locked"
                :items="rechte"
                v-model="user.rechteSolo"
                align="left"
              >
                <template v-slot:prepend="{ item }">
                  <v-icon
                    :class="{ 'disabled': disable }"
                  >
                    {{ item.icon ? item.icon : 'mdi-key' }}
                  </v-icon>
                </template>
              </v-treeview>
            </v-container>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="center">
            <!--<v-btn
            v-if="valid"
            >
              Änderungen speichern
            </v-btn>-->
            <!-- <v-btn
            v-if="valid && userChanged && !disable"
            small
            color="success"
            class="ml-2"
            :loading="isSaving"
            @click="trySaveUser();"
            >
              <v-icon
              left
              >mdi-content-save</v-icon>
              Änderungen Speichern
            </v-btn> -->
          </v-col>
        </v-row>
      </v-form>
    </v-container>
</template>

<script>
import $ from 'jquery';
import EditUserParameter from '@/components/Inputs/EditUserParameter.vue';
import SelectNutzergruppen from '@/components/Inputs/SelectNutzergruppen.vue';

export default {
  components: {
    EditUserParameter,
    SelectNutzergruppen,
  },
  props: {
    user: {
      type: Object,
      default() { return {}; },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    idx: {
      type: Number,
      default: null,
    },
    save: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    valid: true,
    userChanged: false,
    rechte: [],
    dialog_resetPW: false,
    userPW: '',
    tabs_eigenschaftenRechte: null,
    disable: null,
  }),
  computed: {
    isSaving: { // Databinding mit vuex state
      get() {
        return this.$store.state.main.isSaving;
      },
      set(value) {
        this.$store.commit('main/switchIsSaving', value);
      },
    },
  },
  watch: {
    save(neu) {
      if (neu === true) {
        this.trySaveUser();
      }
    },
    user: {
      handler(neu, alt) {
        if (alt.id !== neu.id) {
          this.tabs_eigenschaftenRechte = 0;
          this.userPW = neu.rootPW;
        } else {
          this.userChanged = true;
        }
      },
      deep: true,
    },
    isSaving(neu) {
      if (neu === false) {
        this.userChanged = false;
      }
    },
  },
  methods: {
    async tryResetPW() {
      this.$store.commit('main/switchIsSaving', true);
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/settings/?func=resetPW`,
        type: 'post',
        data: {
          code: this.$store.state.login.session.code,
          data: this.user.id,
        },
        success: (response) => {
          if (response.status === 'accessError') {
            this.$store.commit('login/backendErrorSession');
          } else {
            this.$store.commit('main/alert', {
              typ: 'success',
              text: `Das Passwort wurde erfolgreich zurückgesetzt. Das neue Passwort lautet: ${response.neuPW}`,
            });
            this.userPW = response.neuPW;
            this.$store.commit('main/switchIsSaving', false);
          }
        },
        timeout: this.$store.state.main.ajaxMaxTimeout,
        error: () => {
          // Nutzer konnte nicht gespeichert werden
          this.$store.commit('main/alert', {
            typ: 'error',
            text: 'Das Passwort konnte nicht zurückgesetzt werden. Bitte die Netzwerkverbindung prüfen.',
          }, { root: true });
          this.$store.commit('main/switchIsSaving', false);
        },
      });
    },
    async trySaveUser() {
      this.$store.commit('main/switchIsSaving', true);
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/settings/?func=saveNutzer`,
        type: 'post',
        data: {
          code: this.$store.state.login.session.code,
          data: JSON.stringify(this.user),
          neuPW: this.userPW,
        },
        success: (response) => {
          if (response.status === 'accessError') {
            this.$store.commit('login/backendErrorSession');
          } else {
            this.$emit('reloadUsers', { addedUserID: response.addedUserID });
            this.$store.dispatch('main/tryGetSettings', { code: this.$store.state.login.session.code }).then(() => {
              this.userChanged = false;
            });
            if (response.addedUserNN !== '') {
              setTimeout(() => {
                this.$store.commit('main/alert', {
                  typ: 'success',
                  text: `Der Nutzer ${response.addedUserNN} wurde mit folgendem Passwort angelegt: ${response.addedUserPW}`,
                });
              }, 500);
            }
          }
        },
        timeout: this.$store.state.main.ajaxMaxTimeout,
        error: () => {
          // Nutzer konnte nicht gespeichert werden
          this.$store.commit('main/alert', {
            typ: 'error',
            text: 'Die Einstellungen konnten nicht gespeichert werden. Bitte die Netzwerkverbindung prüfen.',
          });
          this.$store.commit('main/switchIsSaving', false);
        },
        complete: () => {
          this.$emit('updatedUser');
        },
      });
    },
    async tryGetRechte() {
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/settings/?func=getRechte`,
        type: 'post',
        data: {
          code: this.$store.state.login.session.code,
        },
        success: (response) => {
          if (response.status === 'accessError') {
            this.$store.commit('login/backendErrorSession');
          } else {
            this.rechte = response.rechte[0].children;
            if (!this.$store.state.main.save.user.rechte.includes('x|005|000|020')) {
              // Wenn Edit nicht erlaubt, sperre alle Rechte:
              if (this.user.id === '' && this.disabled) {
                this.disable = false;
                this.rechte.forEach((item) => { item.locked = false; });
              } else {
                this.rechte.forEach((item) => { item.locked = true; });
              }
            }
          }
        },
        error: () => {
          // Rechte konnten nicht geladen werden
        },
      });
    },
  },
  created() {
    this.tryGetRechte();
    this.userPW = this.user.rootPW;
    this.disable = this.disabled;
  },
};
</script>
