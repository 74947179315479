var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.doRender)?_c('div',{staticClass:"view view_Anwesenheiten"},[(_vm.tabs.length > 1)?_c('viewTabsTop',{attrs:{"tabs":_vm.tabs}}):_vm._e(),(
      _vm.$store.state[("f" + _vm.sitecode)].loading
      && !_vm.$store.state[("f" + _vm.sitecode)].offline
    )?_c('v-progress-linear',{staticStyle:{"transform":"translateY(-2px)"},attrs:{"indeterminate":"","absolute":"","color":"primary"}}):_vm._e(),(
      _vm.$store.state[("f" + _vm.sitecode)].offline
      && _vm.$store.state[("f" + _vm.sitecode)].offlineHideAlert
    )?_c('v-alert',{staticStyle:{"transform":"translateY(-2px)","border-radius":"0px"},attrs:{"dense":"","type":"error"}}):_vm._e(),(
      _vm.$store.state[("f" + _vm.sitecode)].offline
      && !_vm.$store.state[("f" + _vm.sitecode)].offlineHideAlert
    )?_c('v-container',[_c('v-alert',{staticClass:"ma-2",staticStyle:{"font-size":"80%"},attrs:{"dense":"","transition":"scale-transition","type":"error"}},[_c('b',[_vm._v("KEINE NETZWERKVERBINDUNG")]),_c('br'),_vm._v(" Die angezeigten Daten können veraltet sein. Bevor Änderungen vorgenommen werden, vergewissern Sie sich, dass die Daten aktuell sind. "),_c('div',{attrs:{"align":"right"}},[_c('v-btn',{attrs:{"small":"","text":""},on:{"click":function($event){return _vm.$store.commit(("f" + _vm.sitecode + "/offlineHideAlert"));}}},[_vm._v("verstanden")])],1)])],1):_vm._e(),(
      _vm.currentTab.code === ''
      && _vm.tabs.filter(function (o) { return o.erlaubt === ("x|" + _vm.sitecode); }).length > 0
    )?_c('transition',{attrs:{"name":"custom-classes-transition","enter-active-class":_vm.transitionIn,"leave-active-class":_vm.transitionOut}},[(_vm.$store.state[("f" + _vm.sitecode)].store.settings)?_c('F150Slots',{attrs:{"sitecode":_vm.sitecode,"get":this.currentTab.query}}):_vm._e()],1):(
      _vm.currentTab.code === 'set'
      && _vm.tabs.filter(function (o) { return o.erlaubt === ("x|" + _vm.sitecode); }).length > 0
    )?_c('transition',{attrs:{"name":"custom-classes-transition","enter-active-class":_vm.transitionIn,"leave-active-class":_vm.transitionOut}},[(_vm.$store.state[("f" + _vm.sitecode)].store.settings)?_c('F150Einstellungen'):_vm._e()],1):_c('p',[_vm._v(" Zugriff verweigert ")]),(_vm.tabs.length > 1)?_c('viewTabsBottom',{attrs:{"tabs":_vm.tabs}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }