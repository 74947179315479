var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.doRender)?_c('div',{staticClass:"view view_Mitglieder"},[(_vm.tabs.length > 1)?_c('viewTabsTop',{attrs:{"tabs":_vm.tabs}}):_vm._e(),(
      _vm.currentTab.code === ''
      && !_vm.currentTab.query.u
      && _vm.tabs.filter(function (o) { return o.erlaubt === 'x|100|000'; }).length > 0
    )?_c('transition',{attrs:{"name":"custom-classes-transition","enter-active-class":_vm.transitionIn,"leave-active-class":_vm.transitionOut}},[_c('Mitgliederliste',{attrs:{"save":_vm.save}})],1):(
      _vm.currentTab.code === ''
      && _vm.currentTab.query.u
      && _vm.tabs.filter(function (o) { return o.erlaubt === 'x|100|000'; }).length > 0
    )?_c('transition',{attrs:{"name":"custom-classes-transition","enter-active-class":_vm.transitionIn,"leave-active-class":_vm.transitionOut}},[_c('Mitglied',{attrs:{"id":_vm.currentTab.query.u,"save":_vm.save}})],1):(
      _vm.currentTab.code === '010'
      && _vm.tabs.filter(function (o) { return o.erlaubt === 'x|100|010'; }).length > 0
    )?_c('transition',{attrs:{"name":"custom-classes-transition","enter-active-class":_vm.transitionIn,"leave-active-class":_vm.transitionOut}},[_c('Einstellungen',{attrs:{"save":_vm.save}})],1):_c('p',[_vm._v(" Zugriff verweigert ")]),(_vm.tabs.length > 1)?_c('viewTabsBottom',{attrs:{"tabs":_vm.tabs}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }