var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"inline-block"}},[(_vm.item.anwesenheitsstatus === 0)?_c('v-btn',{attrs:{"x-small":"","color":"error","loading":_vm.item.anwesenheitsstatusLoading,"disabled":!_vm.item.anwesend},on:{"click":function($event){return _vm.changeAnwesenheitStatus(_vm.item);}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" mdi-account-cancel ")]),_vm._v(" Abwesend ")],1):_vm._e(),(_vm.item.anwesenheitsstatus === 1)?_c('v-btn',{attrs:{"x-small":"","color":"success","disabled":!_vm.item.anwesend,"loading":_vm.item.anwesenheitsstatusLoading},on:{"click":function($event){return _vm.changeAnwesenheitStatus(_vm.item);}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" mdi-account-check ")]),_vm._v(" Anwesend seit "+_vm._s(_vm.item.anwesenheitHeute.zeit_von)+" ")],1):_vm._e(),(_vm.item.anwesenheitsstatus === 2)?_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","color":"warning","loading":_vm.item.anwesenheitsstatusLoading}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" mdi-account-arrow-up ")]),_vm._v(" Entlassen ")],1)]}}],null,false,2699323396),model:{value:(_vm.item.anwesenheitHeute.dialog_entlassen),callback:function ($$v) {_vm.$set(_vm.item.anwesenheitHeute, "dialog_entlassen", $$v)},expression:"item.anwesenheitHeute.dialog_entlassen"}},[_c('v-card',[_c('v-card-title',[_c('b',[_vm._v(_vm._s(_vm.item.name)+" auf abwesend stellen?")])]),_c('v-card-text',{staticClass:"pt-4"},[_vm._v(" Es werden sämtliche Anwesenheitsdaten gelöscht, als wäre "+_vm._s(_vm.item.name)+" heute nicht anwesend gewesen. Wenn Sie stattdessen nur die Abmeldung rückgängig machen möchten, klicken Sie auf \"RÜCKG.\". ")]),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"small":"","color":"primary","text":""},on:{"click":function($event){_vm.item.anwesenheitHeute.dialog_entlassen = false;}}},[_vm._v(" Nichts tun ")]),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","color":"warning","text":""},on:{"click":function($event){_vm.nurEntlassenRueckgaengig = true;
            _vm.changeAnwesenheitStatus(_vm.item);
            _vm.item.anwesenheitHeute.dialog_entlassen = false;}}},[_vm._v(" Rückg. ")]),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","color":"error","text":""},on:{"click":function($event){_vm.nurEntlassenRueckgaengig = false;
            _vm.changeAnwesenheitStatus(_vm.item);
            _vm.item.anwesenheitHeute.dialog_entlassen = false;}}},[_vm._v(" Abwesend ")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }