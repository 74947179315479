var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.doRender)?_c('div',{staticClass:"view view_ElternKontakt"},[(_vm.tabs.length > 1)?_c('viewTabsTop',{attrs:{"tabs":_vm.tabs}}):_vm._e(),(_vm.offline)?_c('v-container',[_c('v-alert',{staticClass:"ma-2",staticStyle:{"font-size":"80%"},attrs:{"dense":"","transition":"scale-transition","type":"error"}},[_c('b',[_vm._v("KEINE NETZWERKVERBINDUNG")]),_c('br'),_vm._v(" Die angezeigten Daten können veraltet sein. Bevor Änderungen vorgenommen werden, vergewissern Sie sich, dass die Daten aktuell sind. ")])],1):_vm._e(),(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","absolute":""}}):_vm._e(),(
      _vm.currentTab.code === ''
      && _vm.tabs.filter(function (o) { return o.erlaubt === 'x|210|000'; }).length > 0
    )?_c('transition',{attrs:{"name":"custom-classes-transition","enter-active-class":_vm.transitionIn,"leave-active-class":_vm.transitionOut}},[(_vm.save.xtime)?_c('AnwesenheitMelden',{attrs:{"prop_xtime":_vm.save.xtime,"prop_settings":_vm.save.settings,"prop_kinder":_vm.save.kinder},on:{"saveSettings":function($event){return _vm.tryGetOrSaveSettings($event)}}}):_vm._e()],1):(
      _vm.currentTab.code === '100'
      && _vm.tabs.filter(function (o) { return o.erlaubt === 'x|210|100'; }).length > 0
    )?_c('transition',{attrs:{"name":"custom-classes-transition","enter-active-class":_vm.transitionIn,"leave-active-class":_vm.transitionOut}},[_c('Kontakt')],1):(
      _vm.currentTab.code === 'set'
      && _vm.tabs.filter(function (o) { return o.erlaubt === 'x|210|set'; }).length > 0
    )?_c('transition',{attrs:{"name":"custom-classes-transition","enter-active-class":_vm.transitionIn,"leave-active-class":_vm.transitionOut}},[(_vm.save.xtime)?_c('Einstellungen',{attrs:{"prop_settings":_vm.save.settings},on:{"saveSettings":function($event){return _vm.tryGetOrSaveSettings($event)}}}):_vm._e()],1):_c('p',[_vm._v(" Zugriff verweigert ")]),(_vm.tabs.length > 1)?_c('viewTabsBottom',{attrs:{"tabs":_vm.tabs}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }