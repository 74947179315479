<template>
  <div class="view view_Profil" v-if="doRender">
    <viewTabsTop v-if="tabs.length > 0" :tabs="tabs" />
    <!-- CONTENT -->
    <v-container>
      <MeineDaten v-if="currentTab.code === ''" />
      <Kontoeinstellungen v-if="currentTab.code === '010'" />
    </v-container>
    <!-- CONTENT -->
    <viewTabsBottom v-if="tabs.length > 0" :tabs="tabs" />
  </div>
</template>

<script>
import ViewTabsTop from '@/components/ViewTabsTop.vue';
import ViewTabsBottom from '@/components/ViewTabsBottom.vue';

import MeineDaten from '@/components/Funktionen/MeinProfil/MeineDaten.vue';
import Kontoeinstellungen from '@/components/Funktionen/MeinProfil/Kontoeinstellungen.vue';

export default ({
  name: 'Profil',
  components: {
    ViewTabsTop,
    ViewTabsBottom,
    MeineDaten,
    Kontoeinstellungen,
  },
  data: () => ({
    doRender: false,
    tabs: [
      {
        icon: 'mdi-card-account-details',
        title: 'Meine Daten',
        route: '/010',
      },
      {
        icon: 'mdi-account-key',
        title: 'Kontoeinstellungen',
        route: '/010-010',
      },
    ],
    currentTab: {
      code: '',
      query: {},
    },
  }),
  methods: {
    setCurrentTab(route) {
      this.currentTab.code = route.path.replace(this.$router.history.current.path.substring(0, 4), '').replace('-', '');
      this.currentTab.query = route.query;
      this.doRender = true;
    },
  },
  created() {
    if (this.$store.state.main.save.mainmenu_links
      .some((e) => e.route === this.$router.history.current.path.substring(0, 4))) {
      // Wenn Zugriff auf diese View erlaubt
      // erlaube das Rendern
      this.setCurrentTab(this.$router.history.current);
    } else {
      // Wenn Zugriff auf diese View NICHT erlaubt
      // Leite zur ersten erlaubten Seite um
      this.$router.push({ path: this.$store.state.main.save.mainmenu_links[0].route });
      window.location.reload();
    }
  },
  beforeRouteUpdate(to, from, next) {
    // Wenn ein neue Tab innerhalb dieser Seite aufgerufen wird
    this.setCurrentTab(to);
    next();
  },
});
</script>

<style>
</style>
