<template>
  <v-dialog
    v-model="openDialog"
    width="800"
    @click:outside="close();"
    :fullscreen="$vuetify.breakpoint.xs"
    scrollable
  >
    <!-- <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="red lighten-2"
        dark
        v-bind="attrs"
        v-on="on"
      >
        Click Me
      </v-btn>
    </template> -->

    <v-card v-if="event">
      <v-toolbar
        dark
        color="primary"
      >
        <v-btn
          icon
          dark
          @click="close();"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span
            v-if="event.id === 0"
          >
            Neuer Eintrag
          </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            icon
            dark
            @click="(edit = !edit)"
            v-if="(allowEdit && !edit)"
          >
            <v-icon v-if="edit">mdi-pencil-off</v-icon>
            <v-icon v-else>mdi-pencil</v-icon>
          </v-btn>
          <v-dialog
            v-model="dialogDelTermin"
            width="500"
            v-if="(event.id > 0 && allowEdit) && !istSerie"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                dark
                v-bind="attrs"
                v-on="on"
                :loading="$store.state.f110.loading.delTermin"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-card-title>
                Wirklich löschen?
              </v-card-title>

              <v-card-text>
                Der Termin "{{ event.name }}" wird unwiederruflich gelöscht.
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-btn
                  text small
                  @click="dialogDelTermin = false"
                >
                  Abbrechen
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  text small
                  @click="
                    dialogDelTermin = false;
                    $store.dispatch('f110/tryGetOrSaveSettings',
                    { dataToSave: {
                      type: 'delTermin',
                      data: event,
                    },
                    router: $router,
                  })
                  "
                >
                  Löschen
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialogDelTermin"
            width="500"
            v-if="(event.id > 0 && allowEdit) && istSerie"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                dark
                v-bind="attrs"
                v-on="on"
                :loading="$store.state.f110.loading.delTermin"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-card-title>
                Wirklich löschen?
              </v-card-title>

              <v-card-text>
                Möchtest du die ganze Serie oder nur den
                Termin am {{ calValueDE }} löschen?
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-btn
                  text small
                  @click="dialogDelTermin = false"
                >
                  Abbrechen
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  text small
                  @click="
                    dialogDelTermin = false;
                    $store.dispatch('f110/tryGetOrSaveSettings',
                    { dataToSave: {
                      type: 'delTermin',
                      data: event,
                      nurAusnahmeFuerTag: calValue,
                    },
                    router: $router,
                  })
                  "
                >
                  Termin
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  text small
                  @click="
                    dialogDelTermin = false;
                    $store.dispatch('f110/tryGetOrSaveSettings',
                    { dataToSave: {
                      type: 'delTermin',
                      data: event,
                    },
                    router: $router,
                  })
                  "
                >
                  Serie
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="py-4 pt-8" style="height:100%;">
        <div v-if="(allowEdit && edit)">
          <v-form v-model="formValid">
            <v-row align="center">
              <v-col cols="2" align="center">
                <v-icon :color="event.color">mdi-calendar</v-icon>
              </v-col>
              <v-col>{{ istSerie }}
                <v-select
                  label="Kalender"
                  v-model="event.id_kalenderkategorie"
                  :items="$store.state.f110.store.kalender.stats.allowEditKategorien"
                  item-text="name"
                  item-value="id"
                  @change="
                    event.color = $store.state.f110.store.kalender.stats.allowEditKategorien
                      .filter((o) => o.id === event.id_kalenderkategorie)[0].farbe;
                  "
                />
              </v-col>
            </v-row>
            <v-text-field
              label="Titel"
              v-model="event.name"
              :rules="[$store.state.main.inputValidationRules.required]"
            />
            <v-textarea
              label="Beschreibung"
              auto-grow
              v-model="event.beschreibung"
            />
            <v-checkbox
              v-model="ganztaegig"
              label="Ganztägig"
              class="mb-0 pb-0"
            />
            <v-container class="my-0 py-0">
              <v-row class="my-0 py-0">
                <!-- <v-col cols="12" class="my-0 py-0">
                  <v-subheader>Von</v-subheader>
                </v-col> -->
                <v-col cols="12" sm="6" class="my-0 py-0">
                  <DatePicker
                    v-model="zeitVon.tag"
                    @change="changedZeiten('von');"
                    label="Datum Beginn"
                  />
                </v-col>
                <v-col cols="12" sm="6" class="my-0 py-0"
                v-if="!ganztaegig">
                  <TimePicker
                    v-model="zeitVon.zeit"
                    @change="changedZeiten('von');"
                    label="Zeit Beginn"
                  />
                </v-col>
                <!-- <v-col cols="12" class="my-0 py-0">
                  <v-subheader>Bis</v-subheader>
                </v-col> -->
                <v-col cols="12" sm="6" class="my-0 py-0">
                  <DatePicker
                    v-model="zeitBis.tag"
                    @change="changedZeiten('bis');"
                    label="Datum Ende"
                  />
                </v-col>
                <v-col cols="12" sm="6" class="my-0 py-0"
                v-if="!ganztaegig">
                  <TimePicker
                    v-model="zeitBis.zeit"
                    @change="changedZeiten('bis');"
                    label="Zeit Ende"
                  />
                </v-col>
              </v-row>
            </v-container>
            <v-checkbox
              label="Serientermin"
              v-model="event.istSerie"
            />
            <F110TerminSeriensettings
              v-if="event.istSerie"
              v-model="event.repeat"
            />
          </v-form>
        </div>
        <div v-else>
          <div v-html="eventKalenderkategorie"></div>
          <v-row align="center" v-if="!event.istSerie || event.timed">
            <v-col align="center">
              <v-icon left class="mb-2">mdi-clock-time-four-outline</v-icon>
              <span class="text-h6">
                <span v-if="!event.istSerie">{{ zeitVon.tagDE }}</span>
                <span v-if="event.timed"> {{ zeitVon.zeit }} </span>
                <span
                  v-if="(zeitVon.tagDE !== zeitBis.tagDE || event.timed)"
                > - </span>
                <br v-if="(zeitVon.tagDE !== zeitBis.tagDE && event.timed) && !event.istSerie" />
                <span
                  v-if="(zeitVon.tagDE !== zeitBis.tagDE) && !event.istSerie"
                > {{ zeitBis.tagDE }} </span>
                <span v-if="event.timed"> {{ zeitBis.zeit }} </span>
              </span>
            </v-col>
          </v-row>
          <v-row align="center" v-if="event.istSerie">
            <v-col align="center">
              <p v-if="event.repeat.mustertyp === 'Tage'">
                <span v-if="event.repeat.musterintervall > 1">
                  Alle {{ event.repeat.musterintervall }} Tage
                </span>
                <span v-else>
                  Jeden Tag
                </span>
                {{ eventReapeatSerienzeitraum }}
              </p>
              <p v-if="event.repeat.mustertyp === 'Wochen'">
                <span v-if="event.repeat.musterintervall > 1">
                  Alle {{ event.repeat.musterintervall }} Wochen:
                </span>
                <span v-else>
                  Jede Woche:
                </span>
                {{ eventRepeatWoechentlichWochentage }}
                {{ eventReapeatSerienzeitraum }}
              </p>
              <p v-if="event.repeat.mustertyp === 'Monate'">
                <span v-if="event.repeat.musterintervall > 1">
                  Alle {{ event.repeat.musterintervall }} Monate:
                  Jeden {{ event.repeat.monatlichTag }}. des Monats
                </span>
                <span v-else>
                  Jeden {{ event.repeat.monatlichTag }}. eines Monats
                </span>
                {{ eventReapeatSerienzeitraum }}
              </p>
              <p v-if="event.repeat.mustertyp === 'Jahre'">
                <span v-if="event.repeat.musterintervall > 1">
                  Alle {{ event.repeat.musterintervall }} Jahre:
                  Jeden {{ event.repeat.jaehrlichTag }}.{{ event.repeat.jaehrlichMonat }}.
                  des Jahres
                </span>
                <span v-else>
                  Jeden {{ event.repeat.jaehrlichTag }}.{{ event.repeat.jaehrlichMonat }}.
                  jeden Jahres
                </span>
                {{ eventReapeatSerienzeitraum }}
              </p>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col align="center">
              <h2>{{ event.name }}</h2>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col align="center">
              <p
                v-html="event.beschreibung"
                class="mt-2"
              ></p>
            </v-col>
          </v-row>
          <v-row align="center" v-if="
            event.ausSerie
            && $store.state.f110.store.kalender.events
              .filter((o) => o.id === String(event.ausSerie)).length > 0
          ">
            <v-col align="center">
              <hr/>
              <p>
                Dieser Termin stammt aus einem Serientermin:
                <br/>
                <v-btn
                  small text
                  @click="$router.push({ path: `/110?e=${event.ausSerie}` });"
                >
                  {{
                    $store.state.f110.store.kalender.events
                      .filter((o) => o.id === String(event.ausSerie))[0].name
                  }}
                </v-btn>
              </p>
            </v-col>
          </v-row>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn
          text small
          @click="close();"
        >
          Schließen
        </v-btn>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialogSaveTermin"
          width="500"
          v-if="(allowEdit && istSerie)"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              text small
              :disabled="!formValid || !edit"
              :loading="$store.state.f110.loading.saveTermin"
              v-bind="attrs"
              v-on="on"
            >
              Speichern
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              Wirklich speichern?
            </v-card-title>

            <v-card-text>
              Sollen die vorgenommenen Änderungen für die
              Serie oder als Einzeltermin gespeichert werden?<br/>
              <p style="font-size:80%;">
                EInzeltermine sind losgelöste Termine einer Serie.
                Werden Änderungen an einer Serie vorgenommen, haben
                diese keinen Einfluss mehr auf die Einzeltermine.
              </p>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn
                text small
                @click="dialogSaveTermin = false"
              >
                Abbrechen
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text small
                @click="
                  dialogSaveTermin = false;
                  event.ausSerie = event.id;
                  event.id = 0;
                  event.istSerie = false;
                  $store.dispatch('f110/tryGetOrSaveSettings',
                    { dataToSave: {
                      type: 'saveTermin',
                      data: event,
                      zeit: { zeitVon, zeitBis },
                    },
                    router: $router,
                  })
                "
              >
                Einzeltermin
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text small
                @click="
                  dialogSaveTermin = false;
                  $store.dispatch('f110/tryGetOrSaveSettings',
                    { dataToSave: {
                      type: 'saveTermin',
                      data: event,
                      zeit: { zeitVon, zeitBis },
                    },
                    router: $router,
                  })
                "
              >
                Serie
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn
          v-if="allowEdit && !istSerie"
          color="primary"
          text small
          @click="
            $store.dispatch('f110/tryGetOrSaveSettings',
              { dataToSave: {
                type: 'saveTermin',
                data: event,
                zeit: { zeitVon, zeitBis },
              },
              router: $router,
            })
          "
          :disabled="!formValid || !edit"
          :loading="$store.state.f110.loading.saveTermin"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import $ from 'jquery';
import DatePicker from '@/components/Inputs/DatePicker.vue';
import TimePicker from '@/components/Inputs/TimePicker.vue';
import F110TerminSeriensettings from '@/components/Funktionen/100/F110Kalender/F110TerminSeriensettings.vue';

export default {
  components: {
    DatePicker,
    TimePicker,
    F110TerminSeriensettings,
  },
  props: {
    calValue: {
      type: String,
      default: '',
    },
    idevent: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dialogDelTermin: false,
      dialogSaveTermin: false,
      formValid: null,
      openDialog: false,
      event: null,
      allowEdit: false,
      ganztaegig: true,
      edit: false,
      zeitVon: {},
      zeitBis: {},
      istSerie: false,
    };
  },
  computed: {
    calValueDE() {
      const date = new Date(this.calValue);
      const jahr = date.getFullYear();
      const monat = String(date.getMonth() + 1).padStart(2, '0');
      const tag = String(date.getDate()).padStart(2, '0');
      return `${tag}.${monat}.${jahr}`;
    },
    events() {
      return this.$store.state.f110.events;
    },
    eventRepeatWoechentlichWochentage() {
      let result = [];
      Object.keys(this.event.repeat.woechentlichWochentage).forEach((o) => {
        if (this.event.repeat.woechentlichWochentage[o] === true) {
          result.push(o);
        }
      });
      if (result.length > 1) {
        const letzter = result.pop();
        result = `${result.join(', ')} und ${letzter}`;
      } else {
        result = result.join(', ');
      }
      return result;
    },
    eventReapeatSerienzeitraum() {
      /* const von = new Date(this.event.repeat.von);
      let zeitraum = 'vom ';
      zeitraum += `${String(von.getDate()).padStart(2, '0')}
        .${String(von.getMonth() + 1).padStart(2, '0')}.${von.getFullYear()}`;
      const bis = new Date(this.event.repeat.bis);
      zeitraum += ' bis zum ';
      zeitraum += `${String(bis.getDate()).padStart(2, '0')}
        .${String(bis.getMonth() + 1).padStart(2, '0')}.${bis.getFullYear()}`;
      return zeitraum; */
      return '';
    },
    eventKalenderkategorie() {
      if (this.$store.state.f110.store.kalender.kategorien
        .filter((o) => o.id === this.event.id_kalenderkategorie).length > 0) {
        const kategorie = this.$store.state.f110.store.kalender.kategorien
          .filter((o) => o.id === this.event.id_kalenderkategorie)[0];
        return `
          <div style="
            background-color:${kategorie.farbe};
            color:#ffffff;
            padding:8px;
            display:inline-block;
            border-radius:50%;
          "></div>
        `;
      }
      return '';
    },
  },
  watch: {
    idevent() {
      this.checkOpenDialog();
    },
    events: {
      handler() {
        this.checkOpenDialog();
      },
      deep: true,
    },
    ganztaegig(neu) {
      this.event.timed = !neu;
    },
  },
  methods: {
    convertAusnahme(a) {
      const ausnahme = a.split('|');
      const dateVon = new Date(parseInt(ausnahme[1], 10));
      const jahrVon = dateVon.getFullYear();
      const monatVon = String(dateVon.getMonth() + 1).padStart(2, '0');
      const tagVon = String(dateVon.getDate()).padStart(2, '0');
      const dateBis = new Date(parseInt(ausnahme[2], 10));
      const jahrBis = dateBis.getFullYear();
      const monatBis = String(dateBis.getMonth() + 1).padStart(2, '0');
      const tagBis = String(dateBis.getDate()).padStart(2, '0');
      return {
        id: ausnahme[0],
        von: `${jahrVon}-${monatVon}-${tagVon}`,
        bis: `${jahrBis}-${monatBis}-${tagBis}`,
      };
    },
    changedZeiten(typ) {
      const timeVon = new Date(`${this.zeitVon.tag} ${this.zeitVon.zeit}`).getTime();
      const timeBis = new Date(`${this.zeitBis.tag} ${this.zeitBis.zeit}`).getTime();
      if (timeVon > timeBis) {
        if (typ === 'von') {
          if (this.zeitVon.zeit < this.zeitBis.zeit) {
            this.zeitBis = this
              .convertTimeToDate(new Date(new Date(`${this.zeitVon.tag} ${this.zeitBis.zeit}`).getTime()).getTime());
          } else {
            this.zeitBis = this.convertTimeToDate(new Date(timeVon).getTime());
          }
        } else if (this.zeitVon.zeit < this.zeitBis.zeit) {
          this.zeitVon = this
            .convertTimeToDate(new Date(new Date(`${this.zeitBis.tag} ${this.zeitVon.zeit}`).getTime()).getTime());
        } else {
          this.zeitVon = this.convertTimeToDate(new Date(timeBis).getTime());
        }
      }
      this.updateSeriensettings();
    },
    convertTimeToDate(time) {
      const date = new Date(time);
      const tag = String(date.getDate()).padStart(2, '0');
      const monat = String((date.getMonth() + 1)).padStart(2, '0');
      const jahr = date.getFullYear();
      const stunde = String(date.getHours()).padStart(2, '0');
      const minute = String(date.getMinutes()).padStart(2, '0');
      return {
        tag: `${jahr}-${monat}-${tag}`,
        tagDE: `${tag}.${monat}.${jahr}`,
        zeit: `${stunde}:${minute}`,
      };
    },
    updateSeriensettings() {
      let repeat;
      if (!this.event.istSerie) {
        let neuBis = new Date(this.zeitBis.tag);
        neuBis.setFullYear(neuBis.getFullYear() + 5); // Für offenes Ende addiere 5 Jahre
        neuBis = `${neuBis.getFullYear()}-${String(neuBis.getMonth() + 1).padStart(2, '0')}-${String(neuBis.getDate()).padStart(2, '0')}`;
        repeat = {
          von: this.zeitVon.tag,
          bis: neuBis,
          mustertyp: 'Tage',
          musterintervall: 1,
          jaehrlichTag: new Date(this.zeitVon.tag).getDate(),
          jaehrlichMonat: new Date(this.zeitVon.tag).getMonth() + 1,
          monatlichTag: new Date(this.zeitVon.tag).getDate(),
          woechentlichWochentage: {
            montags: false,
            dienstags: false,
            mittwochs: false,
            donnerstags: false,
            freitags: false,
            samstags: false,
            sonntags: false,
          },
          ausnahmen: [],
        };
        const wochentag = String(new Date(this.zeitVon.tag).getDay());
        switch (wochentag) {
          case ('1'):
            repeat.woechentlichWochentage.montags = true;
            break;
          case ('2'):
            repeat.woechentlichWochentage.dienstags = true;
            break;
          case ('3'):
            repeat.woechentlichWochentage.mittwochs = true;
            break;
          case ('4'):
            repeat.woechentlichWochentage.donnerstags = true;
            break;
          case ('5'):
            repeat.woechentlichWochentage.freitags = true;
            break;
          case ('6'):
            repeat.woechentlichWochentage.samstags = true;
            break;
          default:
            repeat.woechentlichWochentage.sonntags = true;
        }
      } else {
        repeat = this.event.repeat;
      }
      this.event.repeat = repeat;
    },
    checkOpenDialog() {
      if (this.idevent >= 0) {
        // Wenn eine ID übergeben wurde
        this.allowEdit = false;
        this.openDialog = true;
        [this.event] = this.$store.state.f110.events
          .filter((o) => parseInt(o.id, 10) === this.idevent);
        this.edit = false;
        if (!this.event) {
          const help = {
            id: 0,
            name: '',
            beschreibung: '',
            start: 0,
            end: 0,
            id_kalenderkategorie: 0,
            timed: true,
            istSerie: false,
            repeat: {},
            color: '',
            ausSerie: 0,
          };
          const now = new Date();
          const nowHours = String(now.getHours()).padStart(2, '0');
          const nowMinutes = String(now.getMinutes()).padStart(2, '0');
          help.start = new Date(`${this.calValue}T${nowHours}:${nowMinutes}:00`).getTime();
          help.end = help.start;
          if (
            this.$store.state.f110.store.kalender.stats.allowEditKategorien.length > 0
          ) {
            // this.$store.state.f110.store.settings.standard_id_kategorie
            if (
              this.$store.state.f110.store.kalender.stats.allowEditKategorien
                .filter((o) => o.id === this.$store.state.f110.store.settings.standard_id_kategorie)
                .length > 0
            ) {
              help.id_kalenderkategorie = this.$store.state.f110.store
                .settings.standard_id_kategorie;
            } else {
              help.id_kalenderkategorie = this.$store.state.f110.store
                .kalender.stats.allowEditKategorien
                .filter((o) => o.id !== this.$store.state.f110.store
                  .settings.standard_id_kategorie)[0].id;
            }
            help.color = this.$store.state.f110.store.kalender.kategorien
              .filter((o) => o.id === help.id_kalenderkategorie)[0].farbe;
            this.allowEdit = true;
          }
          this.event = help;
          this.edit = true;
        }
        if (
          this.$store.state.f110.store.kalender.stats.allowEditKategorien
            .filter((o) => o.id === this.event.id_kalenderkategorie).length > 0
        ) {
          this.allowEdit = true;
        }
        this.ganztaegig = !this.event.timed;
        this.event = JSON.parse(JSON.stringify(this.event));
        this.zeitVon = this.convertTimeToDate(this.event.start);
        this.zeitBis = this.convertTimeToDate(this.event.end);
        if (!this.event.istSerie) {
          this.updateSeriensettings();
        } else {
          this.updateSeriensettings();
        }
        this.istSerie = this.event.istSerie;
        const neuAusnahmen = [];
        if (this.event.repeat.ausnahmen.length > 0) {
          this.event.repeat.ausnahmen.forEach((a) => {
            neuAusnahmen.push(this.convertAusnahme(a));
          });
        }
        neuAusnahmen.sort((a, b) => {
          if (a.von < b.von) {
            return -1;
          }
          if (a.von > b.von) {
            return 1;
          }
          return 0;
        });
        this.event.repeat.ausnahmen = neuAusnahmen;
      } else {
        // Wenn keine ID übergeben wurde
        this.openDialog = false;
      }
    },
    close() {
      this.$router.push({ path: '/110' });
    },
  },
  created() {
    this.checkOpenDialog();
  },
};
</script>
