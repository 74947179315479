<template>
  <v-alert
    border="bottom"
    colored-border
    elevation="2"
    :class="{'hide': (
      meldung.anzeigePlanen
      &&
      (
        timestampNow - meldung.von_timestamp < 0
        || (
          meldung.bis_timestamp - timestampNow < 0
          && meldung.bis_timestamp > 0
        )
      )
    )}"
    :icon="meldung.icon"
    :color="meldung.color"
  >
    <v-row align="center">
      <v-col
        v-if="!prop_settings.w1_bedingung_meldungErstellen_nutzer"
      >
        <h3>{{ meldung.titel }} </h3>
        <p v-html="meldung.textNl2br"></p>
      </v-col>
      <v-col
        v-if="prop_settings.w1_bedingung_meldungErstellen_nutzer"
        cols="10" sm="11"
      >
        <h3>{{ meldung.titel }}</h3>
        <p v-html="meldung.textNl2br"></p>
      </v-col>
      <v-col
        v-if="prop_settings.w1_bedingung_meldungErstellen_nutzer"
        cols="2"
        sm="1"
        align="center"
      >
        <v-row>
          <v-col v-if="typ === 'alert'">
            <v-dialog
              v-model="dialogs_delNews[meldung.id]"
              width="500"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  x-small
                  class="my-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>

              <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                  Sicher?
                </v-card-title>

                <v-card-text class="pt-8">
                  <p>Die Meldung <b>{{ meldung.titel }}</b> unwiderruflich löschen?</p>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-btn
                    text
                    @click="dialogs_delNews[meldung.id] = false"
                  >
                    Nichts tun
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="error"
                    text
                    @click="
                      $emit('saveNews', 'delMeldung', meldung.id);
                    "
                    :loading="loading.delMeldung"
                  >
                    Löschen
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col v-else>
            <v-btn
              icon
              x-small
              class="my-2"
              @click="$emit('closePopup', {})"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              icon
              x-small
              class="my-2"
              @click="$emit('editNews', meldung);"
            >
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div
      v-if="meldung.rueckmeldung.nutzen"
    >
      <div align="right">
        <Widget1NewsfeedRueckmeldungsergebnisse
          v-if="prop_settings.w1_bedingung_meldungErstellen_nutzer"
          :prop_meldung="meldung"
          @refresh="$emit('refreshUsers', meldung.id, $event)"
        />
      </div>
      <v-card class="my-4 pt-2 pa-6"
        v-if="
          (
            meldung.rueckmeldung.user.kannAendern
            || !prop_settings.w1_bedingung_meldungErstellen_nutzer
          ) && meldung.rueckmeldung.formularfelder.filter((o) => meldung.rueckmeldung.user.nutzerparameter.includes(o.id)).length > 0
        "
      >
        <EditUserParameter
          :user="meldung.rueckmeldung.user"
          :parameter="meldung.rueckmeldung.formularfelder"
          :disabled="!meldung.rueckmeldung.user.kannAendern"
        />
      </v-card>
      <div class="d-flex justify-center"
        v-if="
          meldung.rueckmeldung.user.kannAendern
          && meldung.rueckmeldung.formularfelder.filter((o) => meldung.rueckmeldung.user.nutzerparameter.includes(o.id)).length > 0
        "
      >
        <v-btn x-small
          @click="
            $emit('saveNews', 'saveRueckmeldung', { meldung, kinder: $store.state.main.save.user.f200.kinder });
          "
          :loading="loading.saveRueckmeldung"
        >
          Angaben abschicken
        </v-btn>
      </div>
    </div>
    <v-row class="ma-0">
      <v-col class="pa-0">
        <span v-if="
          meldung.anzeigePlanen
          && prop_settings.w1_bedingung_meldungErstellen_nutzer
        "
          style="font-size:70%;"
        >
          {{ new Date(meldung.von_date).toLocaleString('de-DE', {
            year: 'numeric', month: '2-digit', day: '2-digit'
          }) }}
          {{ meldung.von_time }}
          -
          <span v-if="
            meldung.von_date !== meldung.bis_date
          ">
            {{ new Date(meldung.bis_date).toLocaleString('de-DE', {
              year: 'numeric', month: '2-digit', day: '2-digit'
            }) }}
          </span>
          {{ meldung.bis_time }}
          <span v-if="
            meldung.sendmailsettings
          ">
            [ Sende per E-Mail ]
          </span>
        </span>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
import EditUserParameter from '@/components/Inputs/EditUserParameter.vue';
import Widget1NewsfeedRueckmeldungsergebnisse from '@/components/Funktionen/000/000-Dashboard/Widgets/Widget1NewsfeedRueckmeldungsergebnisse.vue';

export default {
  components: {
    EditUserParameter,
    Widget1NewsfeedRueckmeldungsergebnisse,
  },
  props: {
    typ: {
      type: String,
      default: 'alert',
    },
    meldung: {
      type: Object,
      default() { return {}; },
    },
    prop_settings: {
      type: Object,
      default() { return {}; },
    },
    dialogs_delNews: {
      type: Object,
      default() { return {}; },
    },
    loading: {
      type: Object,
      default() { return {}; },
    },
    timestampNow: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
  }),
  computed: {
  },
  watch: {
  },
  methods: {
  },
  created() {
  },
};
</script>
