var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"width":"972","fullscreen":_vm.$vuetify.breakpoint.xs,"scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-btn',attrs,false),on),[_vm._v(" Rückmeldungen ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.meldung.titel))]),_c('v-spacer'),_c('v-toolbar-items')],1),_c('v-card-text',{staticStyle:{"height":"100%"}},[_c('p',{staticClass:"pt-2"},[_vm._v(" Rückmeldungen: ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.items,"item-key":"name","items-per-page":20,"header-props":_vm.headerProps,"footer-props":_vm.footerProps,"no-data-text":"Noch keine Rückmeldungen empfangen","no-results-text":"Keine Daten gefunden","loading-text":"Lade Daten... Bitte warten"},scopedSlots:_vm._u([{key:"item.kannAendern",fn:function(ref){
var item = ref.item;
return [(!item.kannAendern)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check-all ")]):_vm._e(),(item.kannAendern)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-refresh ")]):_vm._e(),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-menu")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.refresh('delete', { userId: item.id });}}},[_vm._v(" Rückmeldung Löschen ")])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.refresh('allowRefill', { userId: item.id });}}},[_vm._v(" Erlaube ändern ")])],1)],1)],1)]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"80%"}},[_vm._v(" "+_vm._s(item.date)+" ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"footer.prepend",fn:function(){return [_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary","dark":"","small":"","loading":_vm.loading.refresh},on:{"click":function($event){return _vm.refresh('refresh', {});}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"color":"primary","dark":"","small":"","loading":_vm.loading.deleteAll || _vm.loading.allowRefillAll}},'v-btn',attrs,false),on),[_vm._v(" Alle zurücksetzen ")])]}}]),model:{value:(_vm.dialogDelAll),callback:function ($$v) {_vm.dialogDelAll=$$v},expression:"dialogDelAll"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Sicher? ")]),_c('v-card-text',{staticClass:"pt-8"},[_c('p',[_vm._v(" Alle empfangenen Rückmeldungen zurücksetzen sodass die Antworten neu eingegeben werden können?"),_c('br'),_vm._v(" Oder sollen alle Rückmeldungen unwiederruflich gelöscht werden? ")])]),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){_vm.dialogDelAll = false}}},[_vm._v(" Nichts tun ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"warning","text":"","small":""},on:{"click":function($event){_vm.dialogDelAll = false;_vm.refresh('allowRefillAll', {});}}},[_vm._v(" Zurücksetzen ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":"","small":""},on:{"click":function($event){_vm.dialogDelAll = false;_vm.refresh('deleteAll', {});}}},[_vm._v(" Löschen ")])],1)],1)],1)]},proxy:true}])})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" OK ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }