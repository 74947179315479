<template>
  <div style="display:inline-block;">
    <v-dialog
      v-model="showDialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          dark
          v-bind="attrs"
          v-on="on"
          :small="btnsize == 'small'"
          :x-small="btnsize == 'x-small'"
          :color="btncolor"
        >
          <v-icon
            :left="btntext !== ''"
            :small="btnsize == 'x-small'"
            :large="btnsize == ''"
          >{{ btnicon }}</v-icon>
          {{ btntext }}
        </v-btn>
      </template>

      <v-card>
        <v-card-title>
          {{ titel }}
        </v-card-title>

        <v-card-text>
          <qrcode-stream
            v-if="showDialog"
            @decode="onQrCodeDecode"
          ></qrcode-stream>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="showDialog = false"
          >
            {{ abbrechen }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { QrcodeStream } from 'vue-qrcode-reader';

export default {
  components: {
    QrcodeStream,
  },
  props: {
    titel: {
      type: String,
      default: 'QR-Code scannen',
    },
    abbrechen: {
      type: String,
      default: 'abbrechen',
    },
    btntext: {
      type: String,
      default: '',
    },
    btnicon: {
      type: String,
      default: 'mdi-qrcode-scan',
    },
    btnsize: {
      type: String,
      default: '',
    },
    btncolor: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {
      showDialog: false,
    };
  },
  computed: {
  },
  watch: {
  },
  methods: {
    onQrCodeDecode(e) {
      this.$emit('scanned', e);
      this.showDialog = false;
    },
  },
  created() {
  },
};
</script>
