var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-tabs',{staticClass:"d-md-none mb-6",attrs:{"fixed-tabs":""},model:{value:(_vm.tabs_eigenschaftenRechte),callback:function ($$v) {_vm.tabs_eigenschaftenRechte=$$v},expression:"tabs_eigenschaftenRechte"}},[_c('v-tab',[_vm._v("Eigenschaften")]),_c('v-tab',[_vm._v("Rechte")])],1),_c('v-row',[(
          _vm.tabs_eigenschaftenRechte === 0 ||
          _vm.$vuetify.breakpoint.mdAndUp
        )?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-container',{staticClass:"text-title text--secondary text-uppercase text-center d-none d-md-block"},[_vm._v(" Eigenschaften ")]),_c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"pa-0 ma-0 mb-2",attrs:{"label":"Nutzername","prepend-icon":"mdi-account","rules":[
                    _vm.$store.state.main.inputValidationRules.required,
                    _vm.$store.state.main.inputValidationRules.nutzername ],"disabled":_vm.disable},model:{value:(_vm.user.nn),callback:function ($$v) {_vm.$set(_vm.user, "nn", $$v)},expression:"user.nn"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"10"}},[_c('v-text-field',{staticClass:"pa-0 ma-0",attrs:{"label":"Passwort","prepend-icon":"mdi-key","rules":[
                    _vm.$store.state.main.inputValidationRules.pw ],"disabled":_vm.disable},on:{"keyup":function($event){_vm.userChanged = true;}},model:{value:(_vm.userPW),callback:function ($$v) {_vm.userPW=$$v},expression:"userPW"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"2"}},[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"py-4",attrs:{"x-small":"","color":"error","disabled":_vm.user.id === '' || _vm.disable}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-lock-reset")])],1)]}}],null,false,2174211386),model:{value:(_vm.dialog_resetPW),callback:function ($$v) {_vm.dialog_resetPW=$$v},expression:"dialog_resetPW"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Sicher? ")]),_c('v-card-text',{staticClass:"pt-4"},[_vm._v(" Es wird ein zufälliges Passwort generiert und angezeigt. Dieses sollte vom Benutzer so bald wie möglich zu einem beliebigen, sicheren Passwort geändert werden. ")]),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog_resetPW = false;}}},[_vm._v(" Nichts tun ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.tryResetPW();_vm.dialog_resetPW = false;}}},[_vm._v(" zurücksetzen ")])],1)],1)],1)],1)],1),_c('SelectNutzergruppen',{attrs:{"label":"Nutzergruppen","disabled":_vm.disable},model:{value:(_vm.user.gruppen),callback:function ($$v) {_vm.$set(_vm.user, "gruppen", $$v)},expression:"user.gruppen"}})],1)],1),_c('EditUserParameter',{attrs:{"user":_vm.user,"disabled":_vm.disable}})],1):_vm._e(),(
          _vm.tabs_eigenschaftenRechte === 1 ||
          _vm.$vuetify.breakpoint.mdAndUp
        )?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-container',{staticClass:"text-title text--secondary text-uppercase text-center d-none d-md-block"},[_vm._v(" Rechte ")]),_c('p',{staticClass:"text-caption text--secondary"},[_vm._v(" Der Nutzer besitzt automatisch die folgenden Rechte:"),_c('br'),_vm._v(" (Diese können durch Rechte von Gruppen ergänzt werden) ")]),_c('v-container',{staticClass:"pa-0"},[(_vm.rechte.length <= 0)?_c('v-skeleton-loader',{attrs:{"type":"list-item-three-line"}}):_c('v-treeview',{attrs:{"selectable":"","open-all":"","item-disabled":"locked","items":_vm.rechte,"align":"left"},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{class:{ 'disabled': _vm.disable }},[_vm._v(" "+_vm._s(item.icon ? item.icon : 'mdi-key')+" ")])]}}],null,false,4161680841),model:{value:(_vm.user.rechteSolo),callback:function ($$v) {_vm.$set(_vm.user, "rechteSolo", $$v)},expression:"user.rechteSolo"}})],1)],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"align":"center"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }