var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ma-0 pa-0 d-flex justify-center",attrs:{"fluid":""}},[_c('v-container',{staticClass:"ma-0 pa-2"},[_c('F420Lohnabrechnung',{attrs:{"idMitarbeiter":parseInt(_vm.currentTab.query.la_idm, 10)}}),_c('F420EditMitarbeiter',{attrs:{"idMitarbeiter":parseInt(_vm.currentTab.query.em_idm, 10)}}),_c('div',[_c('v-btn',{staticClass:"mx-1",attrs:{"x-small":"","loading":_vm.$store.state.f420.loading.getData},on:{"click":function($event){return _vm.$store.dispatch('f420/tryGetOrSaveSettings', {});}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-reload")]),_vm._v(" aktualisieren ")],1),_c('v-btn',{staticClass:"mx-1",attrs:{"color":"primary","x-small":"","dark":"","text":""},on:{"click":function($event){return _vm.$router.push({ path: '/420?em_idm=0' });}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-plus-box")]),_vm._v(" Neuer Mitarbeiter ")],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.$store.state.f420.store.mitarbeiter,"search":_vm.search,"loading":_vm.$store.state.f420.loading.getData,"items-per-page":50,"header-props":_vm.headerProps,"footer-props":_vm.footerProps,"no-data-text":"Keine Mitarbeiter gefunden","no-results-text":"Keine Mitarbeiter gefunden","loading-text":"Lade Daten... Bitte warten","mobile-breakpoint":"600"},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(ref){
var pageStart = ref.pageStart;
var pageStop = ref.pageStop;
var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+" - "+_vm._s(pageStop)+" von "+_vm._s(itemsLength)+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"color":"primary","dark":"","small":"","text":""},on:{"click":function($event){return _vm.$router.push({ path: ("/420?em_idm=" + (item.id)) });}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.aktion",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"color":"primary","dark":"","small":"","loading":parseInt(_vm.currentTab.query.la_idm, 10) === item.id},on:{"click":function($event){return _vm.$router.push({ path: ("/420?la_idm=" + (item.id)) });}}},[_vm._v(" Lohnabrechnung ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }