<template>
  <div>
    <p
      v-html="summen"
      class="pa-4"
    ></p>
    <v-data-table
      :headers="datatable1_headers"
      :items="vertraege"
      :items-per-page="-1"
      :header-props="headerProps"
      :footer-props="footerProps"
      no-data-text="Keine Verträge gefunden"
      no-results-text="Keine Verträge gefunden"
      loading-text="Lade Daten... Bitte warten"
      class="elevation-1"
    >
      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }} - {{ pageStop }} von {{ itemsLength }}
      </template>
      <template v-slot:item.kunde="{ item }">
        {{ item.kunde.kundennummer }}
      </template>
      <template v-slot:item.titel="{ item }">
        <v-dialog
          v-model="dialog_infoKuendgungBis[item.id]"
          scrollable
          max-width="800px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-chip color="warning" small
              v-if="
                Math.floor(item.zeitBisEndeKuendigungsfrist / 1000 / 60 / 60 / 24)
                <= 7 * 4
                && item.status.nummer !== '7'
              "
              v-bind="attrs"
              v-on="on"
            ><v-icon small left>mdi-alert</v-icon>{{ item.titel }}</v-chip>
            <v-chip color="error" small
              v-else-if="
                item.status.nummer === '7'
              "
              v-bind="attrs"
              v-on="on"
            ><v-icon small left>mdi-alert</v-icon>{{ item.titel }}</v-chip>
            <v-chip small
              v-else
              v-bind="attrs"
              v-on="on"
            >{{ item.titel }}</v-chip>
          </template>
          <v-card>
            <v-card-title class="grey lighten-3 mb-4">
              <div style="width:100%;font-size:80%;">
                <b class="grey--text">Vertragsdaten</b>
              </div>
              {{ item.titel }}
            </v-card-title>
            <v-card-text>
              <div
                v-if="
                  Math.floor(item.zeitBisEndeKuendigungsfrist / 1000 / 60 / 60 / 24)
                  <= 7 * 4
                  || item.status.nummer === '7'
                "
                style="width:100%;"
                class="mb-4"
              >
                <v-chip color="warning" small
                  v-if="
                    Math.floor(item.zeitBisEndeKuendigungsfrist / 1000 / 60 / 60 / 24)
                    <= 7 * 4
                  "
                >
                  <v-icon small left>mdi-alert</v-icon>
                  Ende Kündigungsfrist: <b class="ms-1">{{
                    new Date(item.kuendigungBis).toLocaleString('de-DE', {
                      year: 'numeric', month: '2-digit', day: '2-digit',
                    })
                  }}</b>
                </v-chip>
                <v-chip color="error" small
                  v-if="
                    item.status.nummer === '7'
                  "
                >
                  <v-icon small left>mdi-alert</v-icon>
                  Gekündigt zum <b class="ms-1">{{
                    new Date(item.ende).toLocaleString('de-DE', {
                      year: 'numeric', month: '2-digit', day: '2-digit',
                    })
                  }}</b>
                </v-chip>
              </div>
              Status: <v-icon left small>{{ item.status.icon }}</v-icon>
              <b>{{ item.status.kurz }}</b><br>
              Vertragsbeginn: <b>{{
                new Date(item.beginn).toLocaleString('de-DE', {
                  year: 'numeric', month: '2-digit', day: '2-digit',
                })
              }}</b><br>
              Vertragsende: <b>{{
                new Date(item.ende).toLocaleString('de-DE', {
                  year: 'numeric', month: '2-digit', day: '2-digit',
                })
              }}</b>
              <span
                v-if="
                  Math.floor(item.zeitBisVerlaengerung / 1000 / 60 / 60 / 24) === 1
                "
              >
                (noch 1 Tag)
              </span>
              <span
                v-if="
                  Math.floor(item.zeitBisVerlaengerung / 1000 / 60 / 60 / 24) !== 1
                "
              >
                (noch {{ Math.floor(item.zeitBisVerlaengerung / 1000 / 60 / 60 / 24) }} Tage)
              </span>
              <br>
              Ende Kündigungsfrist:
              <b>{{
                new Date(item.kuendigungBis).toLocaleString('de-DE', {
                  year: 'numeric', month: '2-digit', day: '2-digit',
                })
              }}</b>
              <span
                v-if="
                  Math.floor(item.zeitBisEndeKuendigungsfrist / 1000 / 60 / 60 / 24) === 1
                "
              >
                (noch 1 Tag)
              </span>
              <span
                v-if="
                  Math.floor(item.zeitBisEndeKuendigungsfrist / 1000 / 60 / 60 / 24) !== 1
                "
              >
                (noch {{ Math.floor(item.zeitBisEndeKuendigungsfrist / 1000 / 60 / 60 / 24) }} Tage)
              </span>
              <br>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="dialog_infoKuendgungBis[item.id] = false"
              >
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:item.ende="{ item }">
        {{
          new Date(item.ende).toLocaleString('de-DE', {
            year: 'numeric', month: '2-digit', day: '2-digit',
          })
        }}
      </template>
      <template v-slot:item.umsatz="{ item }">
        {{
          item.umsatz.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })
        }}
      </template>
      <template v-slot:item.zahlungsintervall="{ item }">
        <span v-if="item.zahlungsintervall === 0">
          monatlich
        </span>
        <span v-if="item.zahlungsintervall === 1">
          jährlich
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
// import $ from 'jquery';
// import BarChart from '@/components/Charts/BarChart.vue';

export default {
  components: {
    // BarChart,
  },
  props: {
    vertraege: {
      type: Array,
      default() { return []; },
    },
  },
  data: () => ({
    dialog_infoKuendgungBis: {},
    datatable1_headers: [
      {
        text: 'Kunde',
        align: 'start',
        sortable: true,
        value: 'kunde',
      },
      {
        text: 'Name',
        align: 'start',
        sortable: true,
        value: 'titel',
      },
      {
        text: 'Verlängerung',
        align: 'start',
        sortable: true,
        value: 'ende',
      },
      {
        text: 'Umsatz',
        align: 'start',
        sortable: true,
        value: 'umsatz',
      },
      {
        text: 'Intervall',
        align: 'start',
        sortable: true,
        value: 'zahlungsintervall',
      },
    ],
    headerProps: {
      sortByText: 'Sortiere nach',
    },
    footerProps: {
      itemsPerPageText: 'Elemente pro Seite:',
      itemsPerPageOptions: [5, 10, 20, 50, -1],
      itemsPerPageAllText: 'Alle',
    },
  }),
  computed: {
    summen() {
      let jaehrlich = 0;
      let monatlich = 0;
      let gesJaehrlich = 0;
      let gesMonatlich = 0;
      this.vertraege.forEach((v) => {
        if (v.zahlungsintervall === 1) {
          jaehrlich += v.umsatz;
          gesJaehrlich += v.umsatz;
          gesMonatlich += v.umsatz / 12;
        } else {
          monatlich += v.umsatz;
          gesJaehrlich += v.umsatz * 12;
          gesMonatlich += v.umsatz;
        }
      });
      jaehrlich = jaehrlich.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' });
      monatlich = monatlich.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' });
      gesJaehrlich = gesJaehrlich.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' });
      gesMonatlich = gesMonatlich.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' });
      /* return `
        <h3 style="margin:10px 0px 10px 0px;">Umsätze Insgesamt:</h3>
        Jährlich: <b>${gesJaehrlich}</b> (<b>${jaehrlich}</b> + 12x <b>${monatlich}</b>)<br>
        Monatlich: <b>${gesMonatlich}</b> (<b>${monatlich}</b> + <b>${jaehrlich}</b> / 12)<br>
      `; */
      return `
        <h3 style="margin:10px 0px 10px 0px;">Umsätze Insgesamt:</h3>
        ${this.vertraege.filter((o) => o.zahlungsintervall === '1').length}x Jährlich: <b>${jaehrlich}</b> (<b>${gesJaehrlich}</b>)<br>
        ${this.vertraege.filter((o) => o.zahlungsintervall === '0').length}x Monatlich: <b>${monatlich}</b> (<b>${gesMonatlich}</b>)<br>
      `;
    },
  },
  watch: {
  },
  methods: {
  },
  created() {
  },
};
</script>
