var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ma-0 pa-0 d-flex justify-center",attrs:{"fluid":""}},[_c('v-container',{staticClass:"ma-0 pa-2"},[(_vm.editKunde.id)?_c('EditKunde',{attrs:{"prop_kunde":_vm.editKunde,"prop_auftraege":_vm.prop_auftraege.filter(function (o) {
        if (o.kundennummer === _vm.editKunde.kundennummer) {
          return o;
        }
      }),"prop_kosten":_vm.prop_kosten,"prop_settings":_vm.prop_settings,"prop_isSaving_editKunde":_vm.isSaving_editKunde,"prop_isSaving_delKunde":_vm.isSaving_delKunde},on:{"close":function($event){_vm.editKunde = {}},"save":function($event){return _vm.saveKunde($event);},"del":function($event){return _vm.delKunde($event);},"tryGetOrSave":function($event){return _vm.$emit('tryGetOrSave', $event);}}}):_vm._e(),_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"small":"","color":"primary","loading":_vm.isSaving_addKunde},on:{"click":function($event){return _vm.addKunde();}}},[_c('v-icon',[_vm._v("mdi-plus-box")])],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{staticClass:"ma-0",attrs:{"clearable":"","dense":"","label":"Suchen...","append-icon":"mdi-magnify","rules":[
            _vm.$store.state.main.inputValidationRules.name ]},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.save.kunden,"search":_vm.search,"loading":_vm.loading,"items-per-page":50,"header-props":_vm.headerProps,"footer-props":_vm.footerProps,"no-data-text":"Keine Kunden gefunden","no-results-text":"Keine Kunden gefunden","loading-text":"Lade Daten... Bitte warten","mobile-breakpoint":"600"},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(ref){
          var pageStart = ref.pageStart;
          var pageStop = ref.pageStop;
          var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+" - "+_vm._s(pageStop)+" von "+_vm._s(itemsLength)+" ")]}},{key:"item.ident",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"primary--text",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.editKunde = item}}},[(item.ident !== '')?_c('b',[_vm._v(" "+_vm._s(item.ident)+" ")]):_c('b',[_vm._v(" -- ")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }