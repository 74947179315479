<template>
  <v-container
    fluid
    class="ma-0 pa-0"
  >
    <v-row
    class="my-0"
    v-for="(param, idx) in parameter
        .filter((o) => user.nutzerparameter.includes(o.id))"
    :key="idx"
    >
      <template
        v-if="!(!getBedingungen(param).bearbeiten || disabled)"
      >
        <v-col cols="12" class="ma-0 pa-0">
          <div
            v-if="param.beschreibung"
            style="font-size:80%;"
          >
            {{ param.beschreibung }}
        </div>
        </v-col>
        <v-col cols="12" class="ma-0 pa-0">
          <v-text-field
          v-if="param.typ === 1"
          :disabled="!getBedingungen(param).bearbeiten || disabled"
          :label="param.name"
          :prepend-icon="param.icon"
          v-model="user.nutzerparameterwerte[param.id]"
          :rules="[
              $store.state.main.inputValidationRules.name,
          ]"
          @keyup="emitEvent('change');"
          ></v-text-field>
          <v-textarea
          v-if="param.typ === 2"
          :disabled="!getBedingungen(param).bearbeiten || disabled"
          :label="param.name"
          :prepend-icon="param.icon"
          v-model="user.nutzerparameterwerte[param.id]"
          @keyup="emitEvent('change');"
          ></v-textarea>
          <v-text-field
          v-if="param.typ === 3"
          :disabled="!getBedingungen(param).bearbeiten || disabled"
          type="number"
          :label="param.name"
          :prepend-icon="param.icon"
          v-model="user.nutzerparameterwerte[param.id]"
          :rules="[
              $store.state.main.inputValidationRules.nummer,
          ]"
          @keyup="emitEvent('change');"
          ></v-text-field>
          <v-row
            v-if="param.typ === 4"
            class="px-3 my-0"
            align="center"
          >
            <v-col cols="10" class="ma-0 pa-0">
              <v-text-field
              :disabled="!getBedingungen(param).bearbeiten || disabled"
              type="email"
              :label="param.name"
              :prepend-icon="param.icon"
              v-model="user.nutzerparameterwerte[param.id]"
              :rules="[
                  $store.state.main.inputValidationRules.email,
              ]"
              @keyup="emitEvent('change');"
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="ma-0 pa-0" align="center">
              <v-btn
                :href="`mailto:${user.nutzerparameterwerte[param.id]}`"
                x-small
                :disabled="!user.nutzerparameterwerte[param.id]"
              >
                <v-icon small>mdi-open-in-new</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row
            v-if="param.typ === 5"
            class="px-3 my-0"
            align="center"
          >
            <v-col cols="10" class="ma-0 pa-0">
              <v-text-field
              :disabled="!getBedingungen(param).bearbeiten || disabled"
              type="tel"
              :label="param.name"
              :prepend-icon="param.icon"
              v-model="user.nutzerparameterwerte[param.id]"
              :rules="[
                  $store.state.main.inputValidationRules.telefon,
              ]"
              @keyup="emitEvent('change');"
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="ma-0 pa-0" align="center">
              <v-btn
                :href="`tel:${user.nutzerparameterwerte[param.id]}`"
                x-small
                :disabled="!user.nutzerparameterwerte[param.id]"
              >
                <v-icon small>mdi-open-in-new</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row
            v-if="param.typ === 6"
            class="px-3 my-0"
            align="center"
          >
            <v-col cols="10" class="ma-0 pa-0">
              <v-text-field
              :disabled="!getBedingungen(param).bearbeiten || disabled"
              type="url"
              :label="param.name"
              :prepend-icon="param.icon"
              v-model="user.nutzerparameterwerte[param.id]"
              :rules="[
                  $store.state.main.inputValidationRules.url,
              ]"
              @keyup="emitEvent('change');"
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="ma-0 pa-0" align="center">
              <v-btn
                :href="`https://${user.nutzerparameterwerte[param.id]}`"
                target="_blank"
                x-small
                :disabled="!user.nutzerparameterwerte[param.id]"
              >
                <v-icon small>mdi-open-in-new</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <DatePicker
          v-if="param.typ === 7"
          :disabled="!getBedingungen(param).bearbeiten || disabled"
          :label="param.name"
          :icon="param.icon"
          v-model="user.nutzerparameterwerte[param.id]"
          @change="emitEvent('change');"
          />
          <TimePicker
          v-if="param.typ === 8"
          :disabled="!getBedingungen(param).bearbeiten || disabled"
          :label="param.name"
          :icon="param.icon"
          v-model="user.nutzerparameterwerte[param.id]"
          @change="emitEvent('change');"
          />
          <!-- <Selectmenu
          v-if="param.typ === '9'"
          :disabled="!getBedingungen(param).bearbeiten || disabled"
          :label="param.name"
          :icon="param.icon"
          :options="
              $store.state.main.save.nutzerparameter
              .filter((o) => o.id === param.id)[0].selectoptions
          "
          v-model="user.nutzerparameterwerteSelectoptionIDs[param.id]"
          @change="emitEvent('change');"
          no-data-text="Keine Auswahl verfügbar"
          /> -->
          <v-select
            v-if="param.typ === 9"
            :disabled="!getBedingungen(param).bearbeiten || disabled"
            :label="param.name"
            :icon="param.icon"
            :items="parameter
              .filter((o) => o.id === param.id)[0].selectoptions"
            item-text="wert"
            item-value="id"
            v-model="user.nutzerparameterwerteSelectoptionIDs[param.id]"
            @change="emitEvent('change');"
            no-data-text="Keine Daten verfügbar"
          />
          <v-select
            v-if="param.typ === 10"
            multiple
            :disabled="!getBedingungen(param).bearbeiten || disabled"
            :label="param.name"
            :icon="param.icon"
            :items="parameter
              .filter((o) => o.id === param.id)[0].selectoptions"
            item-text="wert"
            item-value="id"
            v-model="user.nutzerparameterwerteSelectoptionIDs[param.id]"
            @change="emitEvent('change');"
            no-data-text="Keine Daten verfügbar"
          />
          <v-select
            v-if="
              param.typ === 11
            "
            :disabled="!getBedingungen(param).bearbeiten || disabled"
            :label="param.name"
            :icon="param.icon"
            :items="$store.state.main.save.user.f200.kinder"
            item-text="ident"
            item-value="id"
            v-model="user.nutzerparameterwerteSelectoptionIDs[param.id]"
            @change="emitEvent('change');"
            no-data-text="Keine Kinder verfügbar"
          />
          <v-select
            v-if="
              param.typ === 12
            "
            multiple
            :disabled="!getBedingungen(param).bearbeiten || disabled"
            :label="param.name"
            :icon="param.icon"
            :items="$store.state.main.save.user.f200.kinder"
            item-text="ident"
            item-value="id"
            v-model="user.nutzerparameterwerteSelectoptionIDs[param.id]"
            @change="emitEvent('change');"
            no-data-text="Keine Kinder verfügbar"
          />
        </v-col>
      </template>
      <template
        v-else-if="getBedingungen(param).sehen"
      >
        <p class="nutzerparameterReadonly">
          <v-icon
            class="icon"
          >{{ param.icon }}</v-icon>
          <span
            class="span"
          >{{ param.name }}:</span>
          <template
            v-if="param.typ === 4 && user.nutzerparameterwerte[param.id]"
          >
            {{ user.nutzerparameterwerte[param.id] }}
            <v-btn
              :href="`mailto:${user.nutzerparameterwerte[param.id]}`"
              x-small
              :disabled="!user.nutzerparameterwerte[param.id]"
              class="ml-2"
            >
              <v-icon small>mdi-open-in-new</v-icon>
            </v-btn>
          </template>
          <template
            v-else-if="param.typ === 5 && user.nutzerparameterwerte[param.id]"
          >
            {{ user.nutzerparameterwerte[param.id] }}
            <v-btn
              :href="`tel:${user.nutzerparameterwerte[param.id]}`"
              x-small
              :disabled="!user.nutzerparameterwerte[param.id]"
              class="ml-2"
            >
              <v-icon small>mdi-open-in-new</v-icon>
            </v-btn>
          </template>
          <template
            v-else-if="param.typ === 6 && user.nutzerparameterwerte[param.id]"
          >
            {{ user.nutzerparameterwerte[param.id] }}
            <v-btn
              :href="`https://${user.nutzerparameterwerte[param.id]}`"
              target="_blank"
              x-small
              :disabled="!user.nutzerparameterwerte[param.id]"
              class="ml-2"
            >
              <v-icon small>mdi-open-in-new</v-icon>
            </v-btn>
          </template>
          <template
            v-else-if="param.typ === 7 && user.nutzerparameterwerte[param.id]"
          >
            {{ formatiereDatum(user.nutzerparameterwerte[param.id]) }}
          </template>
          <template
            v-else-if="
              param.typ === 9
              && parameter.filter((o) => o.id === param.id)[0].selectoptions
                .filter(
                  (o) => parseInt(o.id, 10) === parseInt(user.nutzerparameterwerteSelectoptionIDs[param.id], 10),
                )
                .length > 0
            "
          >
            {{
              parameter.filter((o) => o.id === param.id)[0].selectoptions
                .filter(
                  (o) => parseInt(o.id, 10) === parseInt(user.nutzerparameterwerteSelectoptionIDs[param.id], 10),
                )[0]
                .wert
            }}
          </template>
          <template
            v-else-if="
              param.typ === 10
              && user.nutzerparameterwerteSelectoptionIDs[param.id]
            "
          >
            <span
              v-for="(val, idx) in user.nutzerparameterwerteSelectoptionIDs[param.id]"
              :key="idx"
            >
              {{
                parameter.filter((o) => o.id === param.id)[0].selectoptions
                  .filter((o) => o.id === parseInt(val, 10))[0].wert
              }}
              <span
                v-if="
                  idx < (user.nutzerparameterwerteSelectoptionIDs[param.id].length - 1)
                  && user.nutzerparameterwerteSelectoptionIDs[param.id].length > 1
                "
              >,</span>
            </span>
          </template>
          <template
            v-else-if="
              param.typ === 11
              && $store.state.main.save.user.f200.kinder
                .filter((o) => o.id === user.nutzerparameterwerteSelectoptionIDs[param.id])
                .length > 0
            "
          >
            {{
              $store.state.main.save.user.f200.kinder
                .filter(
                  (o) => o.id === parseInt(user.nutzerparameterwerteSelectoptionIDs[param.id], 10),
                )[0]
                .ident
            }}
          </template>
          <template
            v-else-if="
              param.typ === 12
              && user.nutzerparameterwerteSelectoptionIDs[param.id]
            "
          >
            <span
              v-for="(val, idx) in user.nutzerparameterwerteSelectoptionIDs[param.id]"
              :key="idx"
            >
              {{
                $store.state.main.save.user.f200.kinder
                  .filter((o) => o.id === parseInt(val, 10))[0].ident
              }}
              <span
                v-if="
                  idx < (user.nutzerparameterwerteSelectoptionIDs[param.id].length - 1)
                  && user.nutzerparameterwerteSelectoptionIDs[param.id].length > 1
                "
              >,</span>
            </span>
          </template>
          <template
            v-else-if="!user.nutzerparameterwerte[param.id]"
          >
            --
          </template>
          <template
            v-else
          >
            {{ user.nutzerparameterwerte[param.id] }}
          </template>
        </p>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import DatePicker from '@/components/Inputs/DatePicker.vue';
import TimePicker from '@/components/Inputs/TimePicker.vue';
// import Selectmenu from '@/components/Inputs/Selectmenu.vue';

export default {
  components: {
    DatePicker,
    TimePicker,
    // Selectmenu,
  },
  props: {
    user: {
      type: Object,
      default() { return {}; },
    },
    parameter: {
      type: Array,
      default() { return this.$store.state.main.save.nutzerparameter; },
    },
    bedingungen: {
      type: Array,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    usergruppen: '',
  }),
  watch: {
    user: {
      handler() {
        if (this.usergruppen !== JSON.stringify(this.user.gruppen)) {
          this.getParameter();
          this.usergruppen = JSON.stringify(this.user.gruppen);
        }
      },
      deep: true,
    },
  },
  methods: {
    formatiereDatum(datum) {
      let datumR = '';
      if (datum) {
        if (datum.includes('-')) {
          datumR = datum.split('-');
        }
      }
      return `${datumR[2]}.${datumR[1]}.${datumR[0]}`;
    },
    getParameter() {
      // Wenn die Gruppen des Nutzers verändert wurden
      // Passe die Parameter der Gruppenauswahl an:
      if (this.user.gruppen) {
        const gruppen = this.$store.state.main.save.nutzergruppen
          .filter((o) => this.user.gruppen.includes(o.id));
        const parameter = gruppen.map((o) => o.nutzerparameter);
        // Arrays in parameter mergen:
        if (parameter.length > 0) {
          const neuParameter = [];
          parameter.forEach((p) => {
            neuParameter.push(...p);
          });
          this.user.nutzerparameter = neuParameter;
        } else {
          this.user.nutzerparameter = [];
        }
      }
    },
    getBedingungen(param) {
      let sehen = true;
      let bearbeiten = true;
      if (this.bedingungen) {
        // Sehen:
        const sehenGruppen = param.bedingungen[this.bedingungen].sehen.gruppen
          .filter((o) => this.$store.state.main.save.user.gruppen
            .includes(o)).length > 0;
        if (
          !param.bedingungen[this.bedingungen].sehen.alle
          && !sehenGruppen
        ) {
          sehen = false;
        }
        // Bearbeiten:
        const bearbeitenGruppen = param.bedingungen[this.bedingungen].bearbeiten.gruppen
          .filter((o) => this.$store.state.main.save.user.gruppen
            .includes(o)).length > 0;
        if (
          !param.bedingungen[this.bedingungen].bearbeiten.alle
          && !bearbeitenGruppen
        ) {
          bearbeiten = false;
        }
        // Wenn Bearbeiten erlaubt ist, sollte auch Sehen erlaubt sein:
        if (bearbeiten) {
          sehen = bearbeiten;
        }
      }
      return {
        sehen,
        bearbeiten,
      };
    },
    emitEvent(value) {
      this.$emit(value);
    },
  },
  created() {
    if (this.user.nutzerparameterwerte.length <= 0) {
      this.user.nutzerparameterwerte = {};
    }
  },
};
</script>

<style>
  .v-input--is-disabled,
  .v-input--is-disabled * {
    color: #000 !important;
  }

  .nutzerparameterReadonly {
    position:relative;margin-top:20px;
  }
  .nutzerparameterReadonly .icon {
    width:25px;margin-right:3px;
  }
  .nutzerparameterReadonly .span {
    position:absolute;left:35px;top:-18px;font-size:80%;opacity:0.6;white-space:nowrap;
  }
</style>
