<template>
    <v-menu
    ref="menu"
    v-model="menu2"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="time"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
    >
    <template v-slot:activator="{ on, attrs }">
        <v-text-field
        v-model="time"
        :label="label"
        :prepend-icon="icon"
        readonly
        v-bind="attrs"
        v-on="on"
        :disabled="disabled"
        ></v-text-field>
    </template>
    <v-time-picker
        v-if="menu2"
        v-model="time"
        format="24hr"
        full-width
        @click:minute="$refs.menu.save(time);emitEvent('change');"
    ></v-time-picker>
    </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'Uhrzeit',
    },
    icon: {
      type: String,
      default: 'mdi-clock-time-four-outline',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      time: null,
      menu2: false,
      modal2: false,
    };
  },
  watch: {
    time(neu) {
      this.$emit('input', neu);
    },
    value(neu) {
      this.time = neu;
    },
  },
  methods: {
    emitEvent(value) {
      this.$emit(value);
    },
  },
  created() {
    this.time = this.value;
  },
};
</script>
