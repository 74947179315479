var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.doRender)?_c('div',{staticClass:"view view_Kunden"},[(_vm.tabs.length > 1)?_c('viewTabsTop',{attrs:{"tabs":_vm.tabs}}):_vm._e(),(_vm.offline)?_c('v-container',[_c('v-alert',{staticClass:"ma-2",staticStyle:{"font-size":"80%"},attrs:{"dense":"","transition":"scale-transition","type":"error"}},[_c('b',[_vm._v("KEINE NETZWERKVERBINDUNG")]),_c('br'),_vm._v(" Die angezeigten Daten können veraltet sein. Bevor Änderungen vorgenommen werden, vergewissern Sie sich, dass die Daten aktuell sind. ")])],1):_vm._e(),(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","absolute":""}}):_vm._e(),(
      _vm.currentTab.code === ''
      && _vm.tabs.filter(function (o) { return o.erlaubt === 'x|410|000'; }).length > 0
    )?_c('transition',{attrs:{"name":"custom-classes-transition","enter-active-class":_vm.transitionIn,"leave-active-class":_vm.transitionOut}},[(_vm.save.xtime)?_c('Kundenliste',{attrs:{"prop_settings":_vm.save.settings,"prop_kunden":_vm.save.kunden,"prop_auftraege":_vm.save.auftraege,"prop_kosten":_vm.save.kosten},on:{"tryGetOrSave":function($event){return _vm.tryGetOrSave($event)}}}):_vm._e()],1):(
      _vm.currentTab.code === '100'
      && _vm.tabs.filter(function (o) { return o.erlaubt === 'x|410|000'; }).length > 0
    )?_c('transition',{attrs:{"name":"custom-classes-transition","enter-active-class":_vm.transitionIn,"leave-active-class":_vm.transitionOut}},[(_vm.save.xtime)?_c('Auftraege',{attrs:{"prop_settings":_vm.save.settings,"prop_auftraege":_vm.save.auftraege,"prop_kunden":_vm.save.kunden,"prop_kosten":_vm.save.kosten},on:{"tryGetOrSave":function($event){return _vm.tryGetOrSave($event)}}}):_vm._e()],1):(
      _vm.currentTab.code === '200'
      && _vm.tabs.filter(function (o) { return o.erlaubt === 'x|410|000'; }).length > 0
    )?_c('transition',{attrs:{"name":"custom-classes-transition","enter-active-class":_vm.transitionIn,"leave-active-class":_vm.transitionOut}},[(_vm.save.xtime)?_c('Kostenaufstellung',{attrs:{"prop_settings":_vm.save.settings,"prop_kosten":_vm.save.kosten,"prop_kunden":_vm.save.kunden,"prop_auftraege":_vm.save.auftraege},on:{"tryGetOrSave":function($event){return _vm.tryGetOrSave($event)}}}):_vm._e()],1):(
      _vm.currentTab.code === 'set'
      && _vm.tabs.filter(function (o) { return o.erlaubt === 'x|410|set'; }).length > 0
    )?_c('transition',{attrs:{"name":"custom-classes-transition","enter-active-class":_vm.transitionIn,"leave-active-class":_vm.transitionOut}},[(_vm.save.xtime)?_c('Einstellungen',{attrs:{"prop_settings":_vm.save.settings},on:{"tryGetOrSave":function($event){return _vm.tryGetOrSave($event)}}}):_vm._e()],1):_c('p',[_vm._v(" Zugriff verweigert ")]),(_vm.tabs.length > 1)?_c('viewTabsBottom',{attrs:{"tabs":_vm.tabs}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }