<template>
  <v-dialog
    fullscreen
    scrollable
    transition="dialog-bottom-transition"
    v-model="dialog_editKunde"
    width="1200"
  >
    <v-card>
      <v-card-title class="primary white--text" style="font-size:100%;">
        <v-container class="pa-0 d-flex justify-end">
          <span
            v-if="editKunde.ident !== ''"
          >
            {{ editKunde.kundennummer }}
          </span>
          <span
            v-else
          >
            Neuer Kunde
          </span>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog_delKunde"
            width="500"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                text dark
                :loading="prop_isSaving_delKunde"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                {{ editKunde.ident }} löschen?
              </v-card-title>

              <v-card-text class="pt-4">
                Es werden sämtliche Daten gelöscht.
                Aufträgs- und Vertragsdaten bleiben bestehen.
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-btn
                  text
                  @click="dialog_delKunde = false;"
                >
                  Nichts tun
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  text
                  @click="
                    dialog_delKunde = false;
                    $emit('del', editKunde.id);
                  "
                >
                  löschen
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn
            text dark
            @click="
              dialog_editKunde = false;
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-container>
      </v-card-title>
      <v-card-text class="pt-4">
        <v-container class="pa-0">
          <v-tabs
            fixed-tabs
            v-model="tabs_editKunde_infosAnschriften"
            class="d-md-none mb-6"
          >
            <v-tab>Kunde</v-tab>
            <v-tab>Aufträge</v-tab>
          </v-tabs>
          <v-row>
            <v-col
              cols="12"
              md="6"
              v-if="
                tabs_editKunde_infosAnschriften === 0 ||
                $vuetify.breakpoint.mdAndUp
              "
              class="pt-0"
            >
              <v-row class="d-none d-md-block">
                <v-col>
                  <p class="text-h6 pa-2 primary--text mb-0">
                    Kunde: {{ editKunde.kundennummer }}
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0">
                  <v-text-field
                    type="number"
                    label="Kundennummer"
                    v-model="editKunde.kundennummer"
                    :rules="[
                      $store.state.main.inputValidationRules.nummer,
                    ]"
                  ></v-text-field>
                  <v-text-field
                    type="text"
                    label="Name / Firma"
                    v-model="editKunde.firma"
                    :rules="[
                      $store.state.main.inputValidationRules.name,
                    ]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- <v-divider class="mb-4" />
              <p class="text-h6 pa-2 primary--text mb-4">
                Anschriften:
              </p> -->
              <v-row>
                <v-col>
                  <p>
                    Ansprechpartner:
                  </p>
                  <v-expansion-panels v-model="editKunde_extPan_ansprechpartner">
                    <v-expansion-panel
                      v-for="(anspr, idx) in editKunde.ansprechpartner"
                      :key="anspr.id"
                    >
                      <v-expansion-panel-header disable-icon-rotate>
                        <template v-slot:actions>
                          <v-btn small text color="primary"
                            @click="delAnsprechpartner(idx)"
                          >
                            <v-icon>mdi-minus-box</v-icon>
                          </v-btn>
                          <v-btn small text color="primary"
                            v-if="idx === editKunde.ansprechpartner.length - 1"
                            @click="addAnsprechpartner()"
                          >
                            <v-icon>mdi-plus-box</v-icon>
                          </v-btn>
                        </template>
                        <b>{{ anspr.bezeichnung }}</b>{{ anspr.nachname }}, {{ anspr.vorname }}
                        <div></div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col class="py-0">
                            <v-text-field
                              type="text"
                              label="Bezeichnung"
                              v-model="anspr.bezeichnung"
                              :rules="[
                                $store.state.main.inputValidationRules.name,
                              ]"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col class="py-0">
                            <v-select
                              label="Anrede"
                              :items="[
                                'Herr',
                                'Frau',
                              ]"
                              v-model="anspr.anrede"
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col class="py-0">
                            <v-text-field
                              type="text"
                              label="Vorname"
                              v-model="anspr.vorname"
                              :rules="[
                                $store.state.main.inputValidationRules.name,
                              ]"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col class="py-0">
                            <v-text-field
                              type="text"
                              label="Nachname"
                              v-model="anspr.nachname"
                              :rules="[
                                $store.state.main.inputValidationRules.name,
                              ]"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row align="center">
                          <v-col class="py-0">
                            <v-text-field
                              type="email"
                              label="E-Mail"
                              prepend-icon="mdi-email"
                              v-model="anspr.mail"
                              :rules="[
                                  $store.state.main.inputValidationRules.email,
                              ]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2" class="pa-0" align="center">
                            <v-btn
                              :href="`mailto:${anspr.mail}`"
                              x-small
                              :disabled="!anspr.mail"
                            >
                              <v-icon small>mdi-open-in-new</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                        <v-row align="center">
                          <v-col class="py-0">
                            <v-text-field
                              type="tel"
                              label="Telefon (Festnetz)"
                              prepend-icon="mdi-phone"
                              v-model="anspr.telefon_festnetz"
                              :rules="[
                                  $store.state.main.inputValidationRules.telefon,
                              ]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2" class="pa-0" align="center">
                            <v-btn
                              :href="`tel:${anspr.telefon_festnetz}`"
                              x-small
                              :disabled="!anspr.telefon_festnetz"
                            >
                              <v-icon small>mdi-open-in-new</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                        <v-row align="center">
                          <v-col class="py-0">
                            <v-text-field
                              type="tel"
                              label="Telefon (Mobil)"
                              prepend-icon="mdi-cellphone-basic"
                              v-model="anspr.telefon_mobil"
                              :rules="[
                                  $store.state.main.inputValidationRules.telefon,
                              ]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2" class="pa-0" align="center">
                            <v-btn
                              :href="`tel:${anspr.telefon_mobil}`"
                              x-small
                              :disabled="!anspr.telefon_mobil"
                            >
                              <v-icon small>mdi-open-in-new</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col class="py-0">
                            <v-textarea
                              auto-grow
                              label="Info"
                              v-model="anspr.info"
                              prepend-icon="mdi-information"
                              hint=""
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <div align="center">
                    <v-btn small text color="primary" class="ma-2"
                      v-if="editKunde.ansprechpartner.length <= 0"
                      @click="addAnsprechpartner();"
                    >
                      <v-icon>mdi-plus-box</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <!-- <v-divider class="mb-4" /> -->
              <v-row>
                <v-col>
                  <p>
                    Adressen:
                  </p>
                  <v-expansion-panels v-model="editKunde_extPan_anschriften">
                    <v-expansion-panel
                      v-for="(anschr, idx) in editKunde.anschriften"
                      :key="anschr.id"
                    >
                      <v-expansion-panel-header disable-icon-rotate>
                        <template v-slot:actions>
                          <v-btn small text color="primary"
                            :href="`https://maps.google.com/maps?q=${ anschr.strasse } ${ anschr.hausnummer }, ${ anschr.plz } ${ anschr.ort }`"
                            target="_blank"
                          >
                            <v-icon>mdi-map-marker</v-icon>
                          </v-btn>
                          <v-btn small text color="primary"
                            @click="delAnschrift(idx)"
                          >
                            <v-icon>mdi-minus-box</v-icon>
                          </v-btn>
                          <v-btn small text color="primary"
                            v-if="idx === editKunde.anschriften.length - 1"
                            @click="addAnschrift()"
                          >
                            <v-icon>mdi-plus-box</v-icon>
                          </v-btn>
                        </template>
                        <b>{{ anschr.bezeichnung }}</b>
                        <div class="pa-2">
                          {{ anschr.strasse }} {{ anschr.hausnummer }},
                          {{ anschr.plz }} {{ anschr.ort }}
                        </div>
                        <div></div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row align="center">
                          <v-col class="py-0">
                            <v-text-field
                              type="text"
                              label="Bezeichnung (nur intern)"
                              hint="Wird nicht auf Rechnungen etc. angezeigt"
                              v-model="anschr.bezeichnung"
                              :rules="[
                                $store.state.main.inputValidationRules.name,
                              ]"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row align="center">
                          <v-col class="py-0">
                            <v-text-field
                              type="text"
                              label="Firma"
                              v-model="anschr.firma"
                              :rules="[
                                $store.state.main.inputValidationRules.name,
                              ]"
                            ></v-text-field>
                            <v-text-field
                              type="text"
                              label="Suffix (optional)"
                              hint="Wird auf Rechnungen etc. nach dem Firmennamen angezeigt"
                              v-model="anschr.suffix"
                              :rules="[
                                $store.state.main.inputValidationRules.name,
                              ]"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col class="py-0">
                            <v-text-field
                              type="text"
                              label="Straße"
                              v-model="anschr.strasse"
                              :rules="[
                                $store.state.main.inputValidationRules.name,
                              ]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4" class="py-0">
                            <v-text-field
                              type="text"
                              label="Hausnummer"
                              v-model="anschr.hausnummer"
                              :rules="[
                                $store.state.main.inputValidationRules.name,
                              ]"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="4" class="py-0">
                            <v-text-field
                              type="text"
                              label="Postleitzahl"
                              v-model="anschr.plz"
                              :rules="[
                                $store.state.main.inputValidationRules.name,
                              ]"
                            ></v-text-field>
                          </v-col>
                          <v-col class="py-0">
                            <v-text-field
                              type="text"
                              label="Ort"
                              v-model="anschr.ort"
                              :rules="[
                                $store.state.main.inputValidationRules.name,
                              ]"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col class="py-0">
                            <v-textarea
                              auto-grow
                              label="Info"
                              v-model="anschr.info"
                              prepend-icon="mdi-information"
                              hint=""
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <div align="center">
                    <v-btn small text color="primary" class="ma-2"
                      v-if="editKunde.anschriften.length <= 0"
                      @click="addAnschrift();"
                    >
                      <v-icon>mdi-plus-box</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <EditUserParameter
                v-if="
                  editKunde.id_login > 0
                  && editKunde.nutzer.nutzerparameter
                "
                :user="editKunde.nutzer"
                :bedingungen="['f200']"
                class="mt-4"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
              v-if="
                tabs_editKunde_infosAnschriften === 1 ||
                $vuetify.breakpoint.mdAndUp
              "
              class="pt-0"
            >
              <p class="text-h6 pa-2 primary--text mb-0 d-none d-md-block">
                Aufträge:
              </p>
              <Auftraege
                :prop_settings="prop_settings"
                :prop_auftraege="prop_auftraege"
                :prop_kunden="[editKunde]"
                :prop_kosten="prop_kosten"
                :prop_addauftragKundennummer="editKunde.kundennummer_save"
                :prop_nuroffeneanzeigen="false"
                @tryGetOrSave="$emit('tryGetOrSave', $event);"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-container
          class="pa-0 d-flex justify-end"
        >
          <v-btn
            text small
            @click="
              dialog_editKunde = false;
            "
          >
            Schließen
          </v-btn>
          <v-btn
            color="primary"
            text small
            @click="
              $emit('save', editKunde);
            "
            :disabled="xLoaded === JSON.stringify(editKunde)"
            :loading="prop_isSaving_editKunde"
          >
            Speichern
          </v-btn>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import $ from 'jquery';
import EditUserParameter from '@/components/Inputs/EditUserParameter.vue';
import Auftraege from '@/components/Funktionen/400/410-Kunden/Auftraege.vue';

export default {
  components: {
    EditUserParameter,
    Auftraege,
  },
  props: {
    prop_kunde: {
      type: Object,
      default() { return {}; },
    },
    prop_isSaving_editKunde: {
      type: Boolean,
      default: false,
    },
    prop_isSaving_delKunde: {
      type: Boolean,
      default: false,
    },
    prop_auftraege: {
      type: Array,
      default() { return []; },
    },
    prop_settings: {
      type: Object,
      default() { return {}; },
    },
    prop_kosten: {
      type: Array,
      default() { return []; },
    },
  },
  data: () => ({
    dialog_editKunde: false,
    dialog_delKunde: false,
    tabs_editKunde_infosAnschriften: null,
    switch_editKunde_auftraegeVertraege: false,
    editKunde_extPan_anschriften: null,
    editKunde_extPan_ansprechpartner: null,
    editKunde: {},
    xLoaded: '',
  }),
  computed: {
  },
  watch: {
    dialog_editKunde(neu) {
      if (neu === false) {
        setTimeout(() => {
          this.$emit('close');
        }, 400);
      }
    },
  },
  methods: {
    delAnschrift(idx) {
      this.editKunde.anschriften.splice(idx, 1);
      this.editKunde_extPan_anschriften = null;
    },
    addAnschrift() {
      this.editKunde.anschriften.push({
        id: 0,
        bezeichnung: '',
        firma: '',
        strasse: '',
        hausnummer: '',
        plz: '',
        ort: '',
        info: '',
        sort: 0,
      });
      setTimeout(() => {
        this.editKunde_extPan_anschriften = this.editKunde.anschriften.length - 1;
      }, 1);
    },
    delAnsprechpartner(idx) {
      this.editKunde.ansprechpartner.splice(idx, 1);
      this.editKunde_extPan_ansprechpartner = null;
    },
    addAnsprechpartner() {
      this.editKunde.ansprechpartner.push({
        id: 0,
        bezeichnung: '',
        anrede: 'Herr',
        vorname: '',
        nachname: '',
        telefon_mobil: '',
        telefon_festnetz: '',
        mail: '',
        info: '',
        sort: 0,
      });
      setTimeout(() => {
        this.editKunde_extPan_ansprechpartner = this.editKunde.ansprechpartner.length - 1;
      }, 1);
    },
  },
  created() {
    this.editKunde = JSON.parse(JSON.stringify(this.prop_kunde));
    this.xLoaded = JSON.stringify(this.prop_kunde);
    this.dialog_editKunde = true;
  },
};
</script>
