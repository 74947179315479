var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.editanw.id >= 0)?_c('v-dialog',{attrs:{"scrollable":"","width":"500"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-card-title',[(_vm.editanw.id === 0)?_c('span',[_vm._v("Anwesenheit hinzufügen")]):_c('span',[_vm._v("Anwesenheit bearbeiten")])]),_c('v-card-text',[_c('v-form',{model:{value:(_vm.formvalid),callback:function ($$v) {_vm.formvalid=$$v},expression:"formvalid"}},[(
            _vm.bedingungen.anwbearbeiten.nutzer
            || _vm.bedingungen.bearbeiten.nutzer
          )?_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('v-checkbox',{attrs:{"label":"Abfrage geschlossen"},model:{value:(_vm.editanw.geschlossen),callback:function ($$v) {_vm.$set(_vm.editanw, "geschlossen", $$v)},expression:"editanw.geschlossen"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-information")])],1)]}}],null,false,2223522019),model:{value:(_vm.dialogInfoOffeneAbfragen),callback:function ($$v) {_vm.dialogInfoOffeneAbfragen=$$v},expression:"dialogInfoOffeneAbfragen"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Offene Abfragen ")]),_c('v-card-text',[_vm._v(" Redakteure können geschlossene Abfragen nicht mehr sehen und bearbeiten. "),_c('br'),_c('br'),_vm._v(" Außerdem wird durch das Scannen eines QR-Codes des jeweiligen Slots die letzte nicht geschlossene Abfrage dieses Slots aktualisiert. ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialogInfoOffeneAbfragen = false}}},[_vm._v(" OK ")])],1)],1)],1)],1)],1):_vm._e(),(
            (
              _vm.bedingungen.anwbearbeiten.nutzer
              || _vm.bedingungen.bearbeiten.nutzer
              || (
                _vm.bedingungen.anwhinzufuegen.nutzer
                && !_vm.editanw.geschlossen
              )
            )
            && !_vm.editanw.geschlossen
          )?_c('DatePicker',{model:{value:(_vm.editanw.datum),callback:function ($$v) {_vm.$set(_vm.editanw, "datum", $$v)},expression:"editanw.datum"}}):_c('div',[_c('b',[_vm._v("Datum: ")]),_c('br'),_vm._v(" "+_vm._s(new Date(_vm.editanw.datum).toLocaleString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' }))+" ")]),(
            (
              _vm.bedingungen.anwbearbeiten.nutzer
              || _vm.bedingungen.bearbeiten.nutzer
              || (
                _vm.bedingungen.anwhinzufuegen.nutzer
                && !_vm.editanw.geschlossen
              )
            )
            && !_vm.editanw.geschlossen
          )?_c('v-text-field',{attrs:{"label":"Titel","hint":"Anzuzeigender Name der Veranstaltung","rules":[
            _vm.$store.state.main.inputValidationRules.required,
            _vm.$store.state.main.inputValidationRules.name ]},model:{value:(_vm.editanw.titel),callback:function ($$v) {_vm.$set(_vm.editanw, "titel", $$v)},expression:"editanw.titel"}}):_c('div',{staticClass:"mt-2"},[_c('b',[_vm._v("Titel: ")]),_c('br'),_vm._v(" "+_vm._s(_vm.editanw.titel)+" ")]),(
            (
              _vm.bedingungen.anwbearbeiten.nutzer
              || _vm.bedingungen.bearbeiten.nutzer
              || (
                _vm.bedingungen.anwhinzufuegen.nutzer
                && !_vm.editanw.geschlossen
              )
            )
            && !_vm.editanw.geschlossen
          )?_c('v-textarea',{staticClass:"mt-2",attrs:{"outlined":"","auto-grow":"","label":"Beschreibung (optional)","hint":"Informationen zur Veranstaltung"},model:{value:(_vm.editanw.beschreibung),callback:function ($$v) {_vm.$set(_vm.editanw, "beschreibung", $$v)},expression:"editanw.beschreibung"}}):(_vm.editanw.beschreibung !== '')?_c('div',{staticClass:"mt-2"},[_c('b',[_vm._v("Beschreibung: ")]),_c('br'),_c('div',{domProps:{"innerHTML":_vm._s(_vm.editanw.beschreibung_nl2br)}})]):_vm._e(),_c('div',{staticClass:"mt-2"},[_c('b',[_vm._v("Anwesenheiten:")]),_c('br'),_vm._l((_vm.editanw.prueflinge),function(p){return _c('span',{key:p.id},[(
                p.status != '0'
                || !_vm.editanw.geschlossen
              )?_c('v-btn',{staticClass:"ma-1",style:(_vm.moeglicheStatus.filter(function (o) { return o.nummer === p.status; })[0].style),attrs:{"small":"","color":_vm.moeglicheStatus.filter(function (o) { return o.nummer === p.status; })[0].farbe},on:{"click":function($event){return _vm.switchPrueflingStatus(p);}}},[_vm._v(" "+_vm._s(p.name)+" ")]):_vm._e()],1)})],2),(
            (
              _vm.bedingungen.anwbearbeiten.nutzer
              || _vm.bedingungen.bearbeiten.nutzer
              || (
                _vm.bedingungen.anwhinzufuegen.nutzer
                && !_vm.editanw.geschlossen
              )
            )
            && !_vm.editanw.geschlossen
          )?_c('v-textarea',{staticClass:"mt-6",attrs:{"outlined":"","auto-grow":"","label":"Gäste (optional)","hint":"Sonstige teilnehmende Personen"},model:{value:(_vm.editanw.gaeste),callback:function ($$v) {_vm.$set(_vm.editanw, "gaeste", $$v)},expression:"editanw.gaeste"}}):(_vm.editanw.gaeste !== '')?_c('div',{staticClass:"mt-2"},[_c('b',[_vm._v("Gäste: ")]),_c('br'),_c('div',{domProps:{"innerHTML":_vm._s(_vm.editanw.gaeste_nl2br)}})]):_vm._e()],1),(
          (
            _vm.bedingungen.anwbearbeiten.nutzer
            || _vm.bedingungen.bearbeiten.nutzer
          ) && !_vm.editanw.geschlossen
        )?_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.downloadQRCode();}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-qrcode")]),_vm._v(" Download QR-Code ")],1):_vm._e()],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"small":"","text":""},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v(" schließen ")]),_c('v-spacer'),(
          (
            _vm.bedingungen.anwbearbeiten.nutzer
            || _vm.bedingungen.bearbeiten.nutzer
          )
          && _vm.editanw.id !== 0
        )?_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","text":"","color":"error"}},'v-btn',attrs,false),on),[_vm._v(" löschen ")])]}}],null,false,463854359),model:{value:(_vm.dialog_delAnw[_vm.editanw.id]),callback:function ($$v) {_vm.$set(_vm.dialog_delAnw, _vm.editanw.id, $$v)},expression:"dialog_delAnw[editanw.id]"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Sicher? ")]),_c('v-card-text'),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"small":"","text":""},on:{"click":function($event){_vm.dialog_delAnw[_vm.editanw.id] = false}}},[_vm._v(" Nichts tun ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","small":"","text":"","loading":_vm.saving.delanw[_vm.editanw.id]},on:{"click":function($event){return _vm.delAnw(_vm.editanw);}}},[_vm._v(" Anwesenheiten löschen ")])],1)],1)],1):_vm._e(),(
          _vm.bedingungen.anwbearbeiten.nutzer
          || _vm.bedingungen.bearbeiten.nutzer
          || (
            _vm.bedingungen.anwhinzufuegen.nutzer
            && (
              _vm.editanw.id === 0
              || !_vm.editanw.geschlossen
            )
          )
        )?_c('v-btn',{attrs:{"small":"","text":"","color":"primary","disabled":!_vm.formvalid,"loading":_vm.$store.state.f150.saving.editanw},on:{"click":function($event){return _vm.$store.dispatch('f150/tryGetOrSaveSettings', { dataToSave: {
          type: 'editanw',
          data: { editanw: _vm.editanw },
        }, router: _vm.$router });}}},[_vm._v(" speichern ")]):_c('v-btn',{attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v(" OK ")])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }