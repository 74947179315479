<template>
  <div
    class="view view_V020Einstellungen"
    v-if="doRender"
  >
    <viewTabsTop v-if="tabs.length > 1" :tabs="tabs" />
    <!-- CONTENT -->
    <v-container
      v-if="offline"
    >
      <v-alert
        dense transition="scale-transition"
        type="error"
        class="ma-2"
        style="font-size:80%;"
      >
        <b>KEINE NETZWERKVERBINDUNG</b>
        <br>
        Die angezeigten Daten können veraltet sein.
        Bevor Änderungen vorgenommen werden, vergewissern Sie sich, dass die Daten aktuell sind.
      </v-alert>
    </v-container>
    <v-progress-linear
      indeterminate
      v-if="loading"
      absolute
    ></v-progress-linear>
    <transition
      v-if="
        currentTab.code === ''
        && tabs.filter((o) => o.erlaubt === `x|${sitecode}`).length > 0
      "
      name="custom-classes-transition"
      :enter-active-class="transitionIn"
      :leave-active-class="transitionOut"
    >
      <F020Einstellungen />
    </transition>
    <!-- <transition
      v-else-if="
        currentTab.code === '100'
        && tabs.filter((o) => o.erlaubt === `x|${sitecode}`).length > 0
      "
      name="custom-classes-transition"
      :enter-active-class="transitionIn"
      :leave-active-class="transitionOut"
    >
      <v-container fluid class="ma-0 pa-0 d-flex justify-center">
        <v-container class="ma-0 pa-0">
          Tab 2
        </v-container>
      </v-container>
    </transition>
    <transition
      v-else-if="
        currentTab.code === '200'
        && tabs.filter((o) => o.erlaubt === `x|${sitecode}`).length > 0
      "
      name="custom-classes-transition"
      :enter-active-class="transitionIn"
      :leave-active-class="transitionOut"
    >
      <v-container fluid class="ma-0 pa-0 d-flex justify-center">
        <v-container class="ma-0 pa-0">
          Tab 3
        </v-container>
      </v-container>
    </transition>
    <transition
      v-else-if="
        currentTab.code === 'set'
        && tabs.filter((o) => o.erlaubt === `x|${sitecode}`).length > 0
      "
      name="custom-classes-transition"
      :enter-active-class="transitionIn"
      :leave-active-class="transitionOut"
    >
      <F110Einstellungen :currentTab="currentTab" />
    </transition> -->
    <p v-else>
      Zugriff verweigert
    </p>
    <!-- CONTENT -->
    <viewTabsBottom v-if="tabs.length > 1" :tabs="tabs" />
  </div>
</template>

<script>
import 'animate.css';
import ViewTabsTop from '@/components/ViewTabsTop.vue';
import ViewTabsBottom from '@/components/ViewTabsBottom.vue';
import F020Einstellungen from '@/components/Funktionen/000/020-Einstellungen/F020Einstellungen.vue';

export default ({
  name: 'V020Einstellungen',
  components: {
    ViewTabsTop,
    ViewTabsBottom,
    F020Einstellungen,
  },
  data: () => ({
    sitecode: '020',
    loading: false,
    offline: false,
    save: {},
    doRender: false,
    tabs: [],
    mytabs: [
      {
        icon: 'mdi-calendar',
        title: 'Kalender',
        route: '/020',
        erlaubt: 'x|020',
      },
    ],
    currentTab: {
      code: '',
      query: {},
    },
  }),
  computed: {
    transitionIn() {
      let result = '';
      if (this.$vuetify.breakpoint.mdAndDown) {
        result = 'animate__animated animate__fadeInLeft';
      }
      return result;
    },
    transitionOut() {
      let result = '';
      if (this.$vuetify.breakpoint.mdAndDown) {
        result = 'animate__animated animate__fadeOutLeft';
      }
      return result;
    },
  },
  methods: {
    setCurrentTab(route) {
      this.currentTab.code = route.path.replace(this.$router.history.current.path.substring(0, 4), '').replace('-', '');
      this.currentTab.query = route.query;
      this.doRender = true;
    },
  },
  created() {
    if (this.$store.state.main.save.mainmenu_links
      .some((e) => e.route === this.$router.history.current.path.substring(0, 4))) {
      // Wenn Zugriff auf diese View erlaubt
      // erlaube das Rendern
      this.setCurrentTab(this.$router.history.current);
    } else {
      // Wenn Zugriff auf diese View NICHT erlaubt
      // Leite zur ersten erlaubten Seite um
      this.$router.push({ path: this.$store.state.main.save.mainmenu_links[0].route });
      window.location.reload();
    }
    // Tabs erstellen:
    this.mytabs.forEach((tab) => {
      if (
        this.$store.state.main.save.user.rechte.find((element) => {
          let bool = false;
          if (element.includes(tab.erlaubt)) {
            bool = true;
          }
          return bool;
        })
      ) {
        this.tabs.push(tab);
      }
    });
    // Wenn der aktuelle Tabs nicht erlaubt ist, zum ersten erlaubten Tab navigieren:
    if (
      this.tabs.filter((o) => o.route === this.$router.history.current.path).length <= 0
      && this.tabs.length > 0
    ) {
      this.$router.push({ path: this.tabs[0].route });
    }
  },
  beforeRouteUpdate(to, from, next) {
    // Wenn ein neuer Tab innerhalb dieser Seite aufgerufen wird
    if (
      to.path.substring(1, 4) === `x|${this.sitecode}`
    ) {
      // Wenn ein Tab der Seite 210 aufgerufen wird.
    }
    this.setCurrentTab(to);
    next();
  },
});
</script>

<style>
</style>
