<template>
    <div>
      <div style="height:60px;" class="d-lg-none"></div>
        <v-bottom-navigation
        fixed grow
        class="d-lg-none"
        style="height:60px;"
        >
        <v-slide-group
        multiple show-arrows
        next-icon="mdi-menu-right"
        prev-icon="mdi-menu-left"
        :class="{'mb-3': iOSSafari}"
        >
            <v-slide-item
            v-for="tab in tabs"
            :key="tab.title"
            >
            <v-btn
                color="primary"
                text
                :to="tab.route"
            >
                <span>{{ tab.title }}</span>
                <v-icon>{{ tab.icon }}</v-icon>
            </v-btn>
            </v-slide-item>
        </v-slide-group>
        </v-bottom-navigation>
    </div>
</template>

<script>

export default {
  props: {
    tabs: {
      type: Array,
      default() { return []; },
    },
  },
  data: () => ({
    iOSSafari: null,
  }),
  created() {
    const ua = window.navigator.userAgent;
    const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    const webkit = !!ua.match(/WebKit/i);
    const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
    this.iOSSafari = iOSSafari;
  },
};
</script>
