<template>
  <v-container fluid class="ma-0 pa-0 d-flex justify-center">
    <v-container class="ma-0 pa-2">
      <v-dialog
        v-model="dialog_verwaltungseinstellungen"
        width="500"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
            class="ma-1"
          >
            <v-icon>mdi-cogs</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-card-title>
            <b>Einstellungen</b>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col>
                <v-select
                  multiple
                  label="Klassen anzuzeigen"
                  :items="prop_klassen"
                  v-model="downloads_monatsuebersichtElemente.klassen"
                  item-text="name"
                  item-value="id"
                >
                  <template v-slot:selection="data">
                    <span
                      v-if="data.index === 0"
                    >
                      <span
                        class="grey--text text-caption"
                        v-for="(k, idx) in filterKlassenSort"
                        :key="idx"
                      >
                        <v-chip x-small
                          close
                          :input-value="k"
                          @click:close="
                            removeAngezeigteKlasse(prop_klassen.filter((o) => o.id === k)[0].id)
                          "
                        >{{ prop_klassen.filter((o) => o.id === k)[0].name }}</v-chip>
                      </span>
                      <!-- <span
                        class="grey--text text-caption"
                      >
                        <v-chip x-small
                          close
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          @click="data.select"
                          @click:close="removeAngezeigteKlasse(data.item.id)"
                        >{{ data.item.name }}</v-chip>
                      </span> -->
                    </span>
                  </template>
                </v-select>
                <div class="mb-4 d-flex justify-space-around"
                  style="transform:translateY(-15px);"
                >
                  <v-btn x-small
                    @click="filterKlassenAlle();"
                    :disabled="
                      downloads_monatsuebersichtElemente.klassen.length
                      === prop_klassen.length
                    "
                  >Alle auswählen</v-btn>
                  <v-btn x-small
                    @click="filterKlassenKeine();"
                    :disabled="downloads_monatsuebersichtElemente.klassen.length <= 0"
                  >Alle abwählen</v-btn>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <v-checkbox
                  v-model="anmeldezeitJetzt"
                  label="Anmeldezeit: Jetzt"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row v-if="!anmeldezeitJetzt">
              <v-col class="py-0">
                <TimePicker
                  v-model="anmeldezeit"
                  label="Anmeldezeit"
                  icon="mdi-clock-time-four"
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="dialog_verwaltungseinstellungen = false"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        scrollable
        v-model="dialog_downloads_createPDF"
        width="500"
      >
        <!-- <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            v-bind="attrs"
            v-on="on"
            class="ma-1"
          >
            <v-icon left>mdi-file-pdf-box</v-icon>
            Auswertung erstellen
          </v-btn>
        </template> -->

        <v-card>
          <v-card-title>
            <!-- <v-icon left>mdi-file-pdf-box</v-icon> -->
            Auswertung erstellen
            <v-tabs
              fixed-tabs
              v-model="downloads_tabsTagesMonatsuebersicht"
              class="mb-4"
            >
              <v-tab>Tag</v-tab>
              <v-tab>Monat</v-tab>
            </v-tabs>
          </v-card-title>

          <v-card-text>
            <template
              v-if="downloads_tabsTagesMonatsuebersicht === 0"
            >
              <v-row
                v-if="
                  prop_anwesenheiten.length > 0
                "
                align="center"
              >
                <v-col class="pe-12">
                  <DatePicker
                    v-if="dialog_downloads_createPDF"
                    label="Datum"
                    icon=""
                    v-model="downloads_tagesanwesenheitTag"
                  />
                  <v-text-field
                    label="Suffix (optional)"
                    prepend-icon=""
                    hint="
                      Wird an den Dateinamen angehängt. Bsp.: ''Klassen 1-2''
                    "
                    v-model="downloads_monatsuebersichtElemente.suffix"
                    :rules="[
                      $store.state.main.inputValidationRules.nameLess,
                    ]"
                    maxlength="20"
                  ></v-text-field>
                  <v-checkbox
                    v-model="downloads_monatsuebersichtElemente.archivieren"
                    color="red"
                    label="PDF archivieren"
                  ></v-checkbox>
                  <p
                    v-if="downloads_monatsuebersichtElemente.archivieren"
                    class="text-caption text--secondary mb-0"
                    style="text-align:justify;"
                  >
                    Das generierte PDF wird auf dem Server gespeichert und liegt dort
                    zum Abruf bereit. Ist dort bereits eine Anwesenheit für den
                    gewählten Zeitraum vorhanden, wird diese überschrieben.
                  </p>
                  <p
                    v-if="!downloads_monatsuebersichtElemente.archivieren"
                    class="text-caption text--secondary mb-0"
                    style="text-align:justify;"
                  >
                    Das generierte PDF wird nicht auf dem Server gespeichert und wird nur dieses
                    eine Mal heruntergeladen.
                  </p>
                  <v-checkbox
                    v-model="downloads_monatsuebersichtElemente.loeschen"
                    color="red"
                    label="Anwesenheitsdaten löschen"
                  ></v-checkbox>
                  <p
                    v-if="downloads_monatsuebersichtElemente.loeschen"
                    class="text-caption text--secondary mb-0"
                    style="text-align:justify;"
                  >
                    Die Daten im gewählten Zeitraum werden unwiderruflich gelöscht. Dies
                    gibt Leistung und Speicher für spätere Auswertungen frei.
                  </p>
                  <p
                    v-if="
                      downloads_monatsuebersichtElemente.loeschen
                      && !downloads_monatsuebersichtElemente.archivieren
                    "
                    class="text-caption text--secondary mb-0 error--text"
                    style="text-align:justify;"
                  >
                    <b class="error--text">
                      ACHTUNG:
                    </b>
                    Sämtliche Anwesenheitsdaten des gewähltes Zeitraums werden gelöscht
                    und die fertige PDF-Auswertung
                    wird nicht archiviert. Bewahren Sie die heruntergeladene
                    Datei daher gut auf.
                  </p>
                  <p
                    v-if="!downloads_monatsuebersichtElemente.loeschen"
                    class="text-caption text--secondary mb-0"
                    style="text-align:justify;"
                  >
                    Die Anwesenheitsdaten des gewählten Zeitraums werden nicht gelöscht.<br>
                    <b class="error--text">
                      ACHTUNG:
                    </b>
                    Es wird empfohlen nicht mehr benötigte Daten zu löschen
                    um Leistung und Speicher für spätere Auswertung freizugeben.
                  </p>
                </v-col>
              </v-row>
              <v-row
                v-if="
                  prop_anwesenheiten.length <= 0
                "
                align="center"
              >
                <v-col align="center">
                  Keine Anwesenheiten verfügbar
                </v-col>
              </v-row>
            </template>
            <template
              v-if="downloads_tabsTagesMonatsuebersicht === 1"
            >
              <v-row
                v-if="downloads_moeglicheMonate.length > 0"
                align="center"
              >
                <v-col>
                  <v-select
                    :items="downloads_moeglicheMonate"
                    v-model="downloads_monatsuebersichtMonat"
                    item-text="name"
                    item-value="value"
                    label="Zeitraum"
                  />
                  <v-select
                    multiple
                    label="Klassen auszuwerten"
                    :items="prop_klassen"
                    v-model="downloads_monatsuebersichtElemente.klassen"
                    item-text="name"
                    item-value="id"
                  >
                    <template v-slot:selection="data">
                      <span
                        v-if="data.index === 0"
                      >
                        <span
                          class="grey--text text-caption"
                          v-for="(k, idx) in filterKlassenSort"
                          :key="idx"
                        >
                          <v-chip x-small
                            close
                            :input-value="k"
                            @click:close="
                              removeAngezeigteKlasse(prop_klassen.filter((o) => o.id === k)[0].id)
                            "
                          >{{ prop_klassen.filter((o) => o.id === k)[0].name }}</v-chip>
                        </span>
                        <!-- <span
                          class="grey--text text-caption"
                        >
                          <v-chip x-small
                            close
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            @click="data.select"
                            @click:close="removeAngezeigteKlasse(data.item.id)"
                          >{{ data.item.name }}</v-chip>
                        </span> -->
                      </span>
                    </template>
                  </v-select>
                  <!-- <v-select
                    multiple
                    label="Klassen auszuwerten"
                    :items="prop_klassen"
                    v-model="downloads_monatsuebersichtElemente.klassen"
                    item-text="name"
                    item-value="id"
                  >
                    <template v-slot:selection="data">
                      <span
                        class="grey--text text-caption"
                      >
                        <v-chip x-small
                          close
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          @click="data.select"
                          @click:close="removeAngezeigteKlasse(data.item.id)"
                        >{{ data.item.name }}</v-chip>
                      </span>
                    </template>
                  </v-select> -->
                  <v-text-field
                    label="Suffix (optional)"
                    prepend-icon=""
                    hint="
                      Wird an den Dateinamen angehängt. Bsp.: ''Klassen 1-2''
                    "
                    v-model="downloads_monatsuebersichtElemente.suffix"
                    :rules="[
                      $store.state.main.inputValidationRules.nameLess,
                    ]"
                    maxlength="20"
                  ></v-text-field>
                  <v-checkbox
                    v-model="downloads_monatsuebersichtElemente.archivieren"
                    color="red"
                    label="PDF archivieren"
                  ></v-checkbox>
                  <p
                    v-if="downloads_monatsuebersichtElemente.archivieren"
                    class="text-caption text--secondary mb-0"
                    style="text-align:justify;"
                  >
                    Das generierte PDF wird auf dem Server gespeichert und liegt dort
                    zum Abruf bereit. Ist dort bereits eine Anwesenheit für den
                    gewählten Zeitraum vorhanden, wird diese überschrieben.
                  </p>
                  <p
                    v-if="!downloads_monatsuebersichtElemente.archivieren"
                    class="text-caption text--secondary mb-0"
                    style="text-align:justify;"
                  >
                    Das generierte PDF wird nicht auf dem Server gespeichert und wird nur dieses
                    eine Mal heruntergeladen.
                  </p>
                  <v-checkbox
                    v-model="downloads_monatsuebersichtElemente.loeschen"
                    color="red"
                    label="Anwesenheitsdaten löschen"
                  ></v-checkbox>
                  <p
                    v-if="downloads_monatsuebersichtElemente.loeschen"
                    class="text-caption text--secondary mb-0"
                    style="text-align:justify;"
                  >
                    Die Daten im gewählten Zeitraum werden unwiderruflich gelöscht. Dies
                    gibt Leistung und Speicher für spätere Auswertungen frei.
                  </p>
                  <p
                    v-if="
                      downloads_monatsuebersichtElemente.loeschen
                      && !downloads_monatsuebersichtElemente.archivieren
                    "
                    class="text-caption text--secondary mb-0 error--text"
                    style="text-align:justify;"
                  >
                    <b class="error--text">
                      ACHTUNG:
                    </b>
                    Sämtliche Anwesenheitsdaten des gewähltes Zeitraums werden gelöscht
                    und die fertige PDF-Auswertung
                    wird nicht archiviert. Bewahren Sie die heruntergeladene
                    Datei daher gut auf.
                  </p>
                  <p
                    v-if="!downloads_monatsuebersichtElemente.loeschen"
                    class="text-caption text--secondary mb-0"
                    style="text-align:justify;"
                  >
                    Die Anwesenheitsdaten des gewählten Zeitraums werden nicht gelöscht.<br>
                    <b class="error--text">
                      ACHTUNG:
                    </b>
                    Es wird empfohlen nicht mehr benötigte Daten zu löschen
                    um Leistung und Speicher für spätere Auswertung freizugeben.
                  </p>
                  <v-checkbox
                    v-model="downloads_monatsuebersichtElemente.quantitativeAnwesenheit"
                    label="Diagramm: quantitative Anwesenheit"
                  ></v-checkbox>
                  <p
                    class="text-caption text--secondary mb-0"
                    style="text-align:justify;"
                  >
                    Balkendiagramm zur Veranschaulichung wie viele Kinder an den
                    jeweiligen Tagen des Monats anwesend waren.
                  </p>
                  <v-checkbox
                    v-model="downloads_monatsuebersichtElemente.anwesenheitenAllerKinder"
                    label="Tabelle: Anwesenheiten aller Kinder"
                  ></v-checkbox>
                  <p
                    class="text-caption text--secondary mb-0"
                    style="text-align:justify;"
                  >
                    Übersicht aller Kinder die diesen Monat mindestens
                    einmal anwesend waren und an welchen Tagen sie anwesend waren.
                  </p>
                  <v-checkbox
                    v-model="downloads_monatsuebersichtElemente.entlasszeitenuebersicht"
                    label="Diagramm: Entlasszeitenübersicht"
                  ></v-checkbox>
                  <p
                    class="text-caption text--secondary mb-0"
                    style="text-align:justify;"
                  >
                    Balkendiagramm zur Veranschaulichung zu welchen Zeiten die
                    Kinder entlassen wurden.
                  </p>
                  <v-checkbox
                    v-model="downloads_monatsuebersichtElemente.entlasszeitenAllerKinder"
                    label="Tabelle: Entlasszeiten aller Kinder"
                  ></v-checkbox>
                  <p
                    class="text-caption text--secondary mb-0"
                    style="text-align:justify;"
                  >
                    Übersicht aller Kinder die diesen Monat mindestens
                    einmal anwesend waren und zu welchen Zeiten sie entlassen wurden.
                  </p>
                  <v-checkbox
                    v-model="downloads_monatsuebersichtElemente.UebersichtenJedenTages"
                    label="Uebersichten jeden Tages"
                  ></v-checkbox>
                  <p
                    class="text-caption text--secondary mb-0"
                    style="text-align:justify;"
                  >
                    Tabellen für jeden Tag des Monats, zur Übersicht welches Kind von wann bis wann
                    anwesend war.
                  </p>
                  <BarChartBase64
                    :prop_createChart="createChartsMonatsuebersicht"
                    :charts="downloads_chartData"
                    @getChart="getChart($event);"
                  />
                </v-col>
              </v-row>
              <v-row
                v-if="downloads_moeglicheMonate.length <= 0"
                align="center"
              >
                <v-col align="center">
                  Keine Anwesenheiten verfügbar
                </v-col>
              </v-row>
            </template>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              text
              @click="dialog_downloads_createPDF = false"
              small
            >
              Schließen
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              v-if="
                downloads_tabsTagesMonatsuebersicht === 0
                && prop_anwesenheiten.length > 0
              "
              x-small
              :disabled="
                prop_anwesenheiten
                  .filter((o) => o.tag === downloads_tagesanwesenheitTag).length <= 0
              "
              @click="
                tryDownloadStatistik('tag');
                downloads_loading = true;
              "
              :loading="downloads_loading"
            >
              <v-icon small left>mdi-download</v-icon>
              Auswertung erstellen
            </v-btn>
            <v-btn
              v-if="
                downloads_tabsTagesMonatsuebersicht === 1
                && downloads_moeglicheMonate.length > 0
              "
              small
              :disabled="
                !downloads_monatsuebersichtMonat
                || downloads_monatsuebersichtElemente.klassen.length <= 0
                || !(
                  downloads_monatsuebersichtElemente.quantitativeAnwesenheit
                  || downloads_monatsuebersichtElemente.entlasszeitenuebersicht
                  || downloads_monatsuebersichtElemente.anwesenheitenAllerKinder
                  || downloads_monatsuebersichtElemente.entlasszeitenAllerKinder
                  || downloads_monatsuebersichtElemente.UebersichtenJedenTages
                )              "
              @click="
                $store.commit('main/switchIsSaving', true);
                createChartsMonatsuebersicht = true;
                downloads_loading = true;
              "
              :loading="downloads_loading"
            >
              <v-icon>mdi-download</v-icon>
              Auswertung erstellen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        scrollable
        v-model="dialog_downloads"
        width="500"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            v-bind="attrs"
            v-on="on"
            class="ma-1"
          >
            <v-icon left>mdi-download</v-icon>
            Downloads
          </v-btn>
        </template>

        <v-card>
          <v-card-title>
            <v-icon left>mdi-download</v-icon>
            Downloads
          </v-card-title>

          <v-card-text>
            <v-divider class="mb-4"></v-divider>
            <p v-if="prop_anwesenheitenDokumente.length <= 0">
              Keine Dokumente im Archiv vorhanden.<br>
              Um Dokumente zu erzeugen, wählen Sie "Auswertung erstellen".
              Damit erstellte Dokumente im Archiv bleiben, aktivieren
              Sie dort den Haken "PDF archivieren".
            </p>
            <v-row
              v-for="(dok, idx) in prop_anwesenheitenDokumente"
              :key="idx"
              align="center"
            >
              <v-col>
                <v-icon v-if="dok.substring(dok.length - 4) === '.pdf'">mdi-file-pdf-box</v-icon>
                <v-icon v-if="dok.substring(dok.length - 4) === '.zip'">mdi-folder-zip</v-icon>
                {{ anzeigeDownloads(dok) }}
              </v-col>
              <v-col cols="4" class="d-flex justify-space-around">
                <v-btn
                  x-small
                  @click="tryDownload(
                    `f200-200/${dok}`,
                    `${anzeigeDownloads(dok)}`
                  );"
                  :loading="loadingWhileSaving"
                  color="primary"
                >
                  <v-icon small>mdi-download</v-icon>
                </v-btn>
                <v-dialog
                  v-model="dialog_delDokument[dok]"
                  width="500"
                >
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        v-bind="attrs"
                        v-on="on"
                        :loading="loadingWhileSaving"
                        color="error"
                      >
                        <v-icon small>mdi-delete</v-icon>
                      </v-btn>
                  </template>

                  <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                      Sicher?
                    </v-card-title>

                    <v-card-text class="pt-4">
                      Das Dokument wird unwiderruflich gelöscht.
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-btn
                        color="primary"
                        text
                        @click="dialog_delDokument[dok] = false;"
                      >
                        Nichts tun
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="error"
                        text
                        :loading="loadingWhileSaving"
                        @click="
                          trySaveAnwesenheit(dok);
                          dialog_delDokument[dok] = false;
                        "
                      >
                        löschen
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              text
              @click="dialog_downloads = false"
              small
            >
              Schließen
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="dialog_downloads_createPDF = true"
              small
            >
              <v-icon left>mdi-plus-box</v-icon>
              Auswertung erstellen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-row align="center">
        <v-col cols="3" align="center">
          <v-btn
            small
            :disabled="!anwesenheitGeaendert"
            @click="trySaveAnwesenheit('');"
            :loading="loadingWhileSaving"
          >
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="9" class="pe-12">
          <DatePicker
            v-if="anzeigeTag"
            label="Datum"
            icon=""
            v-model="anzeigeTag"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="px-7">
      <v-text-field
        clearable dense
        label="Suchen..."
        append-icon="mdi-magnify"
        v-model="search"
        :rules="[
          $store.state.main.inputValidationRules.name,
        ]"
        class="ma-0"
      ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        v-if="anzeigeTag"
        :headers="headers"
        :items="
          anzeigeListeAnwesenheiten
            .filter((o) => downloads_monatsuebersichtElemente.klassen.includes(o.id_klasse))
        "
        :search="search"
        :loading="loading"
        :items-per-page="20"
        :header-props="headerProps"
        :footer-props="footerProps"
        no-data-text="Keine Anwesenheiten gefunden"
        no-results-text="Keine Anwesenheiten gefunden"
        loading-text="Lade Daten... Bitte warten"
        mobile-breakpoint="800"
      >
        <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
          {{ pageStart }} - {{ pageStop }} von {{ itemsLength }}
        </template>
        <template v-slot:item.kind="{ item }">
          {{ item.nachname }}, {{ item.vorname }} ({{ item.name_klasse }})
        </template>
        <template v-slot:item.anwesend="{ item }">
          <v-checkbox
            v-model="item.anwesend"
            label=""
            @change="updateAnmeldezeiten(item);"
          ></v-checkbox>
        </template>
        <template v-slot:item.von="{ item }">
          <TimePicker
            label=""
            icon=""
            v-model="
              item.von
            "
            :disabled="!item.anwesend"
            v-if="item.anwesend"
          />
        </template>
        <template v-slot:item.bis="{ item }">
          <TimePicker
            label=""
            icon=""
            v-model="
              item.bis
            "
            :disabled="!item.anwesend"
            v-if="item.anwesend"
          />
        </template>
        <template v-slot:item.entlassgrund="{ item }">
          <v-text-field
            label=""
            prepend-icon=""
            v-model="item.entlassgrund"
            :rules="[
              $store.state.main.inputValidationRules.nameMore,
            ]"
            :disabled="!item.anwesend || !item.bis"
            v-if="item.anwesend"
          ></v-text-field>
        </template>
      </v-data-table>
    </v-container>
  </v-container>
</template>

<script>
import $ from 'jquery';
import DatePicker from '@/components/Inputs/DatePicker.vue';
import TimePicker from '@/components/Inputs/TimePicker.vue';
import BarChartBase64 from '@/components/Charts/BarChartBase64.vue';

export default {
  components: {
    DatePicker,
    TimePicker,
    BarChartBase64,
  },
  props: {
    prop_anwesenheiten: {
      type: Array,
      default() { return []; },
    },
    prop_anwesenheitenMonate: {
      type: Object,
      default() { return {}; },
    },
    prop_anwesenheitenDokumente: {
      type: Array,
      default() { return []; },
    },
    prop_kinder: {
      type: Array,
      default() { return []; },
    },
    prop_klassen: {
      type: Array,
      default() { return []; },
    },
  },
  data: () => ({
    wochentag: '',
    dialog_verwaltungseinstellungen: false,
    dialog_downloads_createPDF: false,
    dialog_downloads: false,
    dialog_delDokument: {},
    anwesenheitGeaendert: false,
    anzeigeTag: null,
    anzeigeTagGerman: null,
    monate: {
      m01: 'Januar',
      m02: 'Februar',
      m03: 'März',
      m04: 'April',
      m05: 'Mai',
      m06: 'Juni',
      m07: 'Juli',
      m08: 'August',
      m09: 'September',
      m10: 'Oktober',
      m11: 'November',
      m12: 'Dezember',
    },
    createChartsMonatsuebersicht: false,
    downloads_loading: false,
    downloads_tabsTagesMonatsuebersicht: 1,
    downloads_tagesanwesenheitTag: null,
    downloads_moeglicheMonate: [],
    downloads_monatsuebersichtMonat: '',
    downloads_monatsuebersichtElemente: {
      klassen: [],
      quantitativeAnwesenheit: true,
      entlasszeitenuebersicht: true,
      anwesenheitenAllerKinder: true,
      entlasszeitenAllerKinder: true,
      UebersichtenJedenTages: true,
      archivieren: true,
      loeschen: true,
      suffix: '',
    },
    downloads_chartData: [],
    downloads_charts: {
      quantitativeAnwesenheit: '',
      entlasszeitenuebersicht: '',
    },
    anzeigeListeAnwesenheiten: [],
    stringsaveAnzeigeListeAnwesenheiten: '',
    search: '',
    loading: false,
    loadingWhileSaving: false,
    headers: [
      { text: 'Nachname', value: 'nachname' },
      { text: 'Vorname', value: 'vorname' },
      { text: 'Klasse', value: 'name_klasse' },
      { text: 'Anwesend', value: 'anwesend' },
      { text: 'Von', value: 'von' },
      { text: 'Bis', value: 'bis' },
      { text: 'Entlassgrund', value: 'entlassgrund' },
    ],
    headerProps: {
      sortByText: 'Sortiere nach',
    },
    footerProps: {
      itemsPerPageText: 'Elemente pro Seite:',
      itemsPerPageOptions: [5, 10, 20, 50, -1],
      itemsPerPageAllText: 'Alle',
    },
    wochentage: [
      { id: '1', name: 'Montags', nameUnv: 'Montags' },
      { id: '2', name: 'Dienstags', nameUnv: 'Dienstags' },
      { id: '3', name: 'Mittwochs', nameUnv: 'Mittwochs' },
      { id: '4', name: 'Donnerstags', nameUnv: 'Donnerstags' },
      { id: '5', name: 'Freitags', nameUnv: 'Freitags' },
      { id: '6', name: 'Samstags', nameUnv: 'Samstags' },
      { id: '7', name: 'Sonntags', nameUnv: 'Sonntags' },
    ],
    anmeldezeitJetzt: true,
    anmeldezeit: '08:00',
    nurAngemeldeteZeigen: false,
  }),
  computed: {
    filterKlassenSort() {
      const help = [];
      const alleKlassen = JSON.parse(JSON.stringify(this.prop_klassen));
      alleKlassen.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (b.name > a.name) {
          return -1;
        }
        return 0;
      });
      alleKlassen.forEach((k) => {
        if (this.downloads_monatsuebersichtElemente.klassen.includes(k.id)) {
          help.push(k.id);
        }
      });
      return help;
    },
  },
  watch: {
    downloads_monatsuebersichtElemente: {
      handler(neu) {
        this.changeAnzeige(this.anzeigeTag);
        localStorage.setItem('f200_filterClass', JSON.stringify(neu.klassen));
      },
      deep: true,
    },
    dialog_downloads_createPDF() {
      this.changeAnzeige(this.anzeigeTag);
    },
    anzeigeTag(neu) {
      this.changeAnzeige(neu);
    },
    downloads_monatsuebersichtMonat() {
      this.createChartData();
    },
    anzeigeListeAnwesenheiten: {
      handler(neu) {
        if (!(this.stringsaveAnzeigeListeAnwesenheiten === JSON.stringify(neu))) {
          this.anwesenheitGeaendert = true;
        } else {
          this.anwesenheitGeaendert = false;
        }
      },
      deep: true,
    },
    anmeldezeitJetzt(neu) {
      localStorage.setItem('f200_timeAnm', JSON.stringify([neu, this.anmeldezeit, this.nurAngemeldeteZeigen]));
    },
    anmeldezeit(neu) {
      localStorage.setItem('f200_timeAnm', JSON.stringify([this.anmeldezeitJetzt, neu, this.nurAngemeldeteZeigen]));
    },
  },
  methods: {
    anzeigeDownloads(dok) {
      let result = dok.split('/');
      result = result.pop();
      return result;
    },
    updateAnmeldezeiten(item) {
      if (item.anwesend) {
        if (this.anmeldezeitJetzt) {
          const today = new Date();
          const H = String(today.getHours().toString().padStart(2, '0'));
          const i = String(today.getMinutes().toString().padStart(2, '0'));
          item.von = `${H}:${i}`;
        } else {
          item.von = this.anmeldezeit;
        }
      }
    },
    filterKlassenAlle() {
      this.downloads_monatsuebersichtElemente.klassen = [];
      this.prop_klassen.forEach((k) => {
        this.downloads_monatsuebersichtElemente.klassen.push(k.id);
      });
    },
    filterKlassenKeine() {
      this.downloads_monatsuebersichtElemente.klassen = [];
    },
    removeAngezeigteKlasse(id) {
      this.downloads_monatsuebersichtElemente.klassen
        .splice(this.downloads_monatsuebersichtElemente.klassen
          .indexOf(this.downloads_monatsuebersichtElemente.klassen.filter((o) => o === id)[0]), 1);
    },
    changeAnzeige(neuTag) {
      const tag = neuTag.split('-');
      this.anzeigeTagGerman = `${tag[2]}.${tag[1]}.${tag[0]}`;
      this.downloads_tagesanwesenheitTag = neuTag;
      this.anzeigeListeAnwesenheiten = [];
      this.anwesenheitGeaendert = false;
      // Alle Anwesenheiten des gewählten Tages in der Liste anzeigen.
      if (this.prop_anwesenheiten.length > 0) {
        this.prop_anwesenheiten.filter((o) => o.tag === neuTag).forEach((anw) => {
          let kind;
          if (this.prop_kinder.filter((o) => o.id === anw.id_kind).length > 0) {
            [kind] = this.prop_kinder.filter((o) => o.id === anw.id_kind);
          } else {
            kind = {
              id: 0,
              nameMitKlasse: `${anw.delKind_vorname} ${anw.delKind_nachname} (${anw.delKind_klasse})`,
            };
          }
          this.anzeigeListeAnwesenheiten.push({
            id_kind: kind.id,
            tag: neuTag,
            kind: kind.nameMitKlasse,
            vorname: kind.vorname,
            nachname: kind.nachname,
            id_klasse: kind.id_klasse,
            name_klasse: kind.name_klasse,
            anwesend: true,
            von: anw.zeit_von,
            bis: anw.zeit_bis,
            entlassgrund: anw.entlassgrund,
          });
        });
      }
      // Alle Kinder ohne Anwesenheit an diesem Tag in der Liste anzeigen, sodass
      // eine Anwesenheit hinzugefügt werden kann.
      if (this.prop_kinder.length > 0) {
        this.prop_kinder
          .filter((o) => this.anzeigeListeAnwesenheiten
            .filter((anw) => anw.id_kind === o.id).length === 0)
          .forEach((k) => {
            let entlasszeit = k.standardentlasszeit;
            let entlassgrund = 'Standardentlasszeit';
            if (k.regelausnahmen.filter((o) => o.wochentag === this.wochentag).length > 0) {
              const [regelausnahme] = k.regelausnahmen
                .filter((o) => o.wochentag === this.wochentag);
              entlasszeit = regelausnahme.entlasszeit;
              let wochentag = '';
              if (this.wochentage.filter((o) => o.id === regelausnahme.wochentag).length > 0) {
                [wochentag] = this.wochentage.filter((o) => o.id === regelausnahme.wochentag);
              }
              entlassgrund = `Regelausnahme, ${regelausnahme.kommentar} (${wochentag.nameUnv})`;
            }
            if (
              k.ausnahmen
                .filter((o) => o.tag === neuTag)
                .filter((o) => o.anwesend === true)
                .filter((o) => o.elternErstellt === true).length > 0
            ) {
              const [neuAusnahme] = k.ausnahmen
                .filter((o) => o.tag === neuTag)
                .filter((o) => o.anwesend === true)
                .filter((o) => o.elternErstellt === true);
              entlasszeit = neuAusnahme.entlasszeit;
              entlassgrund = `Ausnahme, ${neuAusnahme.kommentar}`;
            }
            if (
              k.ausnahmen
                .filter((o) => o.tag === neuTag)
                .filter((o) => o.anwesend === true)
                .filter((o) => o.elternErstellt === false).length > 0
            ) {
              const [neuAusnahme] = k.ausnahmen
                .filter((o) => o.tag === neuTag)
                .filter((o) => o.anwesend === true)
                .filter((o) => o.elternErstellt === false);
              entlasszeit = neuAusnahme.entlasszeit;
              entlassgrund = `Ausnahme, ${neuAusnahme.kommentar}`;
            }
            const today = new Date();
            const H = String(today.getHours().toString().padStart(2, '0'));
            const i = String(today.getMinutes().toString().padStart(2, '0'));
            let von = `${H}:${i}`;
            if (!this.anmeldezeitJetzt) {
              von = this.anmeldezeit;
            }
            this.anzeigeListeAnwesenheiten.push({
              id_kind: k.id,
              tag: neuTag,
              kind: k.nameMitKlasse,
              vorname: k.vorname,
              nachname: k.nachname,
              id_klasse: k.id_klasse,
              name_klasse: k.name_klasse,
              anwesend: false,
              von,
              bis: entlasszeit,
              entlassgrund,
            });
          });
      }
      this.stringsaveAnzeigeListeAnwesenheiten = JSON.stringify(this.anzeigeListeAnwesenheiten);
      if (this.anzeigeListeAnwesenheiten.length > 0) {
        this.createAnzeigeliste(neuTag);
      }
    },
    createAnzeigeliste(tag) {
      if (tag) {
        this.anzeigeTag = tag;
      } else {
        const heute = new Date();
        const dd = String(heute.getDate()).padStart(2, '0');
        const mm = String(heute.getMonth() + 1).padStart(2, '0'); // January is 0!
        const yyyy = heute.getFullYear();
        this.anzeigeTag = `${yyyy}-${mm}-${dd}`;
      }
      this.getMoeglicheMonate();
    },
    getMoeglicheMonate() {
      let tag;
      let m;
      let strM;
      let y;
      this.downloads_moeglicheMonate = [];
      if (this.prop_anwesenheiten.length > 0) {
        this.prop_anwesenheiten.forEach((anw) => {
          tag = new Date(anw.tag);
          m = String(tag.getMonth() + 1).padStart(2, '0');
          strM = this.monate[`m${String(tag.getMonth() + 1).padStart(2, '0')}`];
          y = tag.getFullYear();
          if (this.downloads_moeglicheMonate.filter((o) => o.value === `${y}-${m}`).length <= 0) {
            this.downloads_moeglicheMonate.push({
              monat: m,
              jahr: y,
              name: `${strM} ${y}`,
              value: `${y}-${m}`,
            });
          }
        });
        /* const splitTag = this.anzeigeTag.split('-');
        if (this.downloads_moeglicheMonate
          .filter((o) => o.value === `${splitTag[0]}-${splitTag[1]}`).length > 0) {
          this.downloads_monatsuebersichtMonat = this.downloads_moeglicheMonate
            .filter((o) => o.value === `${splitTag[0]}-${splitTag[1]}`)[0].value;
        } else {
          [this.downloads_monatsuebersichtMonat] = this.downloads_moeglicheMonate.value;
        } */
      }
    },
    getChart(data) {
      data.charts.forEach((c) => {
        this.downloads_charts[c.name] = c.base64;
      });
      this.tryDownloadStatistik('monat');
    },
    async tryDownloadStatistik(typ) {
      this.downloads_loading = true;
      this.$store.commit('main/switchIsSaving', true);
      let wert;
      let value;
      let dateiname;
      let file = 'temp/f200-200_statistik.pdf';
      let { suffix } = this.downloads_monatsuebersichtElemente;
      if (suffix !== '') {
        suffix = `_${suffix}`;
      }
      if (typ === 'tag') {
        wert = this.downloads_tagesanwesenheitTag;
        value = wert;
        dateiname = `Anwesenheitsstatistik_${wert}${suffix}.pdf`;
        if (this.downloads_monatsuebersichtElemente.archivieren) {
          file = `f200-200/${wert}${suffix}.pdf`;
        }
      } else if (typ === 'monat') {
        [wert] = this.downloads_moeglicheMonate
          .filter((o) => o.value === this.downloads_monatsuebersichtMonat);
        dateiname = `OGSmyAdmin_Anwesenheit_${wert.jahr}-${wert.monat}${suffix}.zip`;
        if (this.downloads_monatsuebersichtElemente.archivieren) {
          file = `f200-200/${wert.value}${suffix}/${wert.value}${suffix}.zip`;
        }
        value = wert.value;
        wert = JSON.stringify(wert);
      } else {
        // Wenn nur Download
        wert = typ;
        let neuwert = wert.split('/');
        neuwert = neuwert.pop();
        // let dateityp = wert.split('.');
        // dateityp = dateityp.pop();
        dateiname = `OGSmyAdmin_Anwesenheit_${neuwert}`;
        file = `f200-200/${wert}`;
      }
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/200/200/statistik.php?func=createAnwesenheitsstatistik`,
        type: 'post',
        data: {
          code: this.$store.state.login.session.code,
          typ,
          wert,
          monatsuebersichtElemente: JSON.stringify(this.downloads_monatsuebersichtElemente),
          charts: JSON.stringify(this.downloads_charts),
        },
        timeout: 10000000,
        success: () => {
          if (
            this.downloads_monatsuebersichtElemente.archivieren
            && (typ === 'tag' || typ === 'monat')
          ) {
            let help = JSON.parse(JSON.stringify(this.prop_anwesenheitenDokumente));
            if (!help.includes(file.replace('f200-200/', ''))) {
              help.push(file.replace('f200-200/', ''));
              help = help.sort();
            }
            this.$emit('neuAnwesenheiten', {
              anwesenheitenDokumente: help,
            });
          }
          if (
            this.downloads_monatsuebersichtElemente.loeschen
            && (typ === 'tag' || typ === 'monat')
          ) {
            const help = JSON.parse(JSON.stringify(this.prop_anwesenheiten));
            this.prop_anwesenheiten.filter((o) => o.tag.includes(value)).forEach((i) => {
              help.splice(help.indexOf(i), 1);
            });
            this.$emit('neuAnwesenheiten', {
              anwesenheiten: help,
            });
            this.changeAnzeige(this.anzeigeTag);
          }
          // Erstelltes PDF downloaden:
          this.tryDownload(file, dateiname);
        },
        error: () => {
          this.$store.commit('main/alert', {
            typ: 'error',
            text: `Die angeforderte Datei konnte nicht erzeugt werden.
            Bitte die Netzwerkverbindung prüfen.`,
          }, { root: true });
          this.$store.commit('main/switchIsSaving', false);
          this.createChartsMonatsuebersicht = false;
        },
        complete: () => {
        },
      });
    },
    async tryDownload(file, dateiname) {
      await $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/data/download.php`,
        method: 'POST',
        data: {
          code: this.$store.state.login.session.code,
          file,
        },
        xhrFields: {
          responseType: 'blob',
        },
        success: (data) => {
          const a = document.createElement('a');
          const url = window.URL.createObjectURL(data);
          a.href = url;
          a.download = dateiname;
          document.body.append(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
          this.dialog_downloads_createPDF = false;
        },
        error: () => {
          this.$store.commit('main/alert', {
            typ: 'error',
            text: `Die angeforderte Datei konnte nicht erzeugt werden.
            Bitte die Netzwerkverbindung prüfen.`,
          }, { root: true });
          this.downloads_loading = false;
        },
        complete: () => {
          this.$store.commit('main/switchIsSaving', false);
          this.createChartsMonatsuebersicht = false;
          this.downloads_loading = false;
        },
      });
    },
    async trySaveAnwesenheit(delDokument) {
      this.$store.commit('main/switchIsSaving', true);
      this.loadingWhileSaving = true;
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/200/200/statistik.php?func=saveAnwesenheit`,
        type: 'post',
        data: {
          code: this.$store.state.login.session.code,
          tag: this.anzeigeTag,
          liste: JSON.stringify(this.anzeigeListeAnwesenheiten),
          delDokument,
        },
        success: (response) => {
          if (response.status === 'accessError') {
            this.$store.commit('login/backendErrorSession');
          } else {
            this.$emit('neuAnwesenheiten', {
              anwesenheiten: response.anwesenheiten,
              anwesenheitenMonate: response.anwesenheitenMonate,
              anwesenheitenDokumente: response.anwesenheitenDokumente,
            });
            this.anwesenheitGeaendert = false;
            this.stringsaveAnzeigeListeAnwesenheiten = JSON
              .stringify(this.anzeigeListeAnwesenheiten);
            setTimeout(() => {
              this.getMoeglicheMonate();
              this.createChartData();
            }, 100);
          }
        },
        timeout: 10000,
        error: () => {
          // Einstellungen konnten nicht geladen werden
          this.$store.commit('main/alert', {
            typ: 'error',
            text: `Die Einstellungen konnten nicht gespeichert werden.
            Bitte die Netzwerkverbindung prüfen.`,
          }, { root: true });
        },
        complete: () => {
          this.$store.commit('main/switchIsSaving', false);
          this.loadingWhileSaving = false;
        },
      });
    },
    createChartData() {
      this.downloads_chartData = [];
      this.downloads_chartData.push({
        id: '1',
        title: 'quantitativeAnwesenheit',
        titleSize: 20,
        name: 'quantitativeAnwesenheit',
        dataByLabels: [],
        labels: [],
        data: [],
        fontSize: 20,
        ticksSize: 20,
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
        aspectRatio: 2,
        showLegend: false,
      });
      this.downloads_chartData.push({
        id: '2',
        title: 'entlasszeitenuebersicht',
        titleSize: 20,
        name: 'entlasszeitenuebersicht',
        dataByLabels: [],
        labels: [],
        data: [],
        fontSize: 20,
        ticksSize: 20,
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
        aspectRatio: 2,
        showLegend: false,
      });
      const quantAnw = this.downloads_chartData.filter((o) => o.name === 'quantitativeAnwesenheit')[0];
      quantAnw.dataByLabels = [];
      quantAnw.labels = [];
      quantAnw.data = [];
      quantAnw.backgroundColor = [];
      quantAnw.borderColor = [];
      const entlUeber = this.downloads_chartData.filter((o) => o.name === 'entlasszeitenuebersicht')[0];
      entlUeber.dataByLabels = [];
      entlUeber.labels = [];
      entlUeber.data = [];
      entlUeber.backgroundColor = [];
      entlUeber.borderColor = [];
      if (this.prop_anwesenheitenMonate[this.downloads_monatsuebersichtMonat]) {
        this.prop_anwesenheitenMonate[this.downloads_monatsuebersichtMonat].forEach((anw) => {
          let idKlasse;
          if (this.prop_kinder.filter((o) => o.id === anw.id_kind).length > 0) {
            idKlasse = this.prop_kinder.filter((o) => o.id === anw.id_kind)[0].id_klasse;
          } else {
            idKlasse = anw.delKind_idKlasse;
          }
          if (this.downloads_monatsuebersichtElemente.klassen.includes(idKlasse)) {
            // quantitative Anwesenheit:
            let tag = new Date(anw.tag);
            const dd = String(tag.getDate()).padStart(2, '0');
            const mm = String(tag.getMonth() + 1).padStart(2, '0');
            // const yyyy = tag.getFullYear();
            tag = `${dd}.${mm}.`;
            if (!quantAnw.dataByLabels.filter((o) => o.label === tag).length > 0) {
              quantAnw.dataByLabels.push({
                label: tag,
                data: 1,
              });
            } else {
              quantAnw.dataByLabels.filter((o) => o.label === tag)[0].data += 1;
            }
            // Entlasszeitenübersicht:
            if (anw.zeit_bis !== '' || 1 < 2) {
              let zeitBis = 'nicht entlassen';
              if (anw.zeit_bis !== '') {
                zeitBis = anw.zeit_bis;
              }
              if (!entlUeber.dataByLabels.filter((o) => o.label === zeitBis).length > 0) {
                entlUeber.dataByLabels.push({
                  label: zeitBis,
                  data: 1,
                });
              } else {
                entlUeber.dataByLabels.filter((o) => o.label === zeitBis)[0].data += 1;
              }
            }
          }
        });
        // quantitative Anwesenheit:
        quantAnw.dataByLabels.sort((a, b) => {
          if (a.label > b.label) {
            return 1;
          }
          if (b.label > a.label) {
            return -1;
          }
          return 0;
        });
        quantAnw.dataByLabels.forEach((dl) => {
          // quantAnw.labels.push(dl.label);
          // quantAnw.data.push(dl.data);
          quantAnw.labels.push(`${dl.label} (${dl.data})`);
          quantAnw.data.push(dl.data);
          quantAnw.backgroundColor.push('rgba(38, 147, 255, 0.5)');
          quantAnw.borderColor.push('rgba(38, 147, 255, 1)');
        });
        // Entlasszeitenübersicht:
        entlUeber.dataByLabels.sort((a, b) => {
          if (a.label > b.label) {
            return 1;
          }
          if (b.label > a.label) {
            return -1;
          }
          return 0;
        });
        entlUeber.dataByLabels.forEach((dl) => {
          // entlUeber.labels.push(dl.label);
          // entlUeber.data.push(dl.data);
          entlUeber.labels.push(`${dl.label} (${dl.data})`);
          entlUeber.data.push(dl.data);
          entlUeber.backgroundColor.push('rgba(38, 147, 255, 0.5)');
          entlUeber.borderColor.push('rgba(38, 147, 255, 1)');
        });
      }
    },
  },
  created() {
    const heute = new Date();
    let weekday = heute.getDay();
    if (weekday <= 0) {
      weekday = 7;
    }
    this.wochentag = `${weekday}`;
    this.createAnzeigeliste();
    this.prop_klassen.forEach((k) => {
      if (!k.name.toLowerCase().includes('neu')) {
        this.downloads_monatsuebersichtElemente.klassen.push(k.id);
      }
    });
    if (localStorage.getItem('f200_timeAnm')) {
      const loc = JSON.parse(localStorage.getItem('f200_timeAnm'));
      [this.anmeldezeitJetzt, this.anmeldezeit, this.nurAngemeldeteZeigen] = loc;
    }
    if (localStorage.getItem('f200_filterClass')) {
      this.downloads_monatsuebersichtElemente.klassen = JSON.parse(localStorage.getItem('f200_filterClass'));
    }
  },
};
</script>
