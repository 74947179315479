<template>
  <v-container fluid class="ma-0 pa-0 d-flex justify-center">
    <v-container class="ma-0 pa-0">
      <v-tabs
        fixed-tabs
        v-model="tabs_allgemeinKlassen"
        class="d-md-none mb-6"
      >
        <v-tab>Allgemein</v-tab>
        <v-tab>Klassen</v-tab>
      </v-tabs>
      <v-container class="ma-0 pa-2">
        <v-row>
          <v-col
            v-if="
              tabs_allgemeinKlassen == '0' ||
              $vuetify.breakpoint.mdAndUp
            "
          >
            <!-- Allgemein -->
            <v-container
              class="text-title text--secondary text-uppercase text-center d-none d-md-block"
            >
              Allgemein
            </v-container>
            <v-row v-if="1 === 2">
              <v-col align="center">
                <v-btn small
                  @click="trySwitchOldDB('del');"
                  :loading="loadingTrySwitchOldDB"
                >Daten in DB löschen</v-btn>
              </v-col>
              <v-col align="center">
                <v-btn
                  small
                  @click="trySwitchOldDB('add');"
                  :loading="loadingTrySwitchOldDB"
                  :disabled="
                    prop_klassen.length > 0
                  "
                >Daten aus alter APP laden</v-btn>
              </v-col>
            </v-row>
            <p
              class="text-title text--secondary"
            >
              Nutzergruppe der Eltern
            </p>
            <p
              class="text-caption text--secondary mb-0"
            >
              Nutzer, die der folgenden Nutzergruppe zugeordnet sind, werden
              als Eltern behandelt:
            </p>
            <v-select
              :items="$store.state.main.save.nutzergruppen"
              v-model="save.settings.id_nutzergruppe_eltern"
              item-value="id"
              item-text="name"
              class="pt-0"
            />
            <p
              class="text-title text--secondary"
            >
              Standardwerte
            </p>
            <p
              class="text-caption text--secondary mb-4"
            >
              Die folgenden Werte gelten für alle neu hinzugefügten Kinder:
            </p>
            <v-row>
              <v-col>
                <TimePicker
                  label="Standardbetreuungsplatz"
                  icon="mdi-clock-time-four"
                  v-model="save.settings.standardbetreuungsplatz"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <TimePicker
                  label="Standardentlasszeit"
                  icon="mdi-clock-time-four"
                  v-model="save.settings.standardentlasszeit"
                />
              </v-col>
            </v-row>
            <p
              class="text-title text--secondary"
            >
              Elternbriefe: Logindaten
            </p>
            <v-text-field
              type="text"
              label="URL zur App"
              v-model="save.settings.eltern_elternbriefLogindaten_appurl"
            ></v-text-field>
            <p
              class="text-caption text--secondary mb-4"
            >
              Generierte Elternbriefe zur Weitergabe der Logindaten enthalten
              folgende Texte:
            </p>
            <v-row>
              <v-col>
                <v-textarea
                  auto-grow
                  label="Text vor den Logindaten"
                  v-model="save.settings.eltern_elternbriefLogindaten_textBeforeLogindata"
                  hint=""
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  auto-grow
                  label="Text nach den Logindaten"
                  v-model="save.settings.eltern_elternbriefLogindaten_textAfterLogindata"
                  hint=""
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row v-if="xChanged.settings">
              <v-col align="center">
                <v-btn
                small
                color="success"
                class="ml-2"
                :loading="isSaving"
                @click="trySaveSettings({ type: 'settings', data: save.settings});"
                >
                  <v-icon
                  left
                  >mdi-content-save</v-icon>
                  Änderungen Speichern
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            v-if="
              tabs_allgemeinKlassen == '1' ||
              $vuetify.breakpoint.mdAndUp
            "
          >
            <!-- Klassen -->
            <v-container
              class="text-title text--secondary text-uppercase text-center d-none d-md-block"
            >
              Klassen
            </v-container>
            <v-row>
              <v-col align="center">
                <v-dialog
                  scrollable
                  v-model="dialog_neuesSchuljahr"
                  width="500"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      class="ma-1"
                    >
                      <v-icon left>mdi-swap-horizontal</v-icon>
                      Neues Schuljahr
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title>
                      <b v-if="
                        neuesSchuljahrStatus.schritt === 1
                      ">
                        Schritt 1: Neue Klassenzuweisung
                      </b>
                      <b v-if="
                        neuesSchuljahrStatus.schritt === 2
                      ">
                        Schritt 2: Elternkonten generieren
                      </b>
                      <b v-if="
                        neuesSchuljahrStatus.schritt === 3
                      ">
                        Schritt 3: Anwesenheiten zurücksetzen
                      </b>
                      <b v-if="
                        neuesSchuljahrStatus.schritt === 4
                      ">
                        Schritt 4: Prüfen und Fertigstellen
                      </b>
                    </v-card-title>

                    <v-card-text>
                      <!-- STUFE 1 -->
                      <div
                        v-if="neuesSchuljahrStatus.schritt === 1"
                      >
                        <v-checkbox
                          v-model="neuesSchuljahrStatus.verteileNeueKlassen"
                          label="Kinder in die nächste Klasse versetzen"
                        ></v-checkbox>
                        <template v-if="neuesSchuljahrStatus.verteileNeueKlassen">
                          <p
                            class="text-caption text--secondary mb-0"
                            style="text-align:justify;"
                          >
                            In welche Klassen werden die Kinder der jeweiligen Klassen versetzt?
                            <br>
                            - Die Kinder in den Klassen ohne neue Zuweisung werden entfernt.
                            <br>
                            - Die Kinder in Klassen, die sich selbst zugewiesen sind,
                            bleiben unberührt.
                            <br>
                            - Auf Basis der Namen der Klassen kann die Zuweisung auch automatisch
                            erfolgen. (Beispiel: Kinder der Klasse 1A werden der 2A zugewiesen.)
                          </p>
                          <v-row class="my-0 mt-2">
                            <v-col class="py-0" align="center">
                              <v-btn
                                x-small
                                @click="automatischeKlassenzuweisungBeiSchuljahreswechsel();"
                              >
                                Automatische zuweisung
                              </v-btn>
                            </v-col>
                            <v-col class="py-0" align="center">
                              <v-btn
                                x-small
                                @click="resetAutomatischeKlassenzuweisungBeiSchuljahreswechsel();"
                              >
                                Zurücksetzen
                              </v-btn>
                            </v-col>
                          </v-row>
                          <v-row class="py-0">
                            <v-col
                              cols="6"
                              v-for="(klasse, idx) in prop_klassen"
                              :key="idx"
                            >
                              <v-select
                                :label="klasse.name"
                                :items="prop_klassen"
                                v-model="neuesSchuljahrStatus.naechsteKlasse[klasse.id]"
                                item-text="name"
                                item-value="id"
                              />
                            </v-col>
                          </v-row>
                        </template>
                      </div>
                      <!-- STUFE 2 -->
                      <div
                        v-if="neuesSchuljahrStatus.schritt === 2"
                      >
                        <v-checkbox
                          v-model="neuesSchuljahrStatus.alleElternkontenLoeschen"
                          label="Nur alle Elternkonten löschen"
                        ></v-checkbox>
                        <p
                          v-if="neuesSchuljahrStatus.alleElternkontenLoeschen"
                          class="text-caption text--secondary mb-0"
                          style="text-align:justify;"
                        >
                          Alle Elternkonten werden gelöscht und es werden keine neuen Elternkonten
                          generiert.
                        </p>
                        <div v-else>
                          <p
                            class="text-caption text--secondary mb-0"
                            style="text-align:justify;"
                          >
                            Alle Elternkonten der Kinder entfernt werden, werden
                            ebenfalls gelöscht, es sei denn, ihnen ist mindestens
                            ein weiteres Kind aus einer anderen Klasse zugewiesen.
                          </p>
                          <v-checkbox
                            v-model="neuesSchuljahrStatus.fuerAlleNeueElternkonten"
                            label="Allen Kindern neue Elternkonten generieren"
                          ></v-checkbox>
                          <p
                            v-if="!neuesSchuljahrStatus.fuerAlleNeueElternkonten"
                            class="text-caption text--secondary mb-0"
                            style="text-align:justify;"
                          >
                            Für alle Kinder, denen kein Elternkonto zugewiesen ist,
                            wird ein neues Elternkonto erstellt.<br>
                            Kinder mit Geschwisterkindern, die bereits einem
                            Elternkonto zugeordnet sind, werden ebenfalls diesem
                            zugeordnet.
                          </p>
                          <p
                            v-if="neuesSchuljahrStatus.fuerAlleNeueElternkonten"
                            class="text-caption text--secondary mb-0"
                            style="text-align:justify;"
                          >
                            Es werden alle bestehenden Elternkonten gelöscht und
                            für jedes Kind neu generiert.<br>
                            ACHTUNG: Da Telefonnummern den Eltern zugewiesen sind,
                            werden diese beim Löschen der Elternkonten ebenfalls
                            gelöscht.
                          </p>
                          <p
                            class="text-caption text--secondary mb-0"
                            style="text-align:justify;"
                          >
                            Geschwisterkinder erhalten ein gemeinsames
                            Elternkonto.<br>
                            Als Nutzername für das Elternkonto wird immer der
                            Nachname des Kindes verwendet.
                            Ist bereits ein Konto mit diesem Namen vorhanden,
                            wird eine fortlaufende Ziffer angefügt.
                          </p>
                        </div>
                      </div>
                      <!-- STUFE 3 -->
                      <div
                        v-if="neuesSchuljahrStatus.schritt === 3"
                      >
                        <v-checkbox
                          v-model="neuesSchuljahrStatus.anwesenheitenZuruecksetzen"
                          label="Alle Anwesenheiten zurücksetzen"
                        ></v-checkbox>
                        <p
                          v-if="!neuesSchuljahrStatus.anwesenheitenZuruecksetzen"
                          class="text-caption text--secondary mb-0"
                          style="text-align:justify;"
                        >
                          Die Anwesenheiten bleiben bestehen.<br>
                          <br>Achten Sie dennoch darauf, nicht
                          mehr benötigte Anwesenheiten dennoch zu löschen. Dies verhindert
                          Schwierigkeiten bei der Auswertung und verkürzt die Ladezeit erheblich.
                        </p>
                        <p
                          v-if="neuesSchuljahrStatus.anwesenheitenZuruecksetzen"
                          class="text-caption text--secondary mb-0"
                          style="text-align:justify;"
                        >
                          Es werden sämtliche bestehenden Anwesenheiten restlos gelöscht.<br>
                          <br>
                          Achten Sie darauf, dass für alle benötigten Anwesenheiten unter
                          "Statistik" eine Auswertung erstellt und archiviert wurde.
                        </p>
                      </div>
                      <!-- STUFE 4 -->
                      <div
                        v-if="neuesSchuljahrStatus.schritt === 4"
                      >
                        <p
                          class="text-caption text--secondary mb-0"
                          style="text-align:justify;"
                        >
                          Mit Klick auf "Speichern" wird der Schuljahreswechsel
                          durchgeführt.<br>
                          Achten Sie darauf, dass folgende Dinge erledigt sind:<br>
                          <ul>
                            <li v-if="neuesSchuljahrStatus.verteileNeueKlassen">
                              Klassen, die in Schritt 1 keiner anderen Klasse zugewiesen
                              wurden, beinhalten die zu löschenden Kinder.
                            </li>
                            <li v-if="neuesSchuljahrStatus.alleElternkontenLoeschen">
                              Sie sind sicher dass alle Elternkonten und deren
                              Informationen (z.B. Telefonnummer) restlos gelöscht werden
                              sollen.
                            </li>
                            <li v-else>
                              Alle Geschwisterkinder wurden als solche definiert, sodass für
                              diese ein gemeinsames Elternkonto erstellt werden kann.
                            </li>
                            <li v-if="neuesSchuljahrStatus.anwesenheitenZuruecksetzen">
                              Für alle bisher eingetragenen Anwesenheiten wurden die
                              Übersichten als PDF generiert und Archiviert.
                            </li>
                          </ul>
                        </p>
                      </div>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-btn
                        text small
                        @click="dialog_neuesSchuljahr = false"
                      >
                        Schließen
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn small color="primary"
                        class="me-1"
                        v-if="neuesSchuljahrStatus.schritt > 1"
                        @click="tryNeuesSchuljahrStepBack();"
                      >
                        zurück
                      </v-btn>
                      <v-btn small color="primary"
                        v-if="
                          neuesSchuljahrStatus.schritt < neuesSchuljahrAnzahlSchritte
                        "
                        @click="tryNeuesSchuljahrNextStep();"
                      >
                        Weiter
                      </v-btn>
                      <v-btn
                        v-if="neuesSchuljahrStatus.schritt == neuesSchuljahrAnzahlSchritte"
                        text small color="primary"
                        @click="tryNeuesSchuljahrNextStep();"
                        :loading="neuesSchuljahr_loading"
                      >
                        speichern
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-expansion-panels
                v-model="expansionPanelStatusKlassen"
                accordion
                >
                  <v-expansion-panel
                  v-for="(klasse, idx) in save.klassen" :key="idx"
                  class="grey lighten-5"
                  >
                    <v-expansion-panel-header>
                      <v-icon left style="flex:none;"></v-icon>
                      Klasse {{ klasse.name }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="white">
                      <v-row class="my-0">
                        <v-col class="pb-0">
                          <v-text-field
                            type="text"
                            label="Name"
                            v-model="klasse.name"
                            :rules="[
                              $store.state.main.inputValidationRules.required,
                              $store.state.main.inputValidationRules.tinytext,
                              $store.state.main.inputValidationRules.name,
                            ]"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="my-0">
                        <v-col class="pb-0">
                          <p
                            v-if="klasse.id < 0"
                            class="text-title text--secondary"
                          >
                            Im eigenen Profil
                          </p>
                          <p
                            v-if="klasse.id < 0"
                            class="text-caption text--secondary"
                          >
                            Wer darf diesen Klasse in der Übersicht sehen bzw. bearbeiten?
                            <br>Achtung: Wer bearbeiten kann, kann automatisch auch sehen.
                          </p>
                          <v-checkbox
                            v-model="klasse.bedingungen.f200.sehen.alle"
                            label="Jeder kann sehen"
                          ></v-checkbox>
                          <SelectNutzergruppen
                            v-if="!klasse.bedingungen.f200.sehen.alle"
                            v-model="klasse.bedingungen.f200.sehen.gruppen"
                            label="Nur bestimmte können sehen:"
                          />
                          <v-checkbox
                            v-model="klasse.bedingungen.f200.bearbeiten.alle"
                            label="Jeder kann bearbeiten"
                          ></v-checkbox>
                          <SelectNutzergruppen
                            v-if="!klasse.bedingungen.f200.bearbeiten.alle"
                            v-model="klasse.bedingungen.f200.bearbeiten.gruppen"
                            label="Nur bestimmte können bearbeiten:"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col align="center">
                          <v-dialog
                            v-model="klasse.dialog_delKlasse"
                            width="500"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                              small
                              color="error"
                              v-bind="attrs"
                              v-on="on"
                              >
                                <v-icon left>mdi-delete</v-icon>
                                "{{ klasse.name }}" Löschen
                              </v-btn>
                            </template>

                            <v-card>
                              <v-card-title class="text-h5 grey lighten-2">
                                Sicher?
                              </v-card-title>

                              <v-card-text class="pt-4">
                                Es werden sämtliche mit dieser Klasse zusammenhängende Daten
                                und Informationen gelöscht.
                              </v-card-text>

                              <v-divider></v-divider>

                              <v-card-actions>
                                <v-btn
                                  color="primary"
                                  text
                                  @click="klasse.dialog_delKlasse = false;"
                                >
                                  Nichts tun
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="primary"
                                  text
                                  @click="klasseLoeschen(idx);"
                                >
                                  Ja, löschen
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
            <v-row>
              <v-col align="center">
                <v-btn
                  small
                  @click="klasseHinzufuegen();"
                >
                  <v-icon
                  left
                  >mdi-plus-box</v-icon>
                  Klasse
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="xChanged.klassen">
              <v-col align="center">
                <v-btn
                small
                color="success"
                class="ml-2"
                :loading="isSaving"
                @click="trySaveSettings({ type: 'klassen', data: save.klassen});"
                >
                  <v-icon
                  left
                  >mdi-content-save</v-icon>
                  Änderungen Speichern
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
import $ from 'jquery';
import SelectNutzergruppen from '@/components/Inputs/SelectNutzergruppen.vue';
import TimePicker from '@/components/Inputs/TimePicker.vue';

export default {
  components: {
    SelectNutzergruppen,
    TimePicker,
  },
  props: {
    prop_klassen: {
      type: Array,
      default() { return []; },
    },
    prop_settings: {
      type: Object,
      default() { return {}; },
    },
  },
  data: () => ({
    loadingTrySwitchOldDB: false,
    xLoaded: {},
    xChanged: {},
    xDeleted: {},
    save: {
      klassen: [],
      settings: {},
      kinder: [],
    },
    tabs_allgemeinKlassen: 0,
    expansionPanelStatusKlassen: null,
    dialog_delKlasse: false,
    dialog_neuesSchuljahr: false,
    neuesSchuljahr_loading: false,
    neuesSchuljahrAnzahlSchritte: 4,
    neuesSchuljahrStatus: {
      schritt: 1,
      naechsteKlasse: {},
      alleElternkontenLoeschen: false,
      fuerAlleNeueElternkonten: false,
      verteileNeueKlassen: true,
      anwesenheitenZuruecksetzen: true,
    },
  }),
  computed: {
    isSaving: { // Databinding mit vuex state
      get() {
        return this.$store.state.main.isSaving;
      },
      set(value) {
        this.$store.commit('main/switchIsSaving', value);
      },
    },
  },
  watch: {
    prop_klassen: {
      handler() {
        this.datenLaden();
      },
      deep: true,
    },
    prop_settings: {
      handler() {
        this.datenLaden();
      },
      deep: true,
    },
    save: {
      handler() {
        /*
          Wenn ein Datenobjekt lokal (und nicht durch Laden) verändert wurde
        */
        if (JSON.stringify(this.save.klassen) !== JSON.stringify(this.xLoaded.klassen)) {
          this.xChanged.klassen = true;
        } else {
          this.xChanged.klassen = false;
        }
        if (JSON.stringify(this.save.settings) !== JSON.stringify(this.xLoaded.settings)) {
          this.xChanged.settings = true;
        } else {
          this.xChanged.settings = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    async trySwitchOldDB(type) {
      this.loadingTrySwitchOldDB = true;
      this.$store.commit('main/switchIsSaving', false);
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/200/200/switchOldDB.php?func=getData`,
        type: 'post',
        data: {
          code: this.$store.state.login.session.code,
          type,
        },
        success: () => {
        },
        error: () => {
          // Einstellungen konnten nicht geladen werden
          this.$store.commit('main/alert', {
            typ: 'error',
            text: 'Die Einstellungen konnten nicht gespeichert werden. Bitte die Netzwerkverbindung prüfen.',
          }, { root: true });
        },
        complete: () => {
          this.$store.commit('main/switchIsSaving', false);
          this.loadingTrySwitchOldDB = false;
        },
      });
    },
    datenLaden() {
      /*
        Wenn neue Daten übergeben wurden,
        prüfe ob diese sich von den zuletzt geladenen unterscheiden.
        Wenn ja, überschreibe die aktuellen Daten mit den neu übergebenen Daten.
      */
      let anythingUpdated = false;
      if (JSON.stringify(this.prop_klassen) !== JSON.stringify(this.xLoaded.klassen)) {
        this.save.klassen = JSON.parse(JSON.stringify(this.prop_klassen));
        this.xLoaded.klassen = JSON.parse(JSON.stringify(this.prop_klassen));
        anythingUpdated = true;
      }
      if (JSON.stringify(this.prop_settings) !== JSON.stringify(this.xLoaded.settings)) {
        this.save.settings = JSON.parse(JSON.stringify(this.prop_settings));
        this.xLoaded.settings = JSON.parse(JSON.stringify(this.prop_settings));
        anythingUpdated = true;
      }
      if (anythingUpdated) {
        this.expansionPanelStatusKlassen = null;
      }
    },
    klasseHinzufuegen() {
      this.save.klassen.push({
        id: '',
        name: 'Neu',
        bedingungen: {
          f200: {
            sehen: {
              alle: true,
              gruppen: [],
            },
            bearbeiten: {
              alle: true,
              gruppen: [],
            },
          },
        },
      });
      this.expansionPanelStatusKlassen = this.save.klassen.length - 1;
    },
    klasseLoeschen(idx) {
      if (this.save.klassen[idx].id) {
        this.xDeleted.klassen.push(this.save.klassen[idx].id);
      }
      this.save.klassen.splice(idx, 1);
      this.expansionPanelStatusKlassen = null;
    },
    async trySaveSettings(dataToSave) {
      this.$emit('saveSettings', { dataToSave, loaded: this.xLoaded, deleted: this.xDeleted });
    },
    async tryNeuesSchuljahrStepBack() {
      this.neuesSchuljahrStatus.schritt -= 1;
      localStorage.setItem('f200_nextYeahr', JSON.stringify(this.neuesSchuljahrStatus));
    },
    async tryNeuesSchuljahrNextStep() {
      if (this.neuesSchuljahrStatus.schritt < this.neuesSchuljahrAnzahlSchritte) {
        this.neuesSchuljahrStatus.schritt += 1;
        localStorage.setItem('f200_nextYeahr', JSON.stringify(this.neuesSchuljahrStatus));
      } else {
        this.neuesSchuljahr_loading = true;
        $.ajax({
          url: `${process.env.VUE_APP_API_HOST}/200/200/?func=neuesSchuljahr`,
          type: 'post',
          data: {
            code: this.$store.state.login.session.code,
            neuesSchuljahrStatus: JSON.stringify(this.neuesSchuljahrStatus),
          },
          success: (response) => {
            if (response.status === 'accessError') {
              this.$store.commit('login/backendErrorSession');
            } else {
              this.dialog_neuesSchuljahr = false;
              localStorage.removeItem('f200_nextYeahr');
            }
          },
          timeout: 10000,
          error: () => {
            // Einstellungen konnten nicht geladen werden
            this.$store.commit('main/alert', {
              typ: 'error',
              text: 'Die Einstellungen konnten nicht gespeichert werden. Bitte die Netzwerkverbindung prüfen.',
            }, { root: true });
          },
          complete: () => {
            // complete
            this.neuesSchuljahr_loading = false;
          },
        });
      }
    },
    automatischeKlassenzuweisungBeiSchuljahreswechsel() {
      this.prop_klassen.forEach((k) => {
        const stufe = parseInt(k.name, 10);
        const neueStufe = stufe + 1;
        const ohneStufe = k.name.split(stufe).join('');
        if (this.prop_klassen.filter((o) => o.name === `${neueStufe}${ohneStufe}`).length > 0) {
          // theoretisch nächste Klasse gefunden
          this.neuesSchuljahrStatus.naechsteKlasse[k.id] = this.prop_klassen.filter((o) => o.name === `${neueStufe}${ohneStufe}`)[0].id;
        } else if (k.name.toLowerCase().includes('neu')) {
          // theoretisch KEINE nächste Klasse gefunden
          // Da neu im Namen, weise sich selbst zu
          this.neuesSchuljahrStatus.naechsteKlasse[k.id] = k.id;
        } else {
          // theoretisch KEINE nächste Klasse gefunden
          this.neuesSchuljahrStatus.naechsteKlasse[k.id] = 0;
        }
      });
      console.log(this.neuesSchuljahrStatus);
      this.dialog_neuesSchuljahr = false;
      this.dialog_neuesSchuljahr = true;
    },
    resetAutomatischeKlassenzuweisungBeiSchuljahreswechsel() {
      this.neuesSchuljahrStatus.naechsteKlasse = {};
    },
  },
  created() {
    this.xDeleted = JSON.parse(JSON.stringify(this.save));
    this.datenLaden();
    if (localStorage.getItem('f200_nextYeahr')) {
      this.neuesSchuljahrStatus = JSON.parse(localStorage.getItem('f200_nextYeahr'));
    } else {
      // this.automatischeKlassenzuweisungBeiSchuljahreswechsel();
    }
  },
};
</script>
