var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ma-0 pa-0 d-flex justify-center",attrs:{"fluid":""}},[_c('v-container',{staticClass:"ma-0 pa-0"},[_c('div',[_c('F150SlotsEdit',{attrs:{"prop_editslot":_vm.editslot}}),_c('F150SlotsUebersicht',{attrs:{"prop_idslot":_vm.uebersichtslot},on:{"close":function($event){_vm.uebersichtslot = 0;}}}),(_vm.get.s)?_c('F150SlotsAnwesenheiten',{attrs:{"s":parseInt(_vm.get.s, 10)}}):_vm._e(),_c('v-container',[_c('ScanQrCode',{staticClass:"me-1",attrs:{"btntext":"","btnsize":"small","btncolor":"primary"},on:{"scanned":function($event){return _vm.$store.dispatch(
            ("f" + _vm.sitecode + "/tryAnwesenheitAnmelden"),
            { e: $event, router: _vm.$router }
          );}}}),(
            this.$store.state.main.save.user.rechte.includes('x|150|000|adm')
            || this.$store.state.main.save.user.rechte.includes('x|150|000|010')
          )?_c('v-btn',{staticClass:"me-1",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.createSlot();}}},[_c('v-icon',[_vm._v("mdi-plus-box")])],1):_vm._e()],1)],1),_vm._l((_vm.store.slots),function(slot){return _c('v-card',{key:slot.id,staticClass:"ma-4"},[_c('i',{staticClass:"ps-4",staticStyle:{"position":"absolute","top":"5px","font-size":"70%","pointer-events":"none"}},[_vm._v(" "+_vm._s(slot.sort)+" ")]),_c('v-card-title',[_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){return _vm.$router.push({ path: ("150?s=" + (slot.id)) });}}},[_vm._v(" "+_vm._s(slot.name)+" ")])],1),_c('v-col',{attrs:{"align":"right","cols":"4"}},[(
                slot.bedingungen.f150.bearbeiten.nutzer
                || slot.bedingungen.f150.sehen.nutzer
              )?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-menu")])]}}],null,true)},[_c('v-list',[(slot.bedingungen.f150.bearbeiten.nutzer)?_c('v-list-item',{on:{"click":function($event){return _vm.downloadQRCode(slot)}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-qrcode")]),_vm._v(" QR-Code herunterladen ")],1)],1):_vm._e(),(
                    slot.bedingungen.f150.sehen.nutzer
                    || slot.bedingungen.f150.bearbeiten.nutzer
                  )?_c('v-list-item',{on:{"click":function($event){_vm.uebersichtslot = JSON.parse(JSON.stringify(slot)).id;}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-eye-outline")]),_vm._v(" Übersicht ")],1)],1):_vm._e(),(slot.bedingungen.f150.bearbeiten.nutzer)?_c('v-list-item',{on:{"click":function($event){_vm.editslot = JSON.parse(JSON.stringify(slot));}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v(" Slot bearbeiten ")],1)],1):_vm._e(),(slot.bedingungen.f150.bearbeiten.nutzer)?_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v(" Slot löschen ")],1)],1)]}}],null,true),model:{value:(_vm.dialog_delSlot[slot.id]),callback:function ($$v) {_vm.$set(_vm.dialog_delSlot, slot.id, $$v)},expression:"dialog_delSlot[slot.id]"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Sicher? ")]),_c('v-card-text',[_vm._v(" Sämtliche Anwesenheiten dieses Slots werden ebenfalls gelöscht. ")]),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog_delSlot[slot.id] = false}}},[_vm._v(" Nichts tun ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":"","loading":_vm.saving.delslot[slot.id]},on:{"click":function($event){return _vm.delSlot(slot);}}},[_vm._v(" Slot löschen ")])],1)],1)],1):_vm._e()],1)],1):_vm._e()],1)],1),_c('div',{attrs:{"hidden":""}},[_vm._v(_vm._s(_vm.saving))])],1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }