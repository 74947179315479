import $ from 'jquery';

export default {
  namespaced: true,
  state: {
    sitecode: '110',
    loading: {
      getEvents: false,
      delTermin: false,
      saveTermin: false,
      saveSettings: false,
    },
    saving: false,
    offline: false,
    offlineHideAlert: false,
    store: {
      v: 0,
      kalender: {
        events: [],
        kategorien: [],
        stats: {
          allowEditKategorien: [],
        },
      },
      settings: {
        standard_id_kategorie: 0,
      },
    },
    events: [],
  },
  getters: {
  },
  mutations: {
    offlineHideAlert(state) {
      state.offlineHideAlert = true;
    },
    setEvents(state, events) {
      const wochentage = [
        'montags',
        'dienstags',
        'mittwochs',
        'donnerstags',
        'freitags',
        'samstags',
        'sonntags',
      ];
      const help = [];
      events.forEach((e) => {
        if (!e.istSerie) {
          help.push(e);
        } else {
          const tag = new Date(e.repeat.von);
          tag.setHours(0);
          let iTag = 0;
          while (tag.getTime() <= new Date(e.repeat.bis).getTime()) {
            let ausnahme = false;
            e.repeat.ausnahmen.forEach((a) => {
              const an = a.split('|');
              /* console.log(tag);
              console.log(new Date(parseInt(an[1], 10)));
              console.log(new Date(parseInt(an[2], 10)));
              console.log('----------------------------------------'); */
              if (
                (
                  tag.getTime() >= parseInt(an[1], 10)
                  && tag.getTime() <= parseInt(an[2], 10)
                )
              ) {
                ausnahme = true;
              }
            });
            if (e.repeat.mustertyp === 'Tage') {
              if (
                iTag % e.repeat.musterintervall === 0
                && !ausnahme
              ) {
                const neuSerieneintrag = JSON.parse(JSON.stringify(e));
                const start = new Date(neuSerieneintrag.start);
                start.setDate(start.getDate() + iTag);
                neuSerieneintrag.start = start.getTime();
                const end = new Date(neuSerieneintrag.end);
                end.setDate(end.getDate() + iTag);
                neuSerieneintrag.end = end.getTime();
                help.push(neuSerieneintrag);
              }
              iTag += 1;
              tag.setDate(tag.getDate() + 1);
            } else if (e.repeat.mustertyp === 'Wochen') {
              if (
                (
                  iTag % (e.repeat.musterintervall * 7) < 7
                  && e.repeat.woechentlichWochentage[wochentage[tag.getDay() - 1]]
                  && !ausnahme
                )
              ) {
                // console.log(tag);
                const neuSerieneintrag = JSON.parse(JSON.stringify(e));
                const start = new Date(neuSerieneintrag.start);
                start.setDate(start.getDate() + iTag);
                neuSerieneintrag.start = start.getTime();
                const end = new Date(neuSerieneintrag.end);
                end.setDate(end.getDate() + iTag);
                neuSerieneintrag.end = end.getTime();
                help.push(neuSerieneintrag);
              }
              iTag += 1;
              tag.setDate(tag.getDate() + 1);
            }
          }
        }
      });
      state.events = help;
    },
    setLoading(state, { name, value }) {
      state.loading[name] = value;
    },
  },
  actions: {
    async tryGetOrSaveSettings({ state }, { dataToSave, router }) {
      let help = {};
      // console.log('dataToSave', dataToSave);
      this.commit('f110/setLoading', { name: 'getEvents', value: true });
      if (dataToSave) {
        state.saving = true;
        this.commit('main/switchIsSaving', true);
        if (dataToSave.type === 'delTermin') {
          this.commit('f110/setLoading', { name: 'delTermin', value: true });
        }
        if (dataToSave.type === 'saveTermin') {
          this.commit('f110/setLoading', { name: 'saveTermin', value: true });
        }
        if (dataToSave.type === 'saveSettings') {
          this.commit('f110/setLoading', { name: 'saveSettings', value: true });
        }
      } else if (localStorage.getItem(`f${state.sitecode}`)) {
        help = JSON.parse(localStorage.getItem(`f${state.sitecode}`));
      }
      await $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/100/${state.sitecode}/?getOrSaveSettings`,
        type: 'post',
        data: {
          code: this.state.login.session.code,
          dataToSave: JSON.stringify(dataToSave),
        },
        success: (response) => {
          if (response.status === 'accessError') {
            this.commit('login/backendErrorSession');
          } else {
            /* if (dataToSave) {
              this.commit('main/alert', {
                typ: 'success',
                text: 'Speichern erfolgreich.',
              }, { root: true });
            } */
            state.offline = false;
            // console.log('response', response);
            help.xtime = response.xtime;
            help.settings = response.settings;
            help.kalender = response.kalender;
            localStorage.setItem(`f${state.sitecode}`, JSON.stringify(help));
            state.store.v += 1;
            if (dataToSave) {
              if (dataToSave.type === 'saveTermin' && 'saveTerminNeuId' in response) {
                router.push({ path: `/110?e=${response.saveTerminNeuId}` });
              }
              if (dataToSave.type === 'delTermin') {
                router.push({ path: '/110' });
              }
            }
          }
        },
        timeout: 10000,
        error: () => {
          state.offline = true;
          if (dataToSave) {
            this.commit('main/alert', {
              typ: 'error',
              text: 'Fehler! Bitte die Netzwerkverbindung prüfen.',
            }, { root: true });
          }
        },
        complete: () => {
          this.commit('f110/setEvents', help.kalender.events);
          state.store = help;
          this.commit('main/switchIsSaving', false);
          this.commit('f110/setLoading', { name: 'getEvents', value: false });
          this.commit('f110/setLoading', { name: 'delTermin', value: false });
          this.commit('f110/setLoading', { name: 'saveTermin', value: false });
          this.commit('f110/setLoading', { name: 'saveSettings', value: false });
        },
      });
    },
  },
};
