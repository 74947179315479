export default {
  namespaced: true,
  state: {
    sitecode: '200',
    loading: {
      getData: false,
      createKarte: false,
      deleteKarte: false,
      getAllKarte: false,
    },
    saving: false,
    offline: false,
    offlineHideAlert: false,
    store: {
      v: 0,
      settings: {
      },
    },
  },
  getters: {
  },
  mutations: {
    offlineHideAlert(state) {
      state.offlineHideAlert = true;
    },
    setLoading(state, { name, value }) {
      state.loading[name] = value;
    },
    setStateStore(state, { name, val }) {
      state.store[name] = val;
    },
    deleteKarte(state, idKind) {
      if (
        state.store.kinder
          .filter((o) => o.id === String(idKind))
          .length > 0
      ) {
        state.store.kinder
          .filter((o) => o.id === idKind)[0]
          .dokumente.forEach((dok, idx) => {
            if (dok.id === 'IDCard') {
              state.store.kinder
                .filter((o) => o.id === idKind)[0]
                .dokumente.splice(idx, 1);
            }
          });
      }
    },
    addKarte(state, { idKind, karte }) {
      if (
        state.store.kinder
          .filter((o) => o.id === String(idKind))
          .length > 0
      ) {
        state.store.kinder
          .filter((o) => o.id === idKind)[0]
          .dokumente.push(karte);
      }
    },
  },
  actions: {
    async tryPostAPI({ state, commit }, {
      url, func, data,
      router,
    }) {
      commit('setLoading', { name: func, value: true });
      const body = new FormData();
      body.append('code', this.state.login.session.code);
      if (Object.keys(data).length > 0) {
        // console.log(data);
        Object.keys(data).forEach((k) => {
          let value = data[k];
          if (Array.isArray(value)) {
            value = JSON.stringify(value);
          }
          if (Object.is(value)) {
            value = JSON.stringify(value);
          }
          body.append(k, value);
        });
      }
      if (router) {
        console.log(router);
      }
      let help = {};
      if (localStorage.getItem(`f${state.sitecode}-${url}?${func}`)) {
        help = JSON.parse(localStorage.getItem(`f${state.sitecode}-${func}`));
      }
      await fetch(`${process.env.VUE_APP_API_HOST}/${url}?${func}`, {
        method: 'POST',
        body,
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.status === 'accessError') {
            this.commit('login/backendErrorSession');
          } else if (func === 'getData') {
            if (response.save) {
              help = response.save;
            }
            localStorage.setItem(`f${state.sitecode}-${url}?${func}`, JSON.stringify(help));
          } else if (func === 'getAllKarte') {
            if (response.count > 0) {
              commit('setLoading', { name: func, value: true });
              this.dispatch('main/tryDownload', {
                path: response.path,
                name: 'Karten.pdf',
                newTab: true,
              }).then(() => {
                setTimeout(() => {
                  commit('setLoading', { name: func, value: false });
                }, 1500);
              });
            } else {
              commit('setLoading', { name: func, value: false });
              this.commit('main/alert', {
                typ: 'error',
                text: 'Keine Karten gefunden. Bitte die Auswahl überprüfen',
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.commit('main/alert', {
            typ: 'error',
            text: 'Bitte die Netzwerkverbindung prüfen.',
          });
        })
        .finally(() => {
          if (func !== 'getAllKarte') {
            commit('setLoading', { name: func, value: false });
          }
          if (func === 'getData') {
            localStorage.setItem(`f${state.sitecode}-${url}?${func}`, JSON.stringify(help));
            // console.log(help);
            Object.keys(help).forEach((k) => {
              commit('setStateStore', { name: k, val: help[k] });
            });
          }
        });
    },
  },
};
