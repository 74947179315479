var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"text-align":"center"}},[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('p',{staticClass:"text-title text--secondary",attrs:{"align":"left"}},[_vm._v(" Kontoeinstellungen ")]),_c('v-text-field',{staticClass:"mb-4",attrs:{"disabled":"","label":"Nutzername","prepend-icon":"mdi-account","rules":[
        _vm.$store.state.main.inputValidationRules.required,
        _vm.$store.state.main.inputValidationRules.name ]},model:{value:(_vm.user.nn),callback:function ($$v) {_vm.$set(_vm.user, "nn", $$v)},expression:"user.nn"}}),_c('p',{staticClass:"text-title text--secondary mb-0 pb-0",attrs:{"align":"left"}},[_vm._v(" Passwort ändern ")]),_c('v-text-field',{attrs:{"type":"password","label":"Altes Passwort","prepend-icon":"mdi-key","rules":[
        _vm.$store.state.main.inputValidationRules.required,
        _vm.$store.state.main.inputValidationRules.pw ]},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.tryChangePW();}},model:{value:(_vm.userPWalt),callback:function ($$v) {_vm.userPWalt=$$v},expression:"userPWalt"}}),_c('v-text-field',{attrs:{"type":"password","label":"Neues Passwort","prepend-icon":"mdi-key","rules":[
        _vm.$store.state.main.inputValidationRules.required,
        _vm.$store.state.main.inputValidationRules.pw,
        _vm.$store.state.main.inputValidationRules.minPW ]},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.tryChangePW();}},model:{value:(_vm.userPW),callback:function ($$v) {_vm.userPW=$$v},expression:"userPW"}}),_c('v-text-field',{attrs:{"type":"password","label":"Neues Passwort wiederholen","prepend-icon":"mdi-key","rules":[
        _vm.$store.state.main.inputValidationRules.required,
        _vm.$store.state.main.inputValidationRules.pw ]},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.tryChangePW();}},model:{value:(_vm.userPW2),callback:function ($$v) {_vm.userPW2=$$v},expression:"userPW2"}})],1),(_vm.valid && _vm.PWvalid)?_c('v-btn',{staticClass:"my-2",attrs:{"small":"","color":"success","loading":_vm.isSaving},on:{"click":function($event){return _vm.tryChangePW();}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v(" Passwort ändern ")],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }