<template>
  <v-container fluid class="ma-0 pa-0 d-flex justify-center">
    <v-container class="ma-0 pa-0">
      <EditKind
        v-if="dialog_editKind.show"
        :prop_editKind="dialog_editKind"
        :prop_klassen="prop_klassen"
        :prop_kinder="prop_kinder"
        :prop_elternkonto="prop_eltern.filter((o) => o.id === dialog_editKind.kind.id_eltern)[0]"
        @editKind="editKind($event)"
        @delKind="delKind($event)"
      />
      <v-row class="ma-0">
        <v-col class="pa-2">
          <v-dialog
            v-model="dialog_verwaltungseinstellungen"
            width="500"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                class="ma-1"
              >
                <v-icon>mdi-cogs</v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-card-title>
                <b>Einstellungen</b>
              </v-card-title>

              <v-card-text>
                <v-row>
                  <v-col>
                    <v-select
                      multiple
                      label="Klassen filtern"
                      :items="save.klassen"
                      v-model="filterKlassen"
                      item-text="name"
                      item-value="id"
                      class="vSelectHideInput"
                    >
                      <template v-slot:selection="data">
                        <span
                          v-if="data.index === 0"
                        >
                          <span
                            class="grey--text text-caption"
                            v-for="(k, idx) in filterKlassenSort"
                            :key="idx"
                          >
                            <v-chip x-small
                              close
                              :input-value="k"
                              @click:close="
                                removeAngezeigteKlasse(prop_klassen.filter((o) => o.id === k)[0].id)
                              "
                            >{{ prop_klassen.filter((o) => o.id === k)[0].name }}</v-chip>
                          </span>
                        </span>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="3" align="left">
                    <v-btn x-small
                      @click="filterKlassenAlle();"
                      :disabled="filterKlassen.length === save.klassen.length"
                    ><v-icon>mdi-set-all</v-icon></v-btn>
                    <br/>
                    <v-btn x-small
                      @click="filterKlassenKeine();"
                      :disabled="filterKlassen.length <= 0"
                    ><v-icon>mdi-set-none</v-icon></v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-checkbox
                      v-model="filterKlassenShowQuickselect"
                      label="Schnellauswahl zum Filtern von Klassen nutzen"
                    />
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col>
                    <v-select
                      multiple
                      label="Entlasszeiten filtern"
                      :items="alleEntlasszeiten"
                      v-model="filterAlleEntlasszeiten"
                      item-text="name"
                      item-value="id"
                    >
                      <template v-slot:selection="data">
                        <span
                          v-if="data.index === 0"
                        >
                          <span
                            class="grey--text text-caption"
                            v-for="(k, idx) in filterAlleEntlasszeitenSort"
                            :key="idx"
                          >
                            <v-chip x-small
                              close
                              :input-value="k"
                              @click:close="
                                removefilterAlleEntlasszeiten(k)
                              "
                            >{{ k }}</v-chip>
                          </span>
                        </span>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="3" align="left">
                    <v-btn x-small
                      @click="filterAlleEntlasszeitenAlle();"
                      :disabled="filterAlleEntlasszeiten.length === alleEntlasszeiten.length"
                    ><v-icon>mdi-set-all</v-icon></v-btn>
                    <br/>
                    <v-btn x-small
                      @click="filterAlleEntlasszeitenKeine();"
                      :disabled="filterAlleEntlasszeiten.length <= 0"
                    ><v-icon>mdi-set-none</v-icon></v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0">
                    <v-checkbox
                      v-model="nurAngemeldeteZeigen"
                      label="Nur angemeldete zeigen"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0">
                    <v-checkbox
                      v-model="anmeldezeitJetzt"
                      label="Anmeldezeit: Jetzt"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row v-if="!anmeldezeitJetzt">
                  <v-col class="py-0">
                    <TimePicker
                      v-model="anmeldezeit"
                      label="Anmeldezeit"
                      icon="mdi-clock-time-four"
                    />
                  </v-col>
                </v-row>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  @click="dialog_verwaltungseinstellungen = false"
                >
                  OK
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn
            v-if="this.$store.state.main.save.user.rechte.includes('x|200|000|010')"
            small
            color="primary"
            @click="showEditKind(0);"
          >
            <v-icon>mdi-plus-box</v-icon>
          </v-btn>
          <v-dialog
            v-model="dialog_getAllIDCard"
            width="500"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                class="ma-1"
              >
                <v-icon>mdi-card-account-details</v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-card-title>
                <b>Karten</b>
              </v-card-title>

              <v-card-text>
                <v-row>
                  <v-col>
                    <v-select
                      multiple
                      label="Klassen filtern"
                      :items="save.klassen"
                      v-model="filterKlassen"
                      item-text="name"
                      item-value="id"
                      class="vSelectHideInput"
                    >
                      <template v-slot:selection="data">
                        <span
                          v-if="data.index === 0"
                        >
                          <span
                            class="grey--text text-caption"
                            v-for="(k, idx) in filterKlassenSort"
                            :key="idx"
                          >
                            <v-chip x-small
                              close
                              :input-value="k"
                              @click:close="
                                removeAngezeigteKlasse(prop_klassen.filter((o) => o.id === k)[0].id)
                              "
                            >{{ prop_klassen.filter((o) => o.id === k)[0].name }}</v-chip>
                          </span>
                        </span>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="3" align="left">
                    <v-btn x-small
                      @click="filterKlassenAlle();"
                      :disabled="filterKlassen.length === save.klassen.length"
                    ><v-icon>mdi-set-all</v-icon></v-btn>
                    <br/>
                    <v-btn x-small
                      @click="filterKlassenKeine();"
                      :disabled="filterKlassen.length <= 0"
                    ><v-icon>mdi-set-none</v-icon></v-btn>
                  </v-col>
                </v-row>
                <p>
                  Wenn noch keine Karten vorhanden sind, können in den
                  Einstellungen jedes Kindes neue Karten erstellt werden.
                </p>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-btn
                  text small
                  @click="dialog_getAllIDCard = false"
                >
                  Schließen
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text small
                  :loading="$store.state.f200.loading.getAllKarte"
                  @click="
                    $store.dispatch('f200/tryPostAPI',
                      {
                        url: '200/200/createDocument/',
                        func: 'getAllKarte',
                        data: {
                          filterKlassen,
                        },
                      },
                    );
                  "
                >
                  Herunterladen
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row class="ma-0 mt-2" align="center">
        <v-col class="py-0" cols="12">
          <v-text-field
            clearable dense
            label="Suchen..."
            append-icon="mdi-magnify"
            v-model="search"
            :rules="[
              $store.state.main.inputValidationRules.name,
            ]"
            class="ma-0"
          ></v-text-field>
        </v-col>
        <v-col class="py-0" cols="12" v-if="filterKlassenShowQuickselect">
          <v-btn
            v-for="klasse in save.klassen"
            :key="klasse.id"
            x-small
            class="ma-1"
            :color="filterKlassen.includes(klasse.id) ? 'success' : 'grey'"
            @click="filterKlassenSpezifisch(klasse.id)"
          >
            {{ klasse.name }}
          </v-btn>
        </v-col>
        <!-- <v-col class="py-0" cols="12" sm="6">
          <v-row align="center">
            <v-col>
              <v-select
                multiple
                label="Entlasszeiten filter"
                :items="alleEntlasszeiten"
                v-model="filterAlleEntlasszeiten"
                item-text="name"
                item-value="id"
              >
                <template v-slot:selection="data">
                  <span
                    v-if="data.index === 0"
                  >
                    <span
                      class="grey--text text-caption"
                      v-for="(k, idx) in filterAlleEntlasszeitenSort"
                      :key="idx"
                    >
                      <v-chip x-small
                        close
                        :input-value="k"
                        @click:close="
                          removefilterAlleEntlasszeiten(k)
                        "
                      >{{ k }}</v-chip>
                    </span>
                  </span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="3" align="left">
              <v-btn x-small
                @click="filterAlleEntlasszeitenAlle();"
                :disabled="filterAlleEntlasszeiten.length === alleEntlasszeiten.length"
              ><v-icon>mdi-set-all</v-icon></v-btn>
              <br/>
              <v-btn x-small
                @click="filterAlleEntlasszeitenKeine();"
                :disabled="filterAlleEntlasszeiten.length <= 0"
              ><v-icon>mdi-set-none</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-col> -->
      </v-row>
      <v-data-table
        :headers="headers"
        :items="save.kinder
          .filter((o) => filterKlassen.includes(o.id_klasse))
          .filter((o) => !this.nurAngemeldeteZeigen || o.anwesenheitsstatus === 1)
          .filter((o) => this.filterAlleEntlasszeiten.includes(o.anwesendHeuteBis))
        "
        :search="search"
        :loading="loading"
        :items-per-page="20"
        :header-props="headerProps"
        :footer-props="footerProps"
        no-data-text="Keine Kinder gefunden"
        no-results-text="Keine Kinder gefunden"
        loading-text="Lade Daten... Bitte warten"
        :mobile-breakpoint="dataTableMobileBreakpoint"
      >
        <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
          {{ pageStart }} - {{ pageStop }} von {{ itemsLength }}
        </template>
        <template v-slot:item="{ item }">
          <tr width="100%" v-if="$vuetify.breakpoint.width > dataTableMobileBreakpoint">
            <td v-for="head in headers.filter((o) => {
              if (
                (
                  (
                    o.value !== 'vorname'
                    && o.value !== 'name_klasse'
                  )
                  || $vuetify.breakpoint.width > dataTableMobileBreakpoint
                )
              ) {
                return o;
              }
              return null;
            })" :key="head.value">
              <div v-if="head.value === 'nachname' || head.value === 'vorname'">
                <v-btn
                  text color="primary"
                  @click="showEditKind(item.id);"
                >
                  {{ item[head.value] }}
                </v-btn>
              </div>
              <div v-else-if="head.value === 'anwesenheitsstatus'">
                <KinderverwaltungSwitchAnwesenheit
                  :item="item"
                  @changeAnwesenheitStatus="changeAnwesenheitStatus($event)"
                />
              </div>
              <div v-else-if="head.value === 'anwesendHeuteBis'">
                <KinderverwaltungAnwesendHeuteBis
                  :item="item"
                  :tagHeute="tagHeute"
                />
              </div>
              <div v-else>
                {{ item[head.value] }}
              </div>
            </td>
          </tr>
          <div
            v-else
            class="pa-1"
          >
            <v-btn
              text color="primary"
              @click="showEditKind(item.id);"
              :small="$vuetify.breakpoint.width <= dataTableMobileBreakpoint"
            >
              {{ item.nachname }}
            </v-btn>
            <span v-if="$vuetify.breakpoint.width <= dataTableMobileBreakpoint">,</span>
            <v-btn
              v-if="$vuetify.breakpoint.width <= dataTableMobileBreakpoint"
              text color="primary"
              @click="showEditKind(item.id);"
              :small="$vuetify.breakpoint.width <= dataTableMobileBreakpoint"
            >
              {{ item.vorname }} ({{ item.name_klasse }})
            </v-btn>
            <div align="right" class="pa-2">
              <KinderverwaltungAnwesendHeuteBis
                :item="item"
                :tagHeute="tagHeute"
              />
              <KinderverwaltungSwitchAnwesenheit
                :item="item"
                @changeAnwesenheitStatus="changeAnwesenheitStatus($event)"
              />
            </div>
            <hr style="border:solid #eeeeee 1px;" />
          </div>
        </template>
        <!-- <template v-slot:item.anwesenheitsstatus="{ item }">
          <KinderverwaltungSwitchAnwesenheit
            :item="item"
            @changeAnwesenheitStatus="changeAnwesenheitStatus($event)"
          />
        </template>
        <template v-slot:item.nachname="{ item }">
          <v-btn
            text
            color="primary"
            @click="showEditKind(item.id);"
          >
            {{ item.nachname }}
          </v-btn>
        </template>
        <template v-slot:item.vorname="{ item }">
          <v-btn
            text
            color="primary"
            @click="showEditKind(item.id);"
          >
            {{ item.vorname }}
          </v-btn>
        </template>
        <template v-slot:item.name_klasse="{ item }">
          <v-btn
            text
            style="pointer-events:none;"
          >
            {{ item.name_klasse }}
          </v-btn>
        </template>
        <template v-slot:item.anwesendHeuteBis="{ item }">
          <KinderverwaltungAnwesendHeuteBis
            :item="item"
            :tagHeute="tagHeute"
          />
        </template> -->
      </v-data-table>
    </v-container>
  </v-container>
</template>

<script>
import TimePicker from '@/components/Inputs/TimePicker.vue';
import EditKind from '@/components/Funktionen/200/200/EditKind.vue';
import KinderverwaltungSwitchAnwesenheit from '@/components/Funktionen/200/200/KinderverwaltungSwitchAnwesenheit.vue';
import KinderverwaltungAnwesendHeuteBis from '@/components/Funktionen/200/200/KinderverwaltungAnwesendHeuteBis.vue';

export default {
  components: {
    TimePicker,
    EditKind,
    KinderverwaltungSwitchAnwesenheit,
    KinderverwaltungAnwesendHeuteBis,
  },
  props: {
    offline: {
      type: Boolean,
      default: null,
    },
    prop_klassen: {
      type: Array,
      default() { return []; },
    },
    prop_settings: {
      type: Object,
      default() { return {}; },
    },
    prop_kinder: {
      type: Array,
      default() { return {}; },
    },
    prop_eltern: {
      type: Array,
      default() { return {}; },
    },
    prop_kindUpdated: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialog_getAllIDCard: false,
    tagHeute: '',
    dialog_editKind: {
      show: false,
      id: 0,
      kind: null,
    },
    xLoaded: {},
    xChanged: {},
    xDeleted: {},
    save: {
      klassen: [],
      settings: {},
      kinder: [],
    },
    headers: [
      { text: 'Nachname', value: 'nachname' },
      { text: 'Vorname', value: 'vorname' },
      { text: 'Klasse', value: 'name_klasse' },
      { text: 'Status', value: 'anwesenheitsstatus' },
      { text: 'Entlasszeit', value: 'anwesendHeuteBis' },
    ],
    search: '',
    loading: false,
    dialog_verwaltungseinstellungen: false,
    filterKlassenShowQuickselect: true,
    filterKlassen: [],
    filterAlleEntlasszeiten: [],
    nurAngemeldeteZeigen: false,
    anmeldezeitJetzt: true,
    anmeldezeit: '08:00',
    nurEntlassenRueckgaengig: false,
    headerProps: {
      sortByText: 'Sortiere nach',
    },
    footerProps: {
      itemsPerPageText: 'Elemente pro Seite:',
      itemsPerPageOptions: [5, 10, 20, 40, -1],
      itemsPerPageAllText: 'Alle',
    },
    dataTableMobileBreakpoint: 700,
  }),
  computed: {
    filterKlassenSort() {
      const help = [];
      const alleKlassen = JSON.parse(JSON.stringify(this.save.klassen));
      alleKlassen.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (b.name > a.name) {
          return -1;
        }
        return 0;
      });
      alleKlassen.forEach((k) => {
        if (this.filterKlassen.includes(k.id)) {
          help.push(k.id);
        }
      });
      return help;
    },
    filterAlleEntlasszeitenSort() {
      const neuFilterAlleEntlasszeiten = JSON.parse(JSON.stringify(this.filterAlleEntlasszeiten));
      neuFilterAlleEntlasszeiten.sort();
      return neuFilterAlleEntlasszeiten;
    },
    alleEntlasszeiten() {
      const entlasszeiten = [];
      this.save.kinder.forEach((e) => {
        if (!entlasszeiten.includes(e.anwesendHeuteBis)) {
          entlasszeiten.push(e.anwesendHeuteBis);
        }
      });
      entlasszeiten.sort();
      return entlasszeiten;
    },
  },
  watch: {
    offline() {
      this.save.kinder.forEach((k) => {
        k.anwesenheitsstatusLoading = false;
      });
    },
    filterKlassen(neu) {
      localStorage.setItem('f200_filterClass', JSON.stringify(neu));
    },
    filterKlassenShowQuickselect(neu) {
      localStorage.setItem('f200_filterClassQuickselect', JSON.stringify(neu));
    },
    /* filterAlleEntlasszeiten(neu) {
      localStorage.setItem('f200_filterEntlasszeiten', JSON.stringify(neu));
    }, */
    nurAngemeldeteZeigen(neu) {
      localStorage.setItem('f200_timeAnm', JSON.stringify([this.anmeldezeitJetzt, this.anmeldezeit, neu]));
    },
    anmeldezeitJetzt(neu) {
      localStorage.setItem('f200_timeAnm', JSON.stringify([neu, this.anmeldezeit, this.nurAngemeldeteZeigen]));
    },
    anmeldezeit(neu) {
      localStorage.setItem('f200_timeAnm', JSON.stringify([this.anmeldezeitJetzt, neu, this.nurAngemeldeteZeigen]));
    },
    prop_klassen: {
      handler() {
        this.datenLaden();
      },
      deep: true,
    },
    prop_settings: {
      handler() {
        this.datenLaden();
      },
      deep: true,
    },
    prop_kinder: {
      handler() {
        this.datenLaden();
      },
      deep: true,
    },
    prop_kindUpdated(neu) {
      if (neu === true) {
        setTimeout(() => {
          this.dialog_editKind.show = false;
        }, 10);
      }
    },
    save: {
      handler() {
        /*
          Wenn ein Datenobjekt lokal (und nicht durch Laden) verändert wurde
        */
        if (JSON.stringify(this.save.kinder) !== JSON.stringify(this.xLoaded.kinder)) {
          this.xChanged.kinder = true;
        } else {
          this.xChanged.kinder = false;
        }
      },
      deep: true,
    },
    dialog_editKind: {
      handler(neu) {
        if (!neu.show && neu.id > 0) {
          neu.id = 0;
          neu.kind = null;
        }
      },
      deep: true,
    },
  },
  methods: {
    filterKlassenAlle() {
      this.filterKlassen = [];
      this.save.klassen.forEach((k) => {
        this.filterKlassen.push(k.id);
      });
    },
    filterKlassenKeine() {
      this.filterKlassen = [];
    },
    filterKlassenSpezifisch(id) {
      if (this.filterKlassen.includes(id)) {
        this.filterKlassen.splice(this.filterKlassen.indexOf(id), 1);
      } else {
        this.filterKlassen.push(id);
      }
    },
    removeAngezeigteKlasse(id) {
      this.filterKlassen
        .splice(this.filterKlassen.indexOf(this.filterKlassen.filter((o) => o === id)[0]), 1);
    },
    filterAlleEntlasszeitenAlle() {
      this.filterAlleEntlasszeiten = [];
      this.alleEntlasszeiten.forEach((k) => {
        this.filterAlleEntlasszeiten.push(k);
      });
    },
    filterAlleEntlasszeitenKeine() {
      this.filterAlleEntlasszeiten = [];
    },
    removefilterAlleEntlasszeiten(k) {
      this.filterAlleEntlasszeiten.splice(this.filterAlleEntlasszeiten.indexOf(k), 1);
    },
    datenLaden() {
      /*
        Wenn neue Daten übergeben wurden,
        prüfe ob diese sich von den zuletzt geladenen unterscheiden.
        Wenn ja, überschreibe die aktuellen Daten mit den neu übergebenen Daten.
      */
      let anythingUpdated = false;
      if (JSON.stringify(this.prop_klassen) !== JSON.stringify(this.xLoaded.klassen)) {
        this.save.klassen = JSON.parse(JSON.stringify(this.prop_klassen));
        this.xLoaded.klassen = JSON.parse(JSON.stringify(this.save.klassen));
        anythingUpdated = true;
      }
      if (JSON.stringify(this.prop_settings) !== JSON.stringify(this.xLoaded.settings)) {
        this.save.settings = JSON.parse(JSON.stringify(this.prop_settings));
        this.xLoaded.settings = JSON.parse(JSON.stringify(this.save.settings));
        anythingUpdated = true;
      }
      if (JSON.stringify(this.prop_kinder) !== JSON.stringify(this.xLoaded.kinder)) {
        this.save.kinder = JSON.parse(JSON.stringify(this.prop_kinder));
        this.xLoaded.kinder = JSON.parse(JSON.stringify(this.save.kinder));
        anythingUpdated = true;
      }
      if (anythingUpdated) {
        this.expansionPanelStatusKlassen = null;
      }
      this.filterAlleEntlasszeitenAlle();
    },
    changeAnwesenheitStatus(kind) {
      // console.log(kind);
      let vAnmeldezeit;
      if (this.anmeldezeitJetzt) {
        vAnmeldezeit = '[[JETZT]]';
      } else {
        vAnmeldezeit = this.anmeldezeit;
      }
      // PRÜFE BEDINGUNGEN:
      const bedingungen = this.prop_klassen
        .filter((o) => o.id === kind.id_klasse)[0]
        .bedingungen.f200;
      if (
        !bedingungen.bearbeiten.alle
        && !bedingungen.bearbeiten.gruppen
          .filter((o) => this.$store.state.main.save.user.gruppen
            .includes(o)).length > 0
      ) {
        // Wenn bearbeiten nicht erlaubt:
      } else {
        kind.anwesenheitsstatusLoading = true;
        const entlassgrund = {
          typ: 'Standardentlasszeit',
          grund: '',
        };
        switch (kind.anwesenheitsstatus) {
          case 0: // Abwesend zu Anwesend
            this.trySaveSettings({
              hideLoadingAnimation: true,
              type: 'anwesenheitsstatus',
              data: {
                id_kind: kind.id,
                neuStatus: 1,
                neuZeit_von: vAnmeldezeit,
              },
            });
            break;
          case 3: // Angemeldet zu Anwesend
            this.trySaveSettings({
              hideLoadingAnimation: true,
              type: 'anwesenheitsstatus',
              data: {
                id_kind: kind.id,
                neuStatus: 1,
                neuZeit_von: vAnmeldezeit,
              },
            });
            break;
          case 1: // Anwesend zu Entlassen
            if (kind.anwesenheitHeute.entlassgrundAusnahme !== '') {
              entlassgrund.typ = 'Ausnahme';
              entlassgrund.grund = kind.anwesenheitHeute.entlassgrundAusnahme;
            } else if (kind.anwesenheitHeute.entlassgrundRegelausnahme !== '') {
              entlassgrund.typ = 'Regelausnahme';
              entlassgrund.grund = kind.anwesenheitHeute.entlassgrundRegelausnahme;
            }
            this.trySaveSettings({
              hideLoadingAnimation: true,
              type: 'anwesenheitsstatus',
              data: {
                id_kind: kind.id,
                neuStatus: 2,
                neuZeit_bis: kind.anwesendHeuteBis,
                entlassgrund: `${entlassgrund.typ}, ${entlassgrund.grund}`,
                vorname: kind.vorname,
                nachname: kind.nachname,
                klasse: kind.name_klasse,
                idKlasse: kind.id_klasse,
              },
            });
            break;
          case 2: // Entlassen zu Abwesend
            this.trySaveSettings({
              hideLoadingAnimation: true,
              type: 'anwesenheitsstatus',
              data: {
                id_kind: kind.id,
                neuStatus: 0,
                nurEntlassenRueckgaengig: kind.nurEntlassenRueckgaengig,
              },
            });
            break;
          default:
            break;
        }
        /* if (kind.anwesenheitsstatus === 0) { // Abwesend zu Anwesend
          this.trySaveSettings({
            hideLoadingAnimation: true,
            type: 'anwesenheitsstatus',
            data: {
              id_kind: kind.id,
              neuStatus: 1,
              neuZeit_von: vAnmeldezeit,
            },
          });
        } else if (kind.anwesenheitsstatus === 3) { // Angemeldet zu Anwesend
          this.trySaveSettings({
            hideLoadingAnimation: true,
            type: 'anwesenheitsstatus',
            data: {
              id_kind: kind.id,
              neuStatus: 1,
              neuZeit_von: vAnmeldezeit,
            },
          });
        } else if (kind.anwesenheitsstatus === 1) { // Anwesend zu Entlassen
          const entlassgrund = {
            typ: 'Standardentlasszeit',
            grund: '',
          };
          if (kind.anwesenheitHeute.entlassgrundAusnahme !== '') {
            entlassgrund.typ = 'Ausnahme';
            entlassgrund.grund = kind.anwesenheitHeute.entlassgrundAusnahme;
          } else if (kind.anwesenheitHeute.entlassgrundRegelausnahme !== '') {
            entlassgrund.typ = 'Regelausnahme';
            entlassgrund.grund = kind.anwesenheitHeute.entlassgrundRegelausnahme;
          }
          this.trySaveSettings({
            hideLoadingAnimation: true,
            type: 'anwesenheitsstatus',
            data: {
              id_kind: kind.id,
              neuStatus: 2,
              neuZeit_bis: kind.anwesendHeuteBis,
              entlassgrund: `${entlassgrund.typ}: ${entlassgrund.grund}`,
            },
          });
        } else if (kind.anwesenheitsstatus === 2) { // Entlassen zu Abwesend
          this.trySaveSettings({
            hideLoadingAnimation: true,
            type: 'anwesenheitsstatus',
            data: {
              id_kind: kind.id,
              neuStatus: 0,
              nurEntlassenRueckgaengig: this.nurEntlassenRueckgaengig,
            },
          });
        } */
      }
    },
    editKind(data) {
      this.trySaveSettings({
        hideLoadingAnimation: false,
        type: 'editKind',
        data,
      });
    },
    delKind(data) {
      this.trySaveSettings({
        hideLoadingAnimation: false,
        type: 'delKind',
        data,
      });
    },
    showEditKind(id) {
      this.dialog_editKind.id = id;
      this.dialog_editKind.show = true;
      this.dialog_editKind.allowEdit = true;
      if (id > 0) {
        [this.dialog_editKind.kind] = JSON.parse(
          JSON.stringify(
            this.prop_kinder.filter((o) => o.id === id),
          ),
        );
        // PRÜFE BEDINGUNGEN:
        const bedingungen = this.prop_klassen
          .filter((o) => o.id === this.dialog_editKind.kind.id_klasse)[0]
          .bedingungen.f200;
        if (
          bedingungen.bearbeiten.alle
          || bedingungen.bearbeiten.gruppen
            .filter((o) => this.$store.state.main.save.user.gruppen
              .includes(o)).length > 0
        ) {
          this.dialog_editKind.allowEdit = true;
        } else {
          this.dialog_editKind.allowEdit = false;
        }
      } else {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
        const yyyy = today.getFullYear();
        // const H = today.getHours();
        // const i = today.getMinutes();
        this.dialog_editKind.kind = {
          id: 0,
          id_elternkonto: '0',
          id_klasse: '0',
          name_klasse: '',
          name: '',
          vorname: '',
          nachname: '',
          junge: true,
          standardentlasszeit: this.prop_settings.standardentlasszeit,
          betreuungsplatz: this.prop_settings.standardbetreuungsplatz,
          regelausnahmen: [],
          ausnahmen: [],
          anwesenheitsstatus: 0,
          anwesenheitsstatusLoading: false,
          anwesendHeuteBis: '15:00',
          anwesenheitHeute: {
            id: '',
            tag: `${yyyy}-${mm}-${dd}`,
            zeit_von: '',
            time_angemeldet: '',
            zeit_bis: '',
            time_abgemeldet: '',
            entlassgrund: 'Standardentlasszeit',
            entlassgrundRegelausnahme: '',
            entlassgrundAusnahme: '',
            status: 0,
            anwesendHeuteBis: this.prop_settings.standardentlasszeit,
          },
          entlassgrund: 'Standardentlasszeit',
          tagebucheintraege: {},
        };
      }
    },
    async trySaveSettings(dataToSave) {
      if (dataToSave.type === 'anwesenheitsstatus') {
        this.$emit('saveSettings', { dataToSave });
      } else {
        this.$emit('saveSettings', { dataToSave, loaded: this.xLoaded, deleted: this.xDeleted });
      }
    },
  },
  created() {
    // console.log(this.$vuetify.breakpoint);
    const heute = new Date();
    const dd = String(heute.getDate()).padStart(2, '0');
    const mm = String(heute.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = heute.getFullYear();
    this.tagHeute = `${yyyy}-${mm}-${dd}`;
    this.datenLaden();
    if (localStorage.getItem('f200_filterClassQuickselect')) {
      this.filterKlassenShowQuickselect = JSON.parse(localStorage.getItem('f200_filterClassQuickselect'));
    }
    /* if (localStorage.getItem('f200_filterEntlasszeiten')) {
      this.filterAlleEntlasszeiten = JSON.parse(localStorage.getItem('f200_filterEntlasszeiten'));
    } */
    if (!localStorage.getItem('f200_filterClass')) {
      this.save.klassen.forEach((k) => {
        this.filterKlassen.push(k.id);
      });
      localStorage.setItem('f200_filterClass', JSON.stringify(this.filterKlassen));
    } else {
      this.filterKlassen = JSON.parse(localStorage.getItem('f200_filterClass'));
    }
    if (localStorage.getItem('f200_timeAnm')) {
      const loc = JSON.parse(localStorage.getItem('f200_timeAnm'));
      [this.anmeldezeitJetzt, this.anmeldezeit, this.nurAngemeldeteZeigen] = loc;
    }
  },
};
</script>
