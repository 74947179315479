<template>
  <v-app>
    <Mainmenu
    v-if="showNavigation"
    :maxquicknav="maxQuicknav"
    style="z-index:99;"
    />
    <v-main>
      <pushNotifications />
      <transition
        name="custom-classes-transition"
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
      >
        <div style="
          position:fixed;left:0px;top:0px;width:100vw;height:100vh;
          z-index:1000;background-color:rgba(0,0,0,0.6);"
          v-if="$store.state.main.isSaving"
          >
        </div>
      </transition>
      <transition-group
        name="custom-classes-transition"
        enter-active-class="animate__animated animate__fadeInUp"
        leave-active-class="animate__animated animate__fadeOutDown"
      >
        <Alert
          v-for="(alert, idx) in $store.state.main.alerts"
          :key="idx"
          :text="alert.text"
          :index="idx"
          :color="alert.color"
          :timeout="alert.timeout"
        />
      </transition-group>
      <transition
        name="custom-classes-transition"
        enter-active-class="animate__animated animate__fadeInRight"
        leave-active-class="animate__animated animate__fadeOutLeft"
      >
        <router-view
          @alert="showAlert($event)"
        />
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import Mainmenu from '@/components/Mainmenu.vue';
import Alert from '@/components/Alert.vue';
import pushNotifications from '@/components/Inputs/pushNotifications.vue';

export default {
  name: 'App',
  components: {
    Mainmenu,
    Alert,
    pushNotifications,
  },
  data: () => ({
    deviceWidth: window.innerWidth,
    maxQuicknav: 2,
  }),
  computed: {
    showNavigation: { // Databinding mit vuex state
      get() {
        return this.$store.state.main.showNavigation;
      },
      set(value) {
        this.$store.commit('main/mainmenu_switchNav', value);
      },
    },
  },
  beforeCreate() {
    // Lade lokal gespeicherte Einstellungen
    const session = JSON.parse(localStorage.getItem('login_session'));
    const settings = JSON.parse(localStorage.getItem('app_settings'));
    if (session) {
      if (session.code !== '') {
        // Wenn Sitzungsdaten lokal gespeichert wurden,
        // Führe erst einen offline-login durch
        if (this.$router.history.current.path !== '/') {
          // Wenn nicht die Loginseite aufgerufen wurde, überschreibe die
          // letzte View mit dem aufgerufenen Pfad
          session.lastView = this.$router.history.current.path;
        } else {
          this.$router.push({ path: session.lastView });
        }
        this.$store.commit('main/settings_setSettings', { loadedSettings: settings, router: this.$router });
        this.$store.commit('main/mainmenu_switchNav', true);
        this.$store.commit('login/setSession', { code: session.code, lastView: session.lastView });
        // Lade dann nachträglich im Hintergrund aktuelle Daten
        this.$store.dispatch('main/tryGetSettings', { code: session.code, router: this.$router });
      }
    } else if (this.$router.history.current.path !== '/') {
      this.$store.commit('main/mainmenu_switchNav', false);
      localStorage.clear();
      this.$router.push({ path: '/' });
    }
  },
  /* watch: {
    deviceWidth(neu) {
      // Wenn Fenster resize
      console.log(`resized: ${neu}`);
    },
  }, */
  created() {
    this.onResize();
    if (this.$store.state.main.save.settings) {
      document.title = this.$store.state.main.save.settings.app_titel;
      this.$vuetify.theme.themes.light.primary = this
        .$store.state.main.save.settings.app_theme_color_primary;
    } else {
      document.title = 'Login';
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    /*
      async createPDF() {
        // PDF erstellen:
        $.ajax({
          url: `${process.env.VUE_APP_API_HOST}/200/200/?func=createPDF`,
          type: 'post',
          data: {
            code: this.$store.state.login.session.code,
          },
          success: () => {
            // Erstelltes PDF downloaden:
            $.ajax({
              url: `${process.env.VUE_APP_API_HOST}/data/download.php`,
              method: 'POST',
              data: {
                code: this.$store.state.login.session.code,
                file: 'test.pdf',
              },
              xhrFields: {
                responseType: 'blob',
              },
              success: (data) => {
                const a = document.createElement('a');
                const url = window.URL.createObjectURL(data);
                a.href = url;
                a.download = 'myfile.pdf';
                document.body.append(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
              },
            });
          },
        });
      },
    */
    onResize() {
      this.deviceWidth = window.innerWidth;
      let maxQuicknav = 0;
      if (this.deviceWidth < 600) {
        maxQuicknav = 2;
      } else if (this.deviceWidth < 712) {
        maxQuicknav = 3;
      } else if (this.deviceWidth < 960) {
        maxQuicknav = 4;
      } else if (this.deviceWidth < 1264) {
        maxQuicknav = 5;
      } else if (this.deviceWidth < 1904) {
        maxQuicknav = 7;
      } else {
        maxQuicknav = 10;
      }
      maxQuicknav = (this.deviceWidth - 100) / 100;
      this.maxQuicknav = maxQuicknav;
    },
  },
};
</script>

<style>
  html {
    overflow:hidden !important;
  }
  .v-main__wrap {
    /* background-image:url(''); */
  }
  .view {
    position:absolute;
    width:100%;
    height:100%;
    overflow-y:auto;
    overflow-x:hidden;
    padding-top:50px;
  }

  .animate__animated {
    position:fixed !important;
    --animate-duration: 0.4s;
  }

  .animate__animated.animate__animated__slow {
    --animate-duration: 2s;
  }

  .disabled {
    opacity:0.5 !important;
  }
</style>
