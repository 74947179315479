<template>
  <v-dialog
    v-model="showDialog"
    width="800"
  >
    <!-- <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="red lighten-2"
        dark
        v-bind="attrs"
        v-on="on"
      >
        Click Me
      </v-btn>
    </template> -->
    <v-card>
      <v-card-title>
        Übersicht {{ slot.name }} ({{ slot.sort }})
      </v-card-title>

      <v-card-text height="100%">
        <v-data-table
          dense
          :headers="headers"
          :items="anwesenheitenNachNamen.sort((a, b) => a > b ? -1 : 1)"
          item-key="id"
          class="elevation-1"
          :items-per-page="20"
          :header-props="headerProps"
          :footer-props="footerProps"
          no-data-text="Noch keine Anwesenheiten vorhanden"
          no-results-text="Keine Daten gefunden"
          loading-text="Lade Daten... Bitte warten"
        >
          <!-- <template v-slot:item.kannAendern="{ item }">
          </template> -->
        </v-data-table>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="showDialog = false"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  components: {
  },
  props: {
    prop_idslot: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    showDialog: false,
    slot: {},
    headers: [
      // { text: 'ID', value: 'id' },
      { text: 'Name', value: 'name' },
      { text: 'Anwesend', value: 'status1', align: 'center' },
      { text: 'Entschuldigt', value: 'status2', align: 'center' },
      { text: 'Abwesend', value: 'status3', align: 'center' },
    ],
    headerProps: {
      sortByText: 'Sortiere nach',
    },
    footerProps: {
      itemsPerPageText: 'Elemente pro Seite:',
      itemsPerPageOptions: [5, 10, 20, 40, -1],
      itemsPerPageAllText: 'Alle',
    },
  }),
  computed: {
    anwesenheitenNachNamen() {
      const res = [];
      if (this.slot.anwesenheiten) {
        this.slot.anwesenheiten.forEach((a) => {
          a.prueflinge.filter((o) => o.status !== 0).forEach((p) => {
            if (res.filter((o) => o.id === p.id).length <= 0) {
              res.push({
                id: p.id,
                name: p.name,
                status1: 0,
                status2: 0,
                status3: 0,
              });
            }
            const [pruefling] = res.filter((o) => o.id === p.id);
            if (!pruefling[`status${p.status}`]) {
              pruefling[`status${p.status}`] = 1;
            } else {
              pruefling[`status${p.status}`] += 1;
            }
          });
        });
      }
      return res;
    },
  },
  watch: {
    prop_idslot() {
      this.start();
    },
    showDialog(neu) {
      if (neu === false) {
        this.$emit('close');
      }
    },
  },
  methods: {
    start() {
      if (this.prop_idslot > 0) {
        [this.slot] = this.$store.state.f150.store.slots.filter((o) => o.id === this.prop_idslot);
        this.showDialog = true;
      } else {
        this.showDialog = false;
      }
    },
  },
  created() {
    this.start();
  },
};
</script>
