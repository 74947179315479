<template>
  <div>
    <v-row class="my-0 py-0">
      <v-col cols="12" sm="6" class="my-0 py-0">
        <DatePicker
          label="Serienbeginn"
          v-model="value.von"
          :rules="[$store.state.main.inputValidationRules.required]"
        />
      </v-col>
      <v-col cols="12" sm="6" class="my-0 py-0">
        <DatePicker
          label="Serienende"
          v-model="value.bis"
          :rules="[$store.state.main.inputValidationRules.required]"
        />
      </v-col>
    </v-row>
    <v-row class="my-0 py-0">
      <v-col cols="6" sm="3" class="my-0 py-0">
        <v-row align="center">
          <v-col cols="5">
            Alle
          </v-col>
          <v-col>
            <v-text-field
              v-model="value.musterintervall"
              label=""
              type="number"
              :rules="[$store.state.main.inputValidationRules.required]"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6" sm="3" class="my-0 py-0">
        <v-select
          v-model="value.mustertyp"
          :items="['Tage', 'Wochen'/* , 'Monate', 'Jahre' */]"
          :rules="[$store.state.main.inputValidationRules.required]"
        />
      </v-col>
      <v-col cols="12" sm="6" class="my-0 py-0">
        <!-- <DatePicker
          v-if="value.mustertyp === 'jahre'"
          label="Am"
          v-model="value.jaehrlichTag"
          :rules="[$store.state.main.inputValidationRules.required]"
        /> -->
        <v-row align="center" v-if="value.mustertyp === 'Jahre'">
          <v-col cols="3">
            am
          </v-col>
          <v-col>
            <v-text-field
              v-model="value.jaehrlichTag"
              label=""
              type="number"
              :rules="[$store.state.main.inputValidationRules.required]"
            />
          </v-col>
          <v-col cols="1">
            .
          </v-col>
          <v-col>
            <v-text-field
              v-model="value.jaehrlichMonat"
              label=""
              type="number"
              :rules="[$store.state.main.inputValidationRules.required]"
            />
          </v-col>
          <v-col cols="1">
            .
          </v-col>
        </v-row>
        <v-row align="center" v-if="value.mustertyp === 'Monate'">
          <v-col cols="3">
            am
          </v-col>
          <v-col>
            <v-text-field
              v-model="value.monatlichTag"
              label=""
              type="number"
              :rules="[$store.state.main.inputValidationRules.required]"
            />
          </v-col>
          <v-col cols="3">
            . Tag
          </v-col>
        </v-row>
        <v-row v-if="value.mustertyp === 'Wochen'">
          <v-col cols="3">
            <v-checkbox label="MO" v-model="value.woechentlichWochentage.montags"/>
          </v-col>
          <v-col cols="3">
            <v-checkbox label="DI" v-model="value.woechentlichWochentage.dienstags"/>
          </v-col>
          <v-col cols="3">
            <v-checkbox label="MI" v-model="value.woechentlichWochentage.mittwochs"/>
          </v-col>
          <v-col cols="3">
            <v-checkbox label="DO" v-model="value.woechentlichWochentage.donnerstags"/>
          </v-col>
          <v-col cols="3">
            <v-checkbox label="FR" v-model="value.woechentlichWochentage.freitags"/>
          </v-col>
          <v-col cols="3">
            <v-checkbox label="SA" v-model="value.woechentlichWochentage.samstags"/>
          </v-col>
          <v-col cols="3">
            <v-checkbox label="SO" v-model="value.woechentlichWochentage.sonntags"/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-subheader>Ausnahmen:</v-subheader>
        <p>
          Innerhalb der folgenden Zeiträume wird kein Termin der Serie angezeigt:
        </p>
        <v-btn x-small @click="addAusnahme();">
          <v-icon small left>mdi-plus-box</v-icon>Ausnahme hinzufügen
        </v-btn>
        <v-container v-for="(a, idx) in value.ausnahmen"
          :key="idx"
        >
          <v-row class="my-0 py-0 mb-4">
            <v-col cols="12" align="right" class="my-0 py-0">
              <v-btn x-small
                @click="delAusnahme(idx);"
              >
                <v-icon small left>mdi-delete</v-icon>
                löschen
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <DatePicker
                v-model="a.von"
                label="Von"
              />
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <DatePicker
                v-model="a.bis"
                label="Bis"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import $ from 'jquery';
import DatePicker from '@/components/Inputs/DatePicker.vue';

export default {
  components: {
    DatePicker,
  },
  props: {
    value: {
      type: Object,
      default() { return {}; },
    },
  },
  data() {
    return {
    };
  },
  computed: {
  },
  watch: {
  },
  methods: {
    delAusnahme(idx) {
      this.value.ausnahmen.splice(idx, 1);
    },
    addAusnahme() {
      const heute = new Date();
      const jahr = heute.getFullYear();
      const monat = String(heute.getMonth() + 1).padStart(2, '0');
      const tag = String(heute.getDate()).padStart(2, '0');
      this.value.ausnahmen.unshift({
        id: 0,
        von: `${jahr}-${monat}-${tag}`,
        bis: `${jahr}-${monat}-${tag}`,
      });
    },
  },
  created() {
  },
};
</script>
