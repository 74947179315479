var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"inline-block"}},[(_vm.item.entlassgrund.includes('Regelausnahme'))?_c('v-dialog',{attrs:{"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":_vm.item.anwesend ? 'warning' : 'error'}},'v-btn',attrs,false),on),[(_vm.item.anwesend)?_c('span',[_vm._v(" "+_vm._s(_vm.item.anwesendHeuteBis)+" ")]):_c('span',[_vm._v(" NICHT ")])])]}}],null,false,3011199370),model:{value:(_vm.item.anwesenheitHeute.dialog_regelausnahme),callback:function ($$v) {_vm.$set(_vm.item.anwesenheitHeute, "dialog_regelausnahme", $$v)},expression:"item.anwesenheitHeute.dialog_regelausnahme"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.item.vorname)+" "+_vm._s(_vm.item.nachname)+" ("+_vm._s(_vm.item.name_klasse)+") "),_c('br'),_vm._v(" Regelausnahme ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.item.anwesenheitHeute.entlassgrundRegelausnahme)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.item.anwesenheitHeute.dialog_regelausnahme = false}}},[_vm._v(" OK ")])],1)],1)],1):_vm._e(),(
      _vm.item.ausnahmen.filter(function (o) { return o.tag === _vm.tagHeute && o.aktiv === 1; }).length > 0
      && _vm.item.entlassgrund.includes('Ausnahme')
    )?_c('v-dialog',{attrs:{"max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [(
          (
            1 === 1
            /*&& item.ausnahmen.filter((o) => o.tag === tagHeute && o.aktiv === 1)[0]
              .entlasszeit
            !== item.anwesendHeuteBis
            && item.anwesend
            && item.ausnahmen.filter((o) => o.tag === tagHeute && o.aktiv === 1)[0].elternErstellt*/
          )
        )?_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","color":_vm.item.anwesend ? 'warning' : 'error'}},'v-btn',attrs,false),on),[(_vm.item.anwesend)?_c('span',[_vm._v(" "+_vm._s(_vm.item.ausnahmen.filter(function (o) { return o.tag === _vm.tagHeute && o.aktiv === 1; })[0].entlasszeit)+" ")]):_c('span',[_vm._v(" NICHT ")]),(_vm.item.ausnahmen.filter(function (o) { return o.tag === _vm.tagHeute && o.aktiv === 1; })[0].elternErstellt)?_c('span',[_vm._v("  ! ")]):_vm._e()]):_vm._e()]}}],null,false,689255739),model:{value:(_vm.item.anwesenheitHeute.dialog_ausnahme),callback:function ($$v) {_vm.$set(_vm.item.anwesenheitHeute, "dialog_ausnahme", $$v)},expression:"item.anwesenheitHeute.dialog_ausnahme"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.item.vorname)+" "+_vm._s(_vm.item.nachname)+" ("+_vm._s(_vm.item.name_klasse)+") ")]),_c('v-card-text',[(_vm.item.anwesend)?_c('div',[(
              _vm.item.ausnahmen.filter(function (o) { return o.tag === _vm.tagHeute && o.aktiv === 1; })[0]
                .entlasszeit
              !== _vm.item.anwesendHeuteBis
              && _vm.item.ausnahmen.filter(function (o) { return o.tag === _vm.tagHeute && o.aktiv === 1; })[0]
                .elternErstellt
              && _vm.item.anwesenheitsstatus !== 0
            )?_c('v-alert',{attrs:{"type":"error","border":"bottom","colored-border":""}},[_vm._v(" Die Ausnahme wurde am "+_vm._s(_vm.item.ausnahmen.filter(function (o) { return o.tag === _vm.tagHeute && o.aktiv === 1; })[0] .elternErstellt_tag)+" um "+_vm._s(_vm.item.ausnahmen.filter(function (o) { return o.tag === _vm.tagHeute && o.aktiv === 1; })[0] .elternErstellt_zeit)+" Uhr erstellt, nachdem das Kind bereits für "+_vm._s(_vm.item.anwesendHeuteBis)+" Uhr entlassen wurde: ")]):_vm._e(),(
              _vm.item.ausnahmen.filter(function (o) { return o.tag === _vm.tagHeute && o.aktiv === 1; }).length > 0
            )?_c('div',[_vm._v(" Ausnahme Heute bis "+_vm._s(_vm.item.ausnahmen .filter(function (o) { return o.tag === _vm.tagHeute && o.aktiv === 1; })[0].entlasszeit)+" Uhr. ")]):_vm._e(),_c('p',[_vm._v("\""+_vm._s(_vm.item.anwesenheitHeute.entlassgrundAusnahme)+"\"")]),(
              _vm.item.ausnahmen.filter(function (o) { return o.tag === _vm.tagHeute && o.aktiv === 1; })[0]
                .entlasszeit
              !== _vm.item.anwesendHeuteBis
              && _vm.item.ausnahmen.filter(function (o) { return o.tag === _vm.tagHeute && o.aktiv === 1; })[0]
                .elternErstellt
              && _vm.item.anwesenheitsstatus !== 0
            )?_c('v-alert',{attrs:{"type":"warning","border":"bottom","colored-border":""}},[_vm._v(" Um die Ausnahme umzusetzen, stellen Sie das Kind von \"entlassen\" wieder auf \"anwesend\" um. ")]):_vm._e()],1):_vm._e(),(!_vm.item.anwesend)?_c('div',[_vm._v(" Ausnahme Heute nicht anwesend. "),_c('p',[_vm._v("\""+_vm._s(_vm.item.anwesenheitHeute.entlassgrundAusnahme)+"\"")])]):_vm._e()]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.item.anwesenheitHeute.dialog_ausnahme = false}}},[_vm._v(" OK ")])],1)],1)],1):_vm._e(),(_vm.item.entlassgrund.includes('Standardentlasszeit'))?_c('v-btn',{staticStyle:{"pointer-events":"none"},attrs:{"text":""}},[_vm._v(" "+_vm._s(_vm.item.anwesendHeuteBis)+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }