<template>
    <div class="pa-3">
      <p>
        <b>{{ summeNurGepruefte }}</b> von <b>{{ summeAlle }}</b> Hydrant/en wurden geprüft:
        <!--<br/>
        <b>{{ summeAlleMitMindestensEinemMangel }}</b> von <b>{{ summeAlle }}</b> Hydrant/en haben Mängel:-->
        <table cellspacing="14" style="">
          <tr>
            <td>00</td>
            <td>Hydrant ohne Mangel</td>
            <td>
              {{ summeNurGepruefte - summeAlleMitMindestensEinemMangel }}
            </td>
          </tr>
          <tr v-for="mangel in data.maengel">
            <td>{{ mangel.schluessel }}</td>
            <td>{{ mangel.name }}</td>
            <td>
              {{ data.hydranten.filter((o) => o.status === 1 && o.mangel.filter((o2) => [mangel.schluessel].includes(o2)).length > 0).length }}
            </td>
          </tr>
          <!--<tr>
            <td>00</td>
            <td>Hydrant ohne Mangel</td>
            <td>
              {{ summeNurGepruefte - summeAlleMitMindestensEinemMangel }}
            </td>
          </tr>
          <tr>
            <td>01</td>
            <td>Hinweisschild fehlt</td>
            <td>
              {{ data.hydranten.filter((o) => o.status === 1 && o.mangel.filter((o2) => ['01'].includes(o2)).length > 0).length }}
            </td>
          </tr>
          <tr>
            <td>02</td>
            <td>Hinweisschild nicht lesbar</td>
            <td>
              {{ data.hydranten.filter((o) => o.status === 1 && o.mangel.filter((o2) => ['02'].includes(o2)).length > 0).length }}
            </td>
          </tr>
          <tr>
            <td>03</td>
            <td>Hinweisschild fehlt</td>
            <td>
              {{ data.hydranten.filter((o) => o.status === 1 && o.mangel.filter((o2) => ['03'].includes(o2)).length > 0).length }}
            </td>
          </tr>
          <tr>
            <td>04</td>
            <td>Hinweisschild fehlt</td>
            <td>
              {{ data.hydranten.filter((o) => o.status === 1 && o.mangel.filter((o2) => ['04'].includes(o2)).length > 0).length }}
            </td>
          </tr>
          <tr>
            <td>05</td>
            <td>Hinweisschild fehlt</td>
            <td>
              {{ data.hydranten.filter((o) => o.status === 1 && o.mangel.filter((o2) => ['05'].includes(o2)).length > 0).length }}
            </td>
          </tr>
          <tr>
            <td>06</td>
            <td>Hinweisschild fehlt</td>
            <td>
              {{ data.hydranten.filter((o) => o.status === 1 && o.mangel.filter((o2) => ['06'].includes(o2)).length > 0).length }}
            </td>
          </tr>
          <tr>
            <td>07</td>
            <td>Hinweisschild fehlt</td>
            <td>
              {{ data.hydranten.filter((o) => o.status === 1 && o.mangel.filter((o2) => ['07'].includes(o2)).length > 0).length }}
            </td>
          </tr>
          <tr>
            <td>08</td>
            <td>Hinweisschild fehlt</td>
            <td>
              {{ data.hydranten.filter((o) => o.status === 1 && o.mangel.filter((o2) => ['08'].includes(o2)).length > 0).length }}
            </td>
          </tr>
          <tr>
            <td>09</td>
            <td>Hinweisschild fehlt</td>
            <td>
              {{ data.hydranten.filter((o) => o.status === 1 && o.mangel.filter((o2) => ['09'].includes(o2)).length > 0).length }}
            </td>
          </tr>
          <tr>
            <td>10</td>
            <td>Hinweisschild fehlt</td>
            <td>
              {{ data.hydranten.filter((o) => o.status === 1 && o.mangel.filter((o2) => ['10'].includes(o2)).length > 0).length }}
            </td>
          </tr>
          <tr>
            <td>11</td>
            <td>Hinweisschild fehlt</td>
            <td>
              {{ data.hydranten.filter((o) => o.status === 1 && o.mangel.filter((o2) => ['11'].includes(o2)).length > 0).length }}
            </td>
          </tr>
          <tr>
            <td>12</td>
            <td>Hydrantenventil defekt</td>
            <td>
              {{ data.hydranten.filter((o) => o.status === 1 && o.mangel.filter((o2) => ['12'].includes(o2)).length > 0).length }}
            </td>
          </tr>
          <tr>
            <td>13</td>
            <td>Hydrant zugeparkt</td>
            <td>
              {{ data.hydranten.filter((o) => o.status === 1 && o.mangel.filter((o2) => ['13'].includes(o2)).length > 0).length }}
            </td>
          </tr>-->
        </table>
      </p>
    </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
  }),
  computed: {
    summeAlle() {
      return this.data.hydranten.length;
    },
    summeNurGepruefte() {
      return this.data.hydranten.filter((o) => o.status === 1).length;
    },
    summeAlleMitMindestensEinemMangel() {
      return this.data.hydranten.filter((o) => o.status === 1 && o.mangel.filter((o2) => o2 !== '').length > 0).length
    }
  },
  watch: {
  },
  methods: {
  },
  created() {
    console.log(this.data);
  },
};
</script>
