<template>
    <v-menu
      v-model="fromDateMenu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-row align="center">
          <v-col cols="10">
            <v-text-field
              :prepend-icon="icon"
              :label="label"
              :value="fromDateDisp"
              :disabled="disabled"
              ref="manualInput"
              :rules="myRules"
              @keyup="manualInput();"
              :clearable="clearable"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn
              x-small
              v-on="on"
              :disabled="disabled"
            >
              <v-icon small>mdi-calendar-month</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <v-date-picker
        locale="de-de"
        :min="minDate"
        :max="maxDate"
        v-model="fromDateVal"
        no-title
        @input="fromDateMenu = false"
      ></v-date-picker>
    </v-menu>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'Datum',
    },
    icon: {
      type: String,
      default: 'mdi-calendar-month',
    },
    minDate: {
      type: String,
      default: '',
    },
    maxDate: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default() { return []; },
    },
  },
  data() {
    return {
      fromDateMenu: false,
      fromDateVal: null,
      valid: false,
      myRules: [
        this.$store.state.main.inputValidationRules.datum,
      ],
    };
  },
  watch: {
    value(neu) {
      this.fromDateVal = neu;
    },
    fromDateVal(neu) {
      setTimeout(() => {
        this.$emit('change', neu);
      }, 50);
    },
  },
  computed: {
    fromDateDisp() {
      this.$emit('input', this.fromDateVal);
      // return this.fromDateVal;
      // format date, apply validations, etc. Example below.
      return this.fromDateVal ? this.formatDate(this.fromDateVal) : '';
    },
  },
  methods: {
    manualInput() {
      if (this.$refs.manualInput.valid) {
        const neu = this.$refs.manualInput.lazyValue.split('.');
        if (neu.length === 3) {
          this.fromDateVal = `${neu[2]}-${neu[1]}-${neu[0]}`;
          // this.$emit('change');
        } else {
          this.fromDateVal = '';
          // this.$emit('change');
        }
      }
    },
    formatDate(value) {
      const neu = value.split('-');
      return `${neu[2]}.${neu[1]}.${neu[0]}`;
    },
    emitEvent(value) {
      this.$emit(value);
    },
  },
  created() {
    this.fromDateVal = this.value;
    if (this.rules) {
      this.rules.forEach((r) => {
        this.myRules.push(r);
      });
    }
  },
};
</script>
