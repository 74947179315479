<template>
  <div class="pa-2">
    <p
      v-if="text_titel !== ''"
      class="text-title text--secondary"
    >
      {{ text_titel }}
    </p>
    <p
      v-if="text_beschreibung !== ''"
      class="text-caption text--secondary"
    >
      {{ text_beschreibung }}
    </p>
    <v-checkbox
      v-model="bedingung.alle"
      :label="text_alle"
    ></v-checkbox>
    <SelectNutzergruppen
      v-if="!bedingung.alle"
      v-model="bedingung.gruppen"
      :label="`${text_gruppen}:`"
    />
  </div>
</template>
<script>
import SelectNutzergruppen from '@/components/Inputs/SelectNutzergruppen.vue';

export default {
  components: {
    SelectNutzergruppen,
  },
  props: {
    value: {
      type: Object,
      default() { return {}; },
    },
    text_titel: {
      type: String,
      default: '',
    },
    text_beschreibung: {
      type: String,
      default: '',
    },
    text_alle: {
      type: String,
      default: 'Alle können sehen',
    },
    text_gruppen: {
      type: String,
      default: 'Nur bestimmte können sehen',
    },
  },
  data() {
    return {
      bedingung: {},
    };
  },
  watch: {
    bedingung(neu) {
      this.$emit('input', neu);
    },
  },
  computed: {
  },
  methods: {
  },
  created() {
    this.bedingung = JSON.parse(JSON.stringify(this.value));
  },
};
</script>
