<template>
    <div>
      This is a Routen page
    </div>
</template>

<script>

export default {
  components: {
  },
  props: {
  },
  data: () => ({
  }),
  computed: {
  },
  watch: {
  },
  methods: {
  },
  created() {
  },
};
</script>
