<template>
  <v-container fluid class="ma-0 pa-0 d-flex justify-center">
    <v-container class="ma-0 pa-2">
      <EditKunde
        v-if="editKunde.id"
        :prop_kunde="editKunde"
        :prop_auftraege="prop_auftraege.filter((o) => {
          if (o.kundennummer === editKunde.kundennummer) {
            return o;
          }
        })"
        :prop_kosten="prop_kosten"
        :prop_settings="prop_settings"
        :prop_isSaving_editKunde="isSaving_editKunde"
        :prop_isSaving_delKunde="isSaving_delKunde"
        @close="editKunde = {}"
        @save="saveKunde($event);"
        @del="delKunde($event);"
        @tryGetOrSave="$emit('tryGetOrSave', $event);"
      />
      <v-row>
        <v-col>
          <v-btn
            small color="primary"
            @click="addKunde();"
            :loading="isSaving_addKunde"
          >
            <v-icon>mdi-plus-box</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            clearable dense
            label="Suchen..."
            append-icon="mdi-magnify"
            v-model="search"
            :rules="[
              $store.state.main.inputValidationRules.name,
            ]"
            class="ma-0"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="
          save.kunden
        "
        :search="search"
        :loading="loading"
        :items-per-page="50"
        :header-props="headerProps"
        :footer-props="footerProps"
        no-data-text="Keine Kunden gefunden"
        no-results-text="Keine Kunden gefunden"
        loading-text="Lade Daten... Bitte warten"
        mobile-breakpoint="600"
      >
        <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
          {{ pageStart }} - {{ pageStop }} von {{ itemsLength }}
        </template>
        <template v-slot:item.ident="{ item }">
          <div
            class="primary--text"
            @click="editKunde = item"
            style="cursor:pointer;"
          >
            <b
              v-if="item.ident !== ''"
            >
              {{ item.ident }}
            </b>
            <b v-else>
              --
            </b>
          </div>
        </template>
      </v-data-table>
    </v-container>
  </v-container>
</template>

<script>
// import $ from 'jquery';
import EditKunde from '@/components/Funktionen/400/410-Kunden/EditKunde.vue';

export default {
  components: {
    EditKunde,
  },
  props: {
    prop_settings: {
      type: Object,
      default() { return {}; },
    },
    prop_kunden: {
      type: Array,
      default() { return []; },
    },
    prop_auftraege: {
      type: Array,
      default() { return []; },
    },
    prop_kosten: {
      type: Array,
      default() { return []; },
    },
  },
  data: () => ({
    editKunde: {},
    xLoaded: {
      kunden: {},
    },
    xChanged: {},
    save: {
      settings: {},
      kunden: [],
    },
    isSaving: false,
    isSaving_editKunde: false,
    isSaving_delKunde: false,
    isSaving_addKunde: false,
    loading: false,
    search: '',
    headers: [
      { text: 'Kundennummer', value: 'kundennummer', width: '150px' },
      { text: 'Name', value: 'ident' },
      { text: '', value: 'aktion', sortable: false },
    ],
    headerProps: {
      sortByText: 'Sortiere nach',
    },
    footerProps: {
      itemsPerPageText: 'Elemente pro Seite:',
      itemsPerPageOptions: [5, 10, 20, 50, -1],
      itemsPerPageAllText: 'Alle',
    },
  }),
  computed: {
  },
  watch: {
    prop_settings: {
      handler() {
        this.datenLaden();
      },
      deep: true,
    },
    prop_kunden: {
      handler() {
        this.datenLaden();
      },
      deep: true,
    },
  },
  methods: {
    addKunde() {
      this.isSaving_addKunde = true;
      this.$emit('tryGetOrSave', { type: 'addKunde', data: {} });
    },
    saveKunde(data) {
      this.isSaving_editKunde = true;
      this.$emit('tryGetOrSave', { type: 'saveKunde', data });
    },
    delKunde(data) {
      this.isSaving_delKunde = true;
      this.$emit('tryGetOrSave', { type: 'delKunde', data });
    },
    datenLaden() {
      /*
        Wenn neue Daten übergeben wurden,
        prüfe ob diese sich von den zuletzt geladenen unterscheiden.
        Wenn ja, überschreibe die aktuellen Daten mit den neu übergebenen Daten.
      */
      let anythingUpdated = false;
      if (JSON.stringify(this.prop_settings) !== JSON.stringify(this.xLoaded.settings)) {
        this.save.settings = JSON.parse(JSON.stringify(this.prop_settings));
        this.xLoaded.settings = JSON.parse(JSON.stringify(this.prop_settings));
        anythingUpdated = true;
      }
      this.prop_kunden.forEach((k) => {
        if (!this.save.kunden.filter((o) => o.id === k.id).length > 0) {
          this.save.kunden.push(k);
          this.xLoaded.kunden[k.id] = JSON.stringify(k);
          anythingUpdated = true;
        } else if (
          JSON.stringify(k) !== this.xLoaded.kunden[k.id]
        ) {
          this.save.kunden.splice(
            this.save.kunden.indexOf(this.save.kunden.filter((o) => o.id === k.id)[0]),
            1,
          );
          this.save.kunden.push(k);
          this.xLoaded.kunden[k.id] = JSON.stringify(k);
          anythingUpdated = true;
        }
      });
      this.save.kunden.forEach((k) => {
        if (!this.prop_kunden.filter((o) => o.id === k.id).length > 0) {
          this.save.kunden.splice(
            this.save.kunden.indexOf(this.save.kunden.filter((o) => o.id === k.id)[0]),
            1,
          );
          anythingUpdated = true;
        }
      });
      if (anythingUpdated) {
        this.isSaving = false;
        this.isSaving_addKunde = false;
        if (this.save.settings.lastAddedKunde) {
          [this.editKunde] = this.save.kunden
            .filter((o) => parseInt(o.id, 10) === parseInt(this.save.settings.lastAddedKunde, 10));
        }
        this.isSaving_editKunde = false;
        if (this.save.settings.lastEditedKunde === this.editKunde.id) {
          // this.editKunde = {};
          this.prop_settings.lastEditedKunde = 0;
        }
        this.isSaving_delKunde = false;
        if (this.save.settings.lastDeletedKunde) {
          this.editKunde = {};
        }
        this.save.kunden.sort((a, b) => {
          if (a.kundennummer > b.kundennummer) {
            return -1;
          }
          if (a.kundennummer < b.kundennummer) {
            return 1;
          }
          return 0;
        });
      }
    },
  },
  created() {
    this.datenLaden();
  },
};
</script>
