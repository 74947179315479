export default {
  namespaced: true,
  state: {
    sitecode: '999',
    loading: {
      getData: false,
    },
    saving: false,
    offline: false,
    offlineHideAlert: false,
    store: {
      v: 0,
      settings: {
      },
    },
  },
  getters: {
  },
  mutations: {
    offlineHideAlert(state) {
      state.offlineHideAlert = true;
    },
    setLoading(state, { name, value }) {
      state.loading[name] = value;
    },
    setStateStore(state, { name, val }) {
      state.store[name] = val;
    },
  },
  actions: {
    async tryPostAPI({ commit }, {
      url, func, data,
      router,
    }) {
      if (!url || !func) {
        console.error('Store-f999-tryPostAPI -> Keine url und keine func angegeben');
      } else {
        commit('setLoading', { name: func, value: true });
        const body = new FormData();
        body.append('code', this.state.login.session.code);
        if (data) {
          if (Object.keys(data).length > 0) {
            // console.log(data);
            Object.keys(data).forEach((k) => {
              let value = data[k];
              if (Array.isArray(value)) {
                value = JSON.stringify(value);
              }
              if (Object.is(value)) {
                value = JSON.stringify(value);
              }
              body.append(k, value);
            });
          }
        }
        if (router) {
          console.log(router);
        }
        /* let help = {};
        if (localStorage.getItem(`f${state.sitecode}-${url}?${func}`)) {
          help = JSON.parse(localStorage.getItem(`f${state.sitecode}-${func}`));
        } */
        await fetch(`${process.env.VUE_APP_API_HOST}/${url}?${func}`, {
          method: 'POST',
          body,
        })
          .then((res) => res.json())
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
            this.commit('main/alert', {
              typ: 'error',
              text: 'Bitte die Netzwerkverbindung prüfen.',
            });
          })
          .finally(() => {
            commit('setLoading', { name: func, value: false });
          });
      }
    },
  },
};
