var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"pa-4",domProps:{"innerHTML":_vm._s(_vm.summen)}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.datatable1_headers,"items":_vm.vertraege,"items-per-page":-1,"header-props":_vm.headerProps,"footer-props":_vm.footerProps,"no-data-text":"Keine Verträge gefunden","no-results-text":"Keine Verträge gefunden","loading-text":"Lade Daten... Bitte warten"},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(ref){
var pageStart = ref.pageStart;
var pageStop = ref.pageStop;
var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+" - "+_vm._s(pageStop)+" von "+_vm._s(itemsLength)+" ")]}},{key:"item.kunde",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.kunde.kundennummer)+" ")]}},{key:"item.titel",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{attrs:{"scrollable":"","max-width":"800px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(
              Math.floor(item.zeitBisEndeKuendigungsfrist / 1000 / 60 / 60 / 24)
              <= 7 * 4
              && item.status.nummer !== '7'
            )?_c('v-chip',_vm._g(_vm._b({attrs:{"color":"warning","small":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-alert")]),_vm._v(_vm._s(item.titel))],1):(
              item.status.nummer === '7'
            )?_c('v-chip',_vm._g(_vm._b({attrs:{"color":"error","small":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-alert")]),_vm._v(_vm._s(item.titel))],1):_c('v-chip',_vm._g(_vm._b({attrs:{"small":""}},'v-chip',attrs,false),on),[_vm._v(_vm._s(item.titel))])]}}],null,true),model:{value:(_vm.dialog_infoKuendgungBis[item.id]),callback:function ($$v) {_vm.$set(_vm.dialog_infoKuendgungBis, item.id, $$v)},expression:"dialog_infoKuendgungBis[item.id]"}},[_c('v-card',[_c('v-card-title',{staticClass:"grey lighten-3 mb-4"},[_c('div',{staticStyle:{"width":"100%","font-size":"80%"}},[_c('b',{staticClass:"grey--text"},[_vm._v("Vertragsdaten")])]),_vm._v(" "+_vm._s(item.titel)+" ")]),_c('v-card-text',[(
                Math.floor(item.zeitBisEndeKuendigungsfrist / 1000 / 60 / 60 / 24)
                <= 7 * 4
                || item.status.nummer === '7'
              )?_c('div',{staticClass:"mb-4",staticStyle:{"width":"100%"}},[(
                  Math.floor(item.zeitBisEndeKuendigungsfrist / 1000 / 60 / 60 / 24)
                  <= 7 * 4
                )?_c('v-chip',{attrs:{"color":"warning","small":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-alert")]),_vm._v(" Ende Kündigungsfrist: "),_c('b',{staticClass:"ms-1"},[_vm._v(_vm._s(new Date(item.kuendigungBis).toLocaleString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit', })))])],1):_vm._e(),(
                  item.status.nummer === '7'
                )?_c('v-chip',{attrs:{"color":"error","small":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-alert")]),_vm._v(" Gekündigt zum "),_c('b',{staticClass:"ms-1"},[_vm._v(_vm._s(new Date(item.ende).toLocaleString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit', })))])],1):_vm._e()],1):_vm._e(),_vm._v(" Status: "),_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(_vm._s(item.status.icon))]),_c('b',[_vm._v(_vm._s(item.status.kurz))]),_c('br'),_vm._v(" Vertragsbeginn: "),_c('b',[_vm._v(_vm._s(new Date(item.beginn).toLocaleString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit', })))]),_c('br'),_vm._v(" Vertragsende: "),_c('b',[_vm._v(_vm._s(new Date(item.ende).toLocaleString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit', })))]),(
                Math.floor(item.zeitBisVerlaengerung / 1000 / 60 / 60 / 24) === 1
              )?_c('span',[_vm._v(" (noch 1 Tag) ")]):_vm._e(),(
                Math.floor(item.zeitBisVerlaengerung / 1000 / 60 / 60 / 24) !== 1
              )?_c('span',[_vm._v(" (noch "+_vm._s(Math.floor(item.zeitBisVerlaengerung / 1000 / 60 / 60 / 24))+" Tage) ")]):_vm._e(),_c('br'),_vm._v(" Ende Kündigungsfrist: "),_c('b',[_vm._v(_vm._s(new Date(item.kuendigungBis).toLocaleString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit', })))]),(
                Math.floor(item.zeitBisEndeKuendigungsfrist / 1000 / 60 / 60 / 24) === 1
              )?_c('span',[_vm._v(" (noch 1 Tag) ")]):_vm._e(),(
                Math.floor(item.zeitBisEndeKuendigungsfrist / 1000 / 60 / 60 / 24) !== 1
              )?_c('span',[_vm._v(" (noch "+_vm._s(Math.floor(item.zeitBisEndeKuendigungsfrist / 1000 / 60 / 60 / 24))+" Tage) ")]):_vm._e(),_c('br')],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog_infoKuendgungBis[item.id] = false}}},[_vm._v(" OK ")])],1)],1)],1)]}},{key:"item.ende",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.ende).toLocaleString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit', }))+" ")]}},{key:"item.umsatz",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.umsatz.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }))+" ")]}},{key:"item.zahlungsintervall",fn:function(ref){
              var item = ref.item;
return [(item.zahlungsintervall === 0)?_c('span',[_vm._v(" monatlich ")]):_vm._e(),(item.zahlungsintervall === 1)?_c('span',[_vm._v(" jährlich ")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }