<template>
    <div style="position:relative;">
      <v-row align="center">
        <v-col>
          <v-text-field
            v-model="color"
            label="Hintergrund Farbe"
          ></v-text-field>
        </v-col>
        <v-col cols="2" align="center" class="me-4">
          <v-menu
            v-model="colorMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            origin="right top"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                small
                v-on="on"
              >
                <v-icon small>mdi-eyedropper-variant</v-icon>
              </v-btn>
            </template>
            <v-color-picker
              dot-size="25"
              hide-mode-switch
              hide-inputs
              mode="hexa"
              swatches-max-height="200"
              v-model="color"
            ></v-color-picker>
          </v-menu>
          <!-- <v-btn
            small
            @click="showColorPicker = !showColorPicker"
          >
            <v-icon small>mdi-eyedropper-variant</v-icon>
          </v-btn> -->
        </v-col>
      </v-row>
      <!-- <v-color-picker
        v-if="showColorPicker"
        dot-size="25"
        hide-mode-switch
        hide-inputs
        elevation="6"
        style="
          position:absolute;z-index:10;right:-10px;top:70px;
          border:solid #ffffff 10px;
        "
        mode="hexa"
        swatches-max-height="200"
        v-model="color"
      ></v-color-picker> -->
    </div>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'Datum',
    },
  },
  data() {
    return {
      color: '',
      showColorPicker: false,
      colorMenu: false,
    };
  },
  watch: {
    color(neu) {
      this.$emit('input', neu);
    },
  },
  computed: {
  },
  methods: {
  },
  created() {
    this.color = this.value;
  },
};
</script>
