import $ from 'jquery';

export default {
  namespaced: true,
  state: {
    sitecode: '001',
    loading: false,
    saving: false,
    offline: false,
    offlineHideAlert: false,
    store: {},
  },
  getters: {
  },
  mutations: {
    offlineHideAlert(state) {
      state.offlineHideAlert = true;
    },
  },
  actions: {
    async tryGetOrSaveSettings({ state }, { dataToSave }) {
      let help = {};
      // console.log(dataToSave);
      if (dataToSave) {
        state.saving = true;
        this.commit('main/switchIsSaving', true);
      } else {
        state.loading = true;
        if (localStorage.getItem(`f${state.sitecode}`)) {
          help = JSON.parse(localStorage.getItem(`f${state.sitecode}`));
        }
      }
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/000/${state.sitecode}/?getOrSaveSettings`,
        type: 'post',
        data: {
          code: this.state.login.session.code,
          dataToSave: JSON.stringify(dataToSave),
        },
        success: (response) => {
          if (response.status === 'accessError') {
            this.commit('login/backendErrorSession');
          } else {
            if (dataToSave) {
              this.commit('main/alert', {
                typ: 'success',
                text: 'Speichern erfolgreich.',
              }, { root: true });
            }
            state.offline = false;
            // console.log(response);
            help.xtime = response.xtime;
            help.settings = response.settings;
            localStorage.setItem(`f${state.sitecode}`, JSON.stringify(help));
            state.store = help;
          }
        },
        timeout: 10000,
        error: () => {
          state.offline = true;
          if (dataToSave) {
            this.commit('main/alert', {
              typ: 'error',
              text: 'Fehler! Bitte die Netzwerkverbindung prüfen.',
            }, { root: true });
          }
        },
        complete: () => {
          state.loading = false;
          state.saving = false;
          this.commit('main/switchIsSaving', false);
        },
      });
    },
  },
};
