<template>
  <v-container fluid class="ma-0 pa-0 d-flex justify-center">
    <v-container class="ma-0 pa-2">
      <v-row>
        <v-col>
          <v-dialog
            v-model="dialog_elternbriefe"
            width="800"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                color="primary"
                v-bind="attrs"
                v-on="on"
                :disabled="prop_eltern.length <= 0"
              >
                <v-icon small left>mdi-email-edit</v-icon>
                Elternbriefe Logindaten
              </v-btn>
            </template>

            <v-card>
              <v-card-title>
                Elternbriefe Logindaten
              </v-card-title>

              <v-card-text>
                <p>
                  Mit Klick auf "Zugangsdaten aller Eltern" wird ein PDF mit einem
                  Elternbrief für jedes Elternkonto generiert. In diesem werden die
                  Zugangsdaten für die App mitgeteilt.
                </p>
                <p v-if="this.$store.state.main.save.user.rechte.includes('x|200|set')">
                  Die verwendeten Texte können in den
                  <v-btn text small color="primary"
                    @click="$router.push('/200-set');"
                  >
                    <v-icon left>mdi-open-in-new</v-icon>
                    Einstellungen
                  </v-btn>
                  angepasst werden.
                </p>
                <p>
                  Um einen Elternbrief, nur für ein spezifisches Elternkonto, zu generieren,
                  wählen Sie in der Tabelle das gewünschte Konto aus und klicken oben auf
                  "<v-icon small left>mdi-email-edit</v-icon>Elternbrief Logindaten".
                </p>
                <v-select
                  multiple
                  label="Elternbriefe für Eltern dieser Klasse/n drucken"
                  :items="prop_klassen"
                  v-model="filterKlassen"
                  item-text="name"
                  item-value="id"
                >
                  <template v-slot:selection="data">
                    <span
                      v-if="data.index === 0"
                    >
                      <span
                        class="grey--text text-caption"
                        v-for="(k, idx) in filterKlassenSort"
                        :key="idx"
                      >
                        <v-chip x-small
                          close
                          :input-value="k"
                          @click:close="
                            removeAngezeigteKlasse(prop_klassen.filter((o) => o.id === k)[0].id)
                          "
                        >{{ prop_klassen.filter((o) => o.id === k)[0].name }}</v-chip>
                      </span>
                    </span>
                  </template>
                </v-select>
                <div class="mb-4 d-flex justify-space-around"
                  style="transform:translateY(-15px);"
                >
                  <v-btn x-small
                    @click="filterKlassenAlle();"
                    :disabled="filterKlassen.length === prop_klassen.length"
                  >Alle auswählen</v-btn>
                  <v-btn x-small
                    @click="filterKlassenKeine();"
                    :disabled="filterKlassen.length <= 0"
                  >Alle abwählen</v-btn>
                </div>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-btn
                  small
                  text
                  @click="dialog_elternbriefe = false"
                >
                  schließen
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  small
                  color="primary"
                  @click="createPDF_zugangsdaten();"
                  :loading="loading_createElternbriefZugangsdaten"
                >
                  <v-icon left>mdi-file-pdf-box</v-icon>
                  Zugangsdaten aller Eltern
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row class="ma-0 mt-4">
        <v-col class="py-0">
          <v-text-field
            clearable dense
            label="Suchen..."
            append-icon="mdi-magnify"
            v-model="search"
            :rules="[
              $store.state.main.inputValidationRules.name,
            ]"
            class="ma-0"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="prop_eltern"
        :search="search"
        :loading="loading"
        :items-per-page="20"
        :header-props="headerProps"
        :footer-props="footerProps"
        no-data-text="Keine Eltern gefunden"
        no-results-text="Keine Eltern gefunden"
        loading-text="Lade Daten... Bitte warten"
      >
        <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
          {{ pageStart }} - {{ pageStop }} von {{ itemsLength }}
        </template>
        <template v-slot:item.name="{ item }">
          <v-btn
            v-if="parseInt(prop_query.e, 10) !== parseInt(item.id, 10)"
            text
            color="primary"
            @click="$router.push(`/200-100?e=${item.id}`);"
          >
            {{ item.name }}
          </v-btn>
          <v-dialog
            v-if="parseInt(prop_query.e, 10) === parseInt(item.id, 10)"
            v-model="item.dialog_edit"
            width="800"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                {{ item.name }}
              </v-btn>
            </template>

            <v-card>
              <v-card-title>
                {{ item.name }}
              </v-card-title>

              <v-card-text>
                <v-row class="my-0 mb-6">
                  <v-col class="py-0" align="right">
                    <v-btn
                      x-small
                      @click="createPDF_zugangsdaten(item);"
                      :loading="loading_createElternbriefZugangsdaten"
                    >
                      <v-icon small left>mdi-email-edit</v-icon>
                      Elternbrief Logindaten
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-dialog
                      v-model="dialog_telefonnummern[item.id]"
                      width="500"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          color="primary"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          class="ma-1"
                        >
                          Telefonnummern
                        </v-btn>
                      </template>

                      <v-card>
                        <v-card-title>
                          <b>Telefonnummern</b>
                        </v-card-title>

                        <v-card-text>
                          <v-row
                            v-for="(nummer, idx) in item.telefonnummern"
                            :key="idx"
                            align="center"
                          >
                            <v-col cols="8" class="py-0 pt-4">
                              <v-text-field
                                label="Name"
                                v-model="nummer.name"
                                :rules="[
                                  $store.state.main.inputValidationRules.required,
                                  $store.state.main.inputValidationRules.name,
                                ]"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4" class="d-flex justify-space-between"
                              align="center"
                            >
                              <v-btn color="primary" x-small
                                @click="item.telefonnummern.splice(idx, 1);"
                              >
                                <v-icon small>mdi-minus-box</v-icon>
                              </v-btn>
                            </v-col>
                            <v-col cols="8" class="py-0">
                              <v-text-field
                                label="Nummer"
                                v-model="nummer.nummer"
                                :rules="[
                                  $store.state.main.inputValidationRules.required,
                                  $store.state.main.inputValidationRules.telefon,
                                ]"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4" class="d-flex justify-space-between">
                              <a :href="`tel:${nummer.nummer}`" style="text-decoration:none;">
                                <v-btn x-small>
                                  <v-icon small>mdi-open-in-new</v-icon>
                                  anrufen
                                </v-btn>
                              </a>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col align="center">
                              <v-btn color="primary" x-small
                                @click="item.telefonnummern.push({
                                  id: 0,
                                  id_eltern: item.id,
                                  name: '',
                                  nummer: '',
                                });"
                              >
                                <v-icon small>mdi-plus-box</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="primary"
                            text
                            @click="dialog_telefonnummern[item.id] = false"
                          >
                            OK
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
                <v-row class="my-0 mb-6">
                  <v-col class="py-0">
                    Nutzername: {{ item.nn }}
                  </v-col>
                </v-row>
                <v-row class="my-0">
                  <v-col cols="10" class="py-0">
                    <v-text-field
                      label="Passwort"
                      prepend-icon="mdi-key"
                      v-model="item.nutzer.rootPW"
                      class="pa-0 ma-0"
                      :disabled="true"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" class="py-0" align="center">
                    <v-dialog
                      v-model="item.dialog_resetPW"
                      width="500"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn x-small class="py-4" color="error"
                          v-bind="attrs"
                          v-on="on"
                          :loading="loading_resetPW"
                        >
                          <v-icon small>mdi-lock-reset</v-icon>
                        </v-btn>
                      </template>

                      <v-card>
                        <v-card-title class="text-h5 grey lighten-2">
                          Passwort zurücksetzen?
                        </v-card-title>

                        <v-card-text class="pt-4">
                          Es wird ein zufälliges Passwort generiert und angezeigt.
                          Dieses sollte vom Benutzer so bald wie möglich zu einem
                          beliebigen, sicheren Passwort geändert werden.
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-btn
                            text
                            @click="item.dialog_resetPW = false;"
                          >
                            Nichts tun
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="primary"
                            text
                            @click="tryResetPW(item.nutzer.id);item.dialog_resetPW = false;"
                          >
                            zurücksetzen
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
                <v-row class="my-0">
                  <v-col class="py-0">
                    <v-autocomplete
                      multiple chips
                      label="Kinder"
                      :items="prop_kinder"
                      v-model="item.ids_kinder"
                      item-text="nameMitKlasse"
                      item-value="id"
                      no-data-text="Keine Kinder verfügbar"
                    >
                      <template v-slot:selection="data">
                        <span
                          class="grey--text text-caption"
                        >
                          <v-chip
                            v-bind="data.attrs"
                            small
                            close
                            @click="data.select"
                            @click:close="removeChild(item.id, data.item.id)"
                          >
                            {{ data.item.nameMitKlasse }}
                          </v-chip>
                        </span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <EditUserParameter
                  v-if="item.nutzer"
                  :user="item.nutzer"
                  :bedingungen="['f200']"
                />
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-btn
                  small
                  text
                  @click="item.dialog_edit = false;"
                >
                  Schließen
                </v-btn>
                <v-spacer></v-spacer>
                <v-dialog
                  v-model="item.dialog_del"
                  width="500"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      text
                      color="error"
                      v-bind="attrs"
                      v-on="on"
                      :loading="deletingElternkonto[item.id]"
                      >Löschen</v-btn>
                  </template>

                  <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                      Sicher?
                    </v-card-title>

                    <v-card-text class="pt-4">
                      Es werden sämtliche Daten gelöscht.
                      Auch die Telefonnummern der zugewiesenen
                      Kinder werden mit dem Elternkonto gelöscht.
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-btn
                        color="primary"
                        text
                        @click="item.dialog_del = false;"
                      >
                        Nichts tun
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="error"
                        text
                        @click="
                          dialog_delKind = false;
                          deletingElternkonto[item.id] = true;
                          $router.push('/200-100');
                          $emit('saveSettings', {
                            dataToSave: {
                              hideLoadingAnimation: false,
                              type: 'delEltern',
                              data: {
                                eltern: item,
                                success: 'kindUpdated',
                              },
                            },
                          });
                        "
                      >
                        löschen
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-spacer></v-spacer>
                <v-btn
                  small
                  color="primary"
                  text
                  @click="
                    $emit('saveSettings', {
                      dataToSave: {
                        hideLoadingAnimation: false,
                        type: 'editEltern',
                        data: {
                          eltern: item,
                          success: 'kindUpdated',
                        },
                      },
                    });
                  "
                >
                  Speichern
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-data-table>
    </v-container>
  </v-container>
</template>

<script>
import $ from 'jquery';
import EditUserParameter from '@/components/Inputs/EditUserParameter.vue';

export default {
  components: {
    EditUserParameter,
  },
  props: {
    prop_eltern: {
      type: Array,
      default() { return []; },
    },
    prop_kinder: {
      type: Array,
      default() { return []; },
    },
    prop_klassen: {
      type: Array,
      default() { return []; },
    },
    prop_kindUpdated: {
      type: Boolean,
      default: false,
    },
    prop_query: {
      type: Object,
      default() { return {}; },
    },
  },
  data: () => ({
    dialog_elternbriefe: false,
    dialog_telefonnummern: {},
    expansionPanelEltern: null,
    filterKlassen: [],
    search: '',
    deletingElternkonto: {},
    loading: false,
    loading_resetPW: false,
    loading_createElternbriefZugangsdaten: false,
    headers: [
      { text: 'Nutzername', value: 'nn' },
      { text: 'Name', value: 'name' },
      { text: 'Kinder', value: 'anzahlKinder' },
    ],
    headerProps: {
      sortByText: 'Sortiere nach',
    },
    footerProps: {
      itemsPerPageText: 'Elemente pro Seite:',
      itemsPerPageOptions: [5, 10, 20, 50, -1],
      itemsPerPageAllText: 'Alle',
    },
  }),
  computed: {
    filterKlassenSort() {
      const help = [];
      const alleKlassen = JSON.parse(JSON.stringify(this.prop_klassen));
      alleKlassen.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (b.name > a.name) {
          return -1;
        }
        return 0;
      });
      alleKlassen.forEach((k) => {
        if (this.filterKlassen.includes(k.id)) {
          help.push(k.id);
        }
      });
      return help;
    },
    isSaving: { // Databinding mit vuex state
      get() {
        return this.$store.state.main.isSaving;
      },
      set(value) {
        this.$store.commit('main/switchIsSaving', value);
      },
    },
  },
  watch: {
    dialog_elternbriefe(neu) {
      if (neu === true) {
        const neuFilterKlassen = [];
        this.prop_klassen.forEach((k) => {
          neuFilterKlassen.push(k.id);
        });
        this.filterKlassen = neuFilterKlassen;
      }
    },
    isSaving(neu) {
      if (neu === false) {
        this.loading_resetPW = false;
      }
    },
    prop_kindUpdated(neu) {
      if (neu === true) {
        setTimeout(() => {
          if (this.prop_eltern.filter((o) => o.dialog_edit === true).length > 0) {
            this.prop_eltern.filter((o) => o.dialog_edit === true)[0].dialog_edit = false;
          }
        }, 10);
      }
    },
    prop_query: {
      handler(neu) {
        if (this.prop_eltern.filter((o) => parseInt(o.id, 10) === parseInt(neu.e, 10)).length > 0) {
          this.prop_eltern.filter((o) => parseInt(o.id, 10) === parseInt(neu.e, 10))[0].dialog_edit = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    removeAngezeigteKlasse(id) {
      this.filterKlassen
        .splice(this.filterKlassen.indexOf(this.filterKlassen.filter((o) => o === id)[0]), 1);
    },
    filterKlassenAlle() {
      this.filterKlassen = [];
      this.prop_klassen.forEach((k) => {
        this.filterKlassen.push(k.id);
      });
    },
    filterKlassenKeine() {
      this.filterKlassen = [];
    },
    switchToKind(id) {
      console.log(id);
    },
    removeChild(idEltern, idKind) {
      const index = this.prop_eltern
        .filter((o) => o.id === idEltern)[0].ids_kinder.indexOf(idKind);
      this.prop_eltern
        .filter((o) => o.id === idEltern)[0].ids_kinder.splice(index, 1);
    },
    async tryResetPW(id) {
      this.$store.commit('main/switchIsSaving', true);
      this.loading_resetPW = true;
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/settings/?func=resetPW`,
        type: 'post',
        data: {
          code: this.$store.state.login.session.code,
          data: id,
        },
        success: (response) => {
          if (response.status === 'accessError') {
            this.$store.commit('login/backendErrorSession');
          } else {
            this.prop_eltern.filter((o) => o.nutzer.id === id)[0].nutzer.rootPW = response.neuPW;
            this.$store.commit('main/switchIsSaving', false);
          }
        },
        timeout: this.$store.state.main.ajaxMaxTimeout,
        error: () => {
          // Nutzer konnte nicht gespeichert werden
          this.$store.commit('main/alert', {
            typ: 'error',
            text: 'Das Passwort konnte nicht zurückgesetzt werden. Bitte die Netzwerkverbindung prüfen.',
          }, { root: true });
          this.$store.commit('main/switchIsSaving', false);
        },
      });
    },
    async createPDF_zugangsdaten(eltern) {
      let elternZuDrucken = [];
      let dateiname = '';
      if (!eltern) {
        elternZuDrucken = this.prop_eltern.filter((e) => {
          if (e.kinder.filter((k) => this.filterKlassen.includes(k.id_klasse)).length > 0) {
            return e;
          }
          return null;
        });
        dateiname = 'Zugangsdaten_Alle.pdf';
      } else {
        elternZuDrucken.push(eltern);
        dateiname = `Zugangsdaten_${eltern.nutzer.nn}.pdf`;
      }
      // PDF erstellen:
      this.loading_createElternbriefZugangsdaten = true;
      this.$store.commit('main/switchIsSaving', true);
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/200/200/eltern.php?func=createElternbrief`,
        type: 'post',
        data: {
          code: this.$store.state.login.session.code,
          eltern: JSON.stringify(elternZuDrucken),
        },
        success: () => {
          // Erstelltes PDF downloaden:
          $.ajax({
            url: `${process.env.VUE_APP_API_HOST}/data/download.php`,
            method: 'POST',
            data: {
              code: this.$store.state.login.session.code,
              file: 'temp/elternbrief.pdf',
            },
            xhrFields: {
              responseType: 'blob',
            },
            success: (data) => {
              const a = document.createElement('a');
              const url = window.URL.createObjectURL(data);
              a.href = url;
              a.download = dateiname;
              document.body.append(a);
              a.click();
              a.remove();
              window.URL.revokeObjectURL(url);
            },
            error: () => {
              this.$store.commit('main/alert', {
                typ: 'error',
                text: 'Die angeforderte Datei konnte nicht erzeugt werden. Bitte die Netzwerkverbindung prüfen.',
              }, { root: true });
            },
            complete: () => {
              this.$store.commit('main/switchIsSaving', false);
              this.loading_createElternbriefZugangsdaten = false;
            },
          });
        },
        error: () => {
          this.$store.commit('main/alert', {
            typ: 'error',
            text: 'Die angeforderte Datei konnte nicht erzeugt werden. Bitte die Netzwerkverbindung prüfen.',
          }, { root: true });
          this.$store.commit('main/switchIsSaving', false);
          this.loading_createElternbriefZugangsdaten = false;
        },
        complete: () => {
        },
      });
    },
  },
  created() {
  },
};
</script>
