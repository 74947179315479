<template>
  <div class="view view_Benutzerverwaltung" v-if="doRender">
    <viewTabsTop v-if="tabs.length > 1" :tabs="tabs" />
    <!-- CONTENT -->
    <Benutzer v-if="
      (currentTab.code === '' || currentTab.code === '000')
      && tabs.filter((o) => o.erlaubt === 'x|005|000').length > 0
    "/>
    <Nutzerparameter v-else-if="
      currentTab.code === '010'
      && tabs.filter((o) => o.erlaubt === 'x|005|010').length > 0
    " />
    <Nutzergruppen v-else-if="
      currentTab.code === '020'
      && tabs.filter((o) => o.erlaubt === 'x|005|020').length > 0
    " />
    <p
      v-else
    >
      Zugriff verweigert
    </p>
    <!-- CONTENT -->
    <viewTabsBottom v-if="tabs.length > 1" :tabs="tabs" />
  </div>
</template>

<script>
import ViewTabsTop from '@/components/ViewTabsTop.vue';
import ViewTabsBottom from '@/components/ViewTabsBottom.vue';

import Benutzer from '@/components/Funktionen/Benutzerverwaltung/Benutzer/Benutzer.vue';
import Nutzerparameter from '@/components/Funktionen/Benutzerverwaltung/Nutzerparameter/Nutzerparameter.vue';
import Nutzergruppen from '@/components/Funktionen/Benutzerverwaltung/Nutzergruppen/Nutzergruppen.vue';

export default ({
  name: 'Benutzerverwaltung',
  components: {
    ViewTabsTop,
    ViewTabsBottom,
    Benutzer,
    Nutzerparameter,
    Nutzergruppen,
  },
  data: () => ({
    doRender: false,
    tabs: [],
    mytabs: [
      {
        icon: 'mdi-account-edit',
        title: 'Benutzer',
        route: '/005',
        erlaubt: 'x|005|000',
      },
      {
        icon: 'mdi-tag-multiple',
        title: 'Parameter',
        route: '/005-010',
        erlaubt: 'x|005|010',
      },
      {
        icon: 'mdi-account-group-outline',
        title: 'Gruppen',
        route: '/005-020',
        erlaubt: 'x|005|020',
      },
    ],
    currentTab: {
      code: '',
      query: {},
    },
  }),
  methods: {
    setCurrentTab(route) {
      this.currentTab.code = route.path.replace(this.$router.history.current.path.substring(0, 4), '').replace('-', '');
      this.currentTab.query = route.query;
      this.doRender = true;
    },
  },
  created() {
    if (this.$store.state.main.save.mainmenu_links
      .some((e) => e.route === this.$router.history.current.path.substring(0, 4))) {
      // Wenn Zugriff auf diese View erlaubt
      // erlaube das Rendern
      this.setCurrentTab(this.$router.history.current);
    } else {
      // Wenn Zugriff auf diese View NICHT erlaubt
      // Leite zur ersten erlaubten Seite um
      this.$router.push({ path: this.$store.state.main.save.mainmenu_links[0].route });
      window.location.reload();
    }
    // Tabs erstellen:
    this.mytabs.forEach((tab) => {
      if (
        this.$store.state.main.save.user.rechte.find((element) => {
          let bool = false;
          if (element.includes(tab.erlaubt)) {
            bool = true;
          }
          return bool;
        })
      ) {
        this.tabs.push(tab);
      }
    });
    // Wenn der aktuelle Tabs nicht erlaubt ist, zum ersten erlaubten Tab navigieren:
    if (
      this.tabs.filter((o) => o.route === this.$router.history.current.path).length <= 0
      && this.tabs.length > 0
    ) {
      this.$router.push({ path: this.tabs[0].route });
    }
  },
  beforeRouteUpdate(to, from, next) {
    // Wenn ein neue Tab innerhalb dieser Seite aufgerufen wird
    this.setCurrentTab(to);
    next();
  },
});
</script>

<style>
</style>
