<template>
  <v-container fluid class="ma-0 pa-0 d-flex justify-center">
    <v-container class="ma-0 pa-4">
      <v-row align="center">
        <v-col>
          <v-select
            multiple
            label="Widgets"
            :items="prop_settings.allWidgets"
            v-model="useWidgets"
            item-text="name"
            item-value="id"
          >
            <template v-slot:item="{ item }">
              <span
                v-if="useWidgets.includes(item.id)"
                class="me-2"
              >
                #{{ useWidgets.indexOf(item.id) + 1 }}
              </span>
              {{ item.name }}
            </template>
          </v-select>
        </v-col>
        <v-col cols="4" align="center">
          <v-btn
            small
            @click="saveSettings();"
            :loading="loadingSave"
          >
            Speichern
          </v-btn>
        </v-col>
      </v-row>
      <v-tabs
        v-model="tabs_setWidget"
        show-arrows
        color="primary"
        next-icon="mdi-menu-right"
        prev-icon="mdi-menu-left"
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab
          v-for="(widget, idx) in useWidgets"
          :key="idx"
        >
          #{{ idx + 1 }} {{ widgets[widget].name }}
        </v-tab>
      </v-tabs>
      <v-row>
        <v-col>
          <div
            v-for="(widget, idx) in useWidgets"
            :key="idx"
          >
            <v-card
              v-if="widget === useWidgets[tabs_setWidget]"
              elevation="2"
              class="my-2"
            >
              <v-card-title>{{ widgets[widget].name }}</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col>
                    <p
                      class="text-title text--secondary"
                    >
                      Wer hat Zugriff auf dieses Widget?
                    </p>
                    <!-- <p
                      class="text-caption text--secondary"
                    >
                      Wem soll diese Meldung angezeigt werden?
                    </p> -->
                    <v-checkbox
                      v-model="widgets[widget].bedingung_f000_sehen.alle"
                      label="Jeder kann sehen"
                    ></v-checkbox>
                    <SelectNutzergruppen
                      v-if="!widgets[widget].bedingung_f000_sehen.alle"
                      v-model="widgets[widget].bedingung_f000_sehen.gruppen"
                      label="Nur bestimmte können sehen:"
                    />
                  </v-col>
                </v-row>
                <v-row
                  v-if="widget === 1"
                >
                  <v-col>
                    <p
                      class="text-title text--secondary"
                    >
                      Wer darf Meldungen erstellen und bearbeiten?
                    </p>
                    <v-checkbox
                      v-model="w1_bedingung_meldungErstellen.alle"
                      label="Jeder kann Meldungen erstellen"
                    ></v-checkbox>
                    <SelectNutzergruppen
                      v-if="!w1_bedingung_meldungErstellen.alle"
                      v-model="w1_bedingung_meldungErstellen.gruppen"
                      label="Nur bestimmte können Meldungen erstellen:"
                    />
                  </v-col>
                </v-row>
                <v-row
                  v-if="widget === 1"
                >
                  <v-col>
                    <p
                      class="text-title text--secondary"
                    >
                      An wen sind die Meldungen standardmäßig gerichtet?
                    </p>
                    <SelectNutzergruppen
                      v-model="w1_standardEmpfaenger"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <!-- <v-btn
                  color="primary"
                  text
                >
                  OK
                </v-btn> -->
              </v-card-actions>
            </v-card>
          </div>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col align="center" class="py-4">
          <v-btn
            @click="saveSettings();"
            :loading="loadingSave"
          >
            Speichern
          </v-btn>
        </v-col>
      </v-row> -->
    </v-container>
  </v-container>
</template>

<script>
// import $ from 'jquery';
import SelectNutzergruppen from '@/components/Inputs/SelectNutzergruppen.vue';

export default {
  components: {
    SelectNutzergruppen,
  },
  props: {
    prop_settings: {
      type: Object,
      default() { return {}; },
    },
    prop_widgets: {
      type: Object,
      default() { return {}; },
    },
  },
  data: () => ({
    tabs_setWidget: null,
    loadingSave: false,
    xLoaded: {},
    xChanged: {},
    widgets: {},
    useWidgets: [],
    w1_bedingung_meldungErstellen: '',
    w1_standardEmpfaenger: '',
  }),
  computed: {
  },
  watch: {
    prop_settings() {
      this.getData();
    },
    prop_widgets() {
      this.getData();
    },
    /* moeglicheEntlasszeiten: {
      handler(neu) {
        if (JSON.stringify(neu) !== this.xLoaded.moeglicheEntlasszeiten) {
          this.xChanged.moeglicheEntlasszeiten = true;
        } else {
          this.xChanged.moeglicheEntlasszeiten = false;
        }
      },
      deep: true,
    },
    anwesenheitMeldungSpaetestensBis(neu) {
      if (this.xLoaded.anwesenheitMeldungSpaetestensBis !== JSON.stringify(neu)) {
        this.xChanged.anwesenheitMeldungSpaetestensBis = true;
      } else {
        this.xChanged.anwesenheitMeldungSpaetestensBis = false;
      }
    }, */
  },
  methods: {
    getData() {
      if (this.prop_widgets) {
        const help = JSON.parse(JSON.stringify(this.prop_widgets));
        if (JSON.stringify(help) !== this.xLoaded.widgets) {
          this.widgets = help;
          this.xLoaded.widgets = JSON.stringify(this.widgets);
          this.xChanged.widgets = false;
        }
      }
      if (this.prop_settings) {
        const help = JSON.parse(JSON.stringify(this.prop_settings.useWidgets));
        if (JSON.stringify(help) !== this.xLoaded.useWidgets) {
          this.useWidgets = help;
          this.xLoaded.useWidgets = JSON.stringify(this.useWidgets);
          this.xChanged.useWidgets = false;
        }
        if (this.prop_settings.w1_bedingung_meldungErstellen === '') {
          this.w1_bedingung_meldungErstellen = {
            alle: true,
            gruppen: [],
          };
        } else {
          const n = [];
          this.prop_settings.w1_bedingung_meldungErstellen.split('-').forEach((o) => {
            n.push(parseInt(o, 10));
          });
          this.w1_bedingung_meldungErstellen = {
            alle: false,
            gruppen: n,
          };
        }
        this.xLoaded.w1_bedingung_meldungErstellen = JSON.stringify(
          this.w1_bedingung_meldungErstellen,
        );
        const n = [];
        this.prop_settings.w1_standardEmpfaenger.split('-').forEach((o) => {
          n.push(parseInt(o, 10));
        });
        this.w1_standardEmpfaenger = n;
        this.xLoaded.w1_standardEmpfaenger = JSON.stringify(
          this.w1_standardEmpfaenger,
        );
      }
      this.loadingSave = false;
    },
    saveSettings() {
      this.loadingSave = true;
      this.$emit('savesettings', {
        func: 'saveSettings',
        data: {
          widgets: this.widgets,
          useWidgets: this.useWidgets,
          w1_bedingung_meldungErstellen: this.w1_bedingung_meldungErstellen,
          w1_standardEmpfaenger: this.w1_standardEmpfaenger,
        },
      });
    },
  },
  created() {
    this.getData();
  },
};
</script>
