<template>
  <v-container fluid class="ma-0 pa-0 d-flex justify-center">
    <v-container class="ma-0 pa-0">
      <v-tabs
        fixed-tabs
        v-model="tabs_meldenHistorie"
        class="d-md-none mb-6"
      >
        <v-tab>Melden</v-tab>
        <v-tab>Historie</v-tab>
      </v-tabs>
    </v-container>
  </v-container>
</template>

<script>
// import $ from 'jquery';

export default {
  components: {
  },
  props: {
  },
  data: () => ({
    tabs_meldenHistorie: null,
  }),
  computed: {
  },
  watch: {
  },
  methods: {
  },
  created() {
  },
};
</script>
