<template>
    <div class="ma-2">
      <v-tabs
        fixed-tabs
        v-model="tabs_eigenschaftenRechte"
        class="d-md-none mb-6"
      >
        <v-tab>Eigenschaften</v-tab>
        <v-tab>Rechte</v-tab>
      </v-tabs>
      <v-row>
        <v-col
          cols="12"
          md="6"
          v-if="
            tabs_eigenschaftenRechte === 0 ||
            $vuetify.breakpoint.mdAndUp
          "
        >
          <!-- EIGENSCHAFTEN -->
          <v-container
            class="text-title text--secondary text-uppercase text-center d-none d-md-block"
          >
            Eigenschaften
          </v-container>
          <v-row class="my-0">
            <v-col cols="12" class="pb-0">
              <v-text-field
              type="text"
              label="Name"
              v-model="gruppe.name"
              :rules="[
                $store.state.main.inputValidationRules.required,
                $store.state.main.inputValidationRules.tinytext,
                $store.state.main.inputValidationRules.name,
              ]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="my-0" align="center">
            <v-col cols="1" class="pb-0">
              <v-icon>{{ gruppe.icon }}</v-icon>
            </v-col>
            <v-col cols="11" class="pb-0">
              <v-combobox
                v-model="gruppe.icon"
                :items="$store.state.main.save.settings.icons.parameter"
                label="Icon">
                <template slot="item" slot-scope="data">
                  <v-icon left>{{ data.item }}</v-icon>
                  {{data.item}}
                </template>
              </v-combobox>
            </v-col>
          </v-row>
          <v-row class="my-0">
            <v-col cols="12" class="py-0">
              <v-select
                v-model="gruppe.nutzerparameter"
                :items="$store.state.main.save.nutzerparameter.map(a => a.id)"
                attach chips multiple hide-selected
                label="Nutzerparameter"
              >
                <template v-slot:selection="{ attrs, item, parent, selected }">
                    <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    label
                    small
                    >
                    <v-icon small left>
                    {{
                      $store.state.main.save.nutzerparameter
                        .filter((o) => o.id == item)[0].icon
                    }}
                    </v-icon>
                    <span class="pr-2">
                        {{
                          $store.state.main.save.nutzerparameter
                            .filter((o) => o.id == item)[0].name
                        }}
                    </span>
                    <v-icon
                        small
                        @click.native.stop
                        @click="parent.selectItem(item)"
                    >
                        $delete
                    </v-icon>
                    </v-chip>
                </template>
                <template v-slot:item="{ item }">
                    <v-icon left>
                    {{
                      $store.state.main.save.nutzerparameter
                        .filter((o) => o.id == item)[0].icon
                    }}
                    </v-icon>
                    {{
                      $store.state.main.save.nutzerparameter
                        .filter((o) => o.id == item)[0].name
                    }}
                </template>
                <template v-slot:no-data>
                    <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>
                        Keine weiteren Parameter.
                        </v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row class="my-0">
            <v-col cols="12" class="py-0">
              <v-checkbox
              v-model="gruppe.standard"
              label="Standardgruppe"
              ></v-checkbox>
              <v-checkbox
              v-model="gruppe.forcePwReset"
              label="Passwort zurücksetzen erzwingen"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="6"
          v-if="
            tabs_eigenschaftenRechte === 1 ||
            $vuetify.breakpoint.mdAndUp
          "
        >
          <!-- RECHTE -->
          <v-container
            class="text-title text--secondary text-uppercase text-center d-none d-md-block"
          >
            Rechte
          </v-container>
          <p class="text-caption text--secondary">
            Mitglieder der Gruppe besitzen automatisch die folgenden Rechte:
          </p>
          <v-container
          style="max-height:500px;overflow-y:auto;"
          class="pa-0"
          >
            <v-skeleton-loader
              v-if="rechte.length <= 0"
              type="list-item-three-line"
            ></v-skeleton-loader>
            <v-treeview
              v-else
              selectable open-all
              item-disabled="locked"
              :items="rechte"
              v-model="gruppe.rechte"
            >
              <template v-slot:prepend="{ item }">
                <v-icon
                  :class="{ 'disabled': disabled }"
                >
                  {{ item.icon ? item.icon : 'mdi-key' }}
                </v-icon>
              </template>
            </v-treeview>
          </v-container>
        </v-col>
      </v-row>
    </div>
</template>

<script>
import $ from 'jquery';

export default {
  props: {
    gruppe: {
      type: Object,
      default() { return {}; },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    rechte: [],
    tabs_eigenschaftenRechte: null,
  }),
  watch: {
    gruppe: {
      handler() {
        this.$emit('grpsChanged');
      },
      deep: true,
    },
  },
  methods: {
    async tryGetRechte() {
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/settings/?func=getRechte`,
        type: 'post',
        data: {
          code: this.$store.state.login.session.code,
        },
        success: (response) => {
          if (response.status === 'accessError') {
            this.$store.commit('login/backendErrorSession');
          } else {
            this.rechte = response.rechte[0].children;
          }
        },
        error: () => {
          // Rechte konnten nicht geladen werden
        },
      });
    },
  },
  created() {
    this.tryGetRechte();
  },
};
</script>
