<template>
  <div style="display:inline-block;">
    <v-btn
      v-if="item.anwesenheitsstatus === 0"
      x-small
      color="error"
      :loading="item.anwesenheitsstatusLoading"
      :disabled="!item.anwesend"
      @click="changeAnwesenheitStatus(item);"
    >
      <v-icon left small>
        mdi-account-cancel
      </v-icon>
      Abwesend
    </v-btn>
    <v-btn
      v-if="item.anwesenheitsstatus === 1"
      x-small
      color="success"
      :disabled="!item.anwesend"
      :loading="item.anwesenheitsstatusLoading"
      @click="changeAnwesenheitStatus(item);"
    >
      <v-icon left small>
        mdi-account-check
      </v-icon>
      Anwesend seit {{ item.anwesenheitHeute.zeit_von }}
    </v-btn>
    <v-dialog
      v-if="item.anwesenheitsstatus === 2"
      v-model="item.anwesenheitHeute.dialog_entlassen"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          x-small
          color="warning"
          v-bind="attrs"
          v-on="on"
          :loading="item.anwesenheitsstatusLoading"
        >
          <v-icon left small>
            mdi-account-arrow-up
          </v-icon>
          Entlassen
        </v-btn>
      </template>

      <v-card>
        <v-card-title>
          <b>{{ item.name }} auf abwesend stellen?</b>
        </v-card-title>

        <v-card-text class="pt-4">
          Es werden sämtliche Anwesenheitsdaten gelöscht, als wäre
          {{ item.name }} heute nicht anwesend gewesen.
          Wenn Sie stattdessen nur die Abmeldung rückgängig machen möchten,
          klicken Sie auf "RÜCKG.".
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            small
            color="primary"
            text
            @click="item.anwesenheitHeute.dialog_entlassen = false;"
          >
            Nichts tun
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="warning"
            text
            @click="
              nurEntlassenRueckgaengig = true;
              changeAnwesenheitStatus(item);
              item.anwesenheitHeute.dialog_entlassen = false;
            "
          >
            Rückg.
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="error"
            text
            @click="
              nurEntlassenRueckgaengig = false;
              changeAnwesenheitStatus(item);
              item.anwesenheitHeute.dialog_entlassen = false;
            "
          >
            Abwesend
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    item: {
      type: Object,
      default() { return {}; },
    },
  },
  data: () => ({
  }),
  computed: {
  },
  watch: {
  },
  methods: {
    changeAnwesenheitStatus(item) {
      item.nurEntlassenRueckgaengig = this.nurEntlassenRueckgaengig;
      this.$emit('changeAnwesenheitStatus', item);
    },
  },
  created() {
  },
};
</script>
