<template>
  <v-container fluid class="ma-0 pa-0 d-flex justify-center">
    <v-container class="ma-0 pa-0">
      <div>
        <F150SlotsEdit
          :prop_editslot="editslot"
        />
        <F150SlotsUebersicht
          :prop_idslot="uebersichtslot"
          @close="uebersichtslot = 0;"
        />
        <F150SlotsAnwesenheiten
          v-if="get.s"
          :s="parseInt(get.s, 10)"
        />
        <v-container>
          <ScanQrCode
            @scanned="$store.dispatch(
              `f${sitecode}/tryAnwesenheitAnmelden`,
              { e: $event, router: $router }
            );"
            btntext=""
            btnsize="small"
            btncolor="primary"
            class="me-1"
          />
          <v-btn
            v-if="
              this.$store.state.main.save.user.rechte.includes('x|150|000|adm')
              || this.$store.state.main.save.user.rechte.includes('x|150|000|010')
            "
            small
            class="me-1"
            color="primary"
            @click="createSlot();"
          >
            <v-icon>mdi-plus-box</v-icon>
          </v-btn>
        </v-container>
      </div>
      <v-card
        v-for="slot in store.slots"
        :key="slot.id"
        class="ma-4"
      >
        <i
          style="
            position:absolute;top:5px;
            font-size:70%;
            pointer-events:none;
          "
          class="ps-4"
        >
          {{ slot.sort }}
        </i>
        <v-card-title>
          <v-row>
            <v-col>
              <v-btn
                small text color="primary"
                @click="$router.push({ path: `150?s=${slot.id}` });"
              >
                {{ slot.name }}
              </v-btn>
            </v-col>
            <v-col align="right" cols="4">
              <!-- <v-btn x-small color="primary"
                @click="editslot = JSON.parse(JSON.stringify(slot));"
                class="ma-1"
              >
                <v-icon small>
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn x-small color="error"
                @click="delSlot(slot);"
                class="ma-1"
                :loading="saving.delslot[slot.id]"
              >
                <v-icon small>
                  mdi-delete
                </v-icon>
              </v-btn> -->
              <v-menu
                v-if="
                  slot.bedingungen.f150.bearbeiten.nutzer
                  || slot.bedingungen.f150.sehen.nutzer
                "
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <!--
                    <v-btn
                      text
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-menu</v-icon>
                    </v-btn>
                  -->
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                  >mdi-menu</v-icon>
                </template>
                <v-list>
                  <v-list-item
                    v-if="slot.bedingungen.f150.bearbeiten.nutzer"
                    @click="downloadQRCode(slot)"
                  >
                    <v-list-item-title>
                      <v-icon left>mdi-qrcode</v-icon>
                      QR-Code herunterladen
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="
                      slot.bedingungen.f150.sehen.nutzer
                      || slot.bedingungen.f150.bearbeiten.nutzer
                    "
                    @click="
                      uebersichtslot = JSON.parse(JSON.stringify(slot)).id;
                    "
                  >
                    <v-list-item-title>
                      <v-icon left>mdi-eye-outline</v-icon>
                      Übersicht
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="slot.bedingungen.f150.bearbeiten.nutzer"
                    @click="editslot = JSON.parse(JSON.stringify(slot));"
                  >
                    <v-list-item-title>
                      <v-icon left>mdi-pencil</v-icon>
                      Slot bearbeiten
                    </v-list-item-title>
                  </v-list-item>
                  <v-dialog
                    v-if="slot.bedingungen.f150.bearbeiten.nutzer"
                    v-model="dialog_delSlot[slot.id]"
                    width="500"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-list-item-title>
                          <v-icon left>mdi-delete</v-icon>
                          Slot löschen
                        </v-list-item-title>
                      </v-list-item>
                    </template>

                    <v-card>
                      <v-card-title>
                        Sicher?
                      </v-card-title>

                      <v-card-text>
                        Sämtliche Anwesenheiten dieses Slots werden ebenfalls gelöscht.
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-btn
                          text
                          @click="dialog_delSlot[slot.id] = false"
                        >
                          Nichts tun
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="error"
                          text
                          @click="delSlot(slot);"
                          :loading="saving.delslot[slot.id]"
                        >
                          Slot löschen
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
          <div hidden>{{ saving }}</div>
        </v-card-title>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import $ from 'jquery';
import ScanQrCode from '@/components/Inputs/ScanQrCode.vue';
import F150SlotsEdit from '@/components/Funktionen/100/F150Anwesenheiten/F150SlotsEdit.vue';
import F150SlotsAnwesenheiten from '@/components/Funktionen/100/F150Anwesenheiten/F150SlotsAnwesenheiten.vue';
import F150SlotsUebersicht from '@/components/Funktionen/100/F150Anwesenheiten/F150SlotsUebersicht.vue';

export default {
  components: {
    ScanQrCode,
    F150SlotsEdit,
    F150SlotsAnwesenheiten,
    F150SlotsUebersicht,
  },
  props: {
    sitecode: {
      type: String,
      default: '150',
    },
    get: {
      type: Object,
      default() { return {}; },
    },
  },
  data: () => ({
    store: {},
    lastLoadedStore: {},
    changedStore: {},
    editslot: { id: -1 },
    uebersichtslot: 0,
    dialog_delSlot: {},
  }),
  computed: {
    loadedStore: { // Databinding mit vuex state
      get() {
        return this.$store.state.f150.store;
      },
    },
    saving: { // Databinding mit vuex state
      get() {
        return this.$store.state.f150.saving;
      },
    },
  },
  watch: {
    loadedStore: {
      handler(neu) {
        /*
          Wenn settings in der Datenbank geändert wurde,
          lade diese neu ein.
        */
        if (JSON.stringify(neu.settings) !== this.lastLoadedStore.settings) {
          this.store.settings = neu.settings;
          this.lastLoadedStore.settings = JSON.stringify(neu.settings);
          this.changedStore.settings = false;
        }
        /*
          Wenn slots in der Datenbank geändert wurde,
          lade diese neu ein.
        */
        if (JSON.stringify(neu.slots) !== this.lastLoadedStore.slots) {
          this.store.slots = neu.slots;
          this.lastLoadedStore.slots = JSON.stringify(neu.slots);
          this.changedStore.slots = false;
        }
      },
      deep: true,
    },
    store: {
      handler(neu) {
        /*
          Prüfe ob settings lokal geändert wurde
        */
        if (JSON.stringify(neu.settings) !== this.lastLoadedStore.settings) {
          this.changedStore.settings = true;
        } else {
          this.changedStore.settings = false;
        }
      },
      deep: true,
    },
    /* get(neu) {
      if (neu.s) {
        console.log(`Öffne Slot ${neu.s}`);
      } else {
        console.log('Slot geschlossen');
      }
    }, */
  },
  methods: {
    createSlot() {
      this.editslot = { id: 0 };
    },
    delSlot(slot) {
      this.$store.dispatch('f150/tryGetOrSaveSettings', {
        dataToSave: {
          type: 'delslot',
          data: { delslot: slot },
          texte_success: 'Slot erfolgreich gelöscht.',
        },
      });
    },
    downloadQRCode(slot) {
      this.$store.commit('main/switchIsSaving', true);
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/100/150/?getQR-Code`,
        type: 'post',
        data: {
          code: this.$store.state.login.session.code,
          id: slot.id,
          type: 'slots', // slots_anwesenheiten
          name: slot.name,
        },
        success: () => {
          // Erstelltes PDF downloaden:
          $.ajax({
            url: `${process.env.VUE_APP_API_HOST}/data/download.php`,
            method: 'POST',
            data: {
              code: this.$store.state.login.session.code,
              file: `f100-150/qr_f150_addAnwToSlot_sa_${slot.id}.pdf`,
            },
            xhrFields: {
              responseType: 'blob',
            },
            success: (data) => {
              const a = document.createElement('a');
              const url = window.URL.createObjectURL(data);
              a.href = url;
              a.download = `QR-Code-${slot.name}.pdf`;
              document.body.append(a);
              a.click();
              a.remove();
              window.URL.revokeObjectURL(url);
            },
            error: () => {
              this.$store.commit('main/alert', {
                typ: 'error',
                text: 'Die angeforderte Datei konnte nicht erzeugt werden. Bitte die Netzwerkverbindung prüfen.',
              }, { root: true });
            },
            complete: () => {
              this.$store.commit('main/switchIsSaving', false);
            },
          });
        },
        error: () => {
          this.$store.commit('main/alert', {
            typ: 'error',
            text: 'Die angeforderte Datei konnte nicht erzeugt werden. Bitte die Netzwerkverbindung prüfen.',
          }, { root: true });
          this.$store.commit('main/switchIsSaving', false);
        },
        complete: () => {
        },
      });
    },
  },
  created() {
    this.store = JSON.parse(JSON.stringify(this.$store.state[`f${this.sitecode}`].store));
    /* this.lastLoadedStore.v = JSON.stringify(this.$store.state[`f${this.sitecode}`].store.v);
    this.changedStore.v = false; */
    /*
      Lade settings aus vuex
    */
    this.lastLoadedStore.settings = JSON.stringify(this.$store.state[`f${this.sitecode}`].store.settings);
    this.changedStore.settings = false;
    /*
      Lade slots aus vuex
    */
    this.lastLoadedStore.slots = JSON.stringify(this.$store.state[`f${this.sitecode}`].store.slots);
    this.changedStore.slots = false;
    /*
      Prüfe ob in der url ein QR-Code Scan angefordert wurde
    */
    if (
      this.get.sa // Slots
      || this.get.saa // Slots Anwesenheiten
    ) {
      let url = this.$store.state.main.save.settings.app_url.split('https://').join('');
      if (this.get.sa) {
        url = `https://${url}/150?sa=${this.get.sa}`;
      } else {
        url = `https://${url}/150?saa=${this.get.saa}`;
      }
      this.$store.dispatch(
        `f${this.sitecode}/tryAnwesenheitAnmelden`,
        { e: url, router: this.$router, fromUrl: true },
      );
    }
  },
};
</script>
