<template>
    <v-container
      style="text-align:center;"
    >
      <v-form
        ref="form"
        v-model="valid"
      >
        <p align="left" class="text-title text--secondary">
          Kontoeinstellungen
        </p>
        <v-text-field
          disabled
          label="Nutzername"
          prepend-icon="mdi-account"
          v-model="user.nn"
          :rules="[
            $store.state.main.inputValidationRules.required,
            $store.state.main.inputValidationRules.name,
          ]"
          class="mb-4"
        ></v-text-field>
        <p align="left" class="text-title text--secondary mb-0 pb-0">
          Passwort ändern
        </p>
        <v-text-field
          type="password"
          label="Altes Passwort"
          prepend-icon="mdi-key"
          v-model="userPWalt"
          :rules="[
            $store.state.main.inputValidationRules.required,
            $store.state.main.inputValidationRules.pw,
          ]"
          @keyup.enter="tryChangePW();"
        ></v-text-field>
        <v-text-field
          type="password"
          label="Neues Passwort"
          prepend-icon="mdi-key"
          v-model="userPW"
          :rules="[
            $store.state.main.inputValidationRules.required,
            $store.state.main.inputValidationRules.pw,
            $store.state.main.inputValidationRules.minPW,
          ]"
          @keyup.enter="tryChangePW();"
        ></v-text-field>
        <v-text-field
          type="password"
          label="Neues Passwort wiederholen"
          prepend-icon="mdi-key"
          v-model="userPW2"
          :rules="[
            $store.state.main.inputValidationRules.required,
            $store.state.main.inputValidationRules.pw,
          ]"
          @keyup.enter="tryChangePW();"
        ></v-text-field>
      </v-form>
      <v-btn
        v-if="valid && PWvalid"
        small
        color="success"
        :loading="isSaving"
        @click="tryChangePW();"
        class="my-2"
      >
        <v-icon
        left
        >mdi-content-save</v-icon>
        Passwort ändern
      </v-btn>
    </v-container>
</template>

<script>
import $ from 'jquery';

export default {
  components: {
  },
  props: {
  },
  data: () => ({
    user: null,
    valid: true,
    userPW: '',
    userPW2: '',
    userPWalt: '',
  }),
  computed: {
    PWvalid() {
      let value = false;
      if (this.userPW === this.userPW2) {
        value = true;
      }
      return value;
    },
    isSaving: { // Databinding mit vuex state
      get() {
        return this.$store.state.main.isSaving;
      },
      set(value) {
        this.$store.commit('main/switchIsSaving', value);
      },
    },
  },
  watch: {
    isSaving(neu) {
      if (neu === false) {
        // this.paramsChanged = false;
      }
    },
  },
  methods: {
    tryChangePW() {
      this.$store.commit('main/switchIsSaving', true);
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/settings/?func=changePW`,
        type: 'post',
        data: {
          code: this.$store.state.login.session.code,
          data: this.userPW,
          pwAlt: this.userPWalt,
        },
        success: (response) => {
          if (response.status === 'accessError') {
            this.$store.commit('login/backendErrorSession');
          } else if (response.status === 'errorOldPW') {
            this.$store.commit('main/alert', {
              typ: 'error',
              text: 'Das angegebene Passwort ist falsch. Bitte erneut versuchen.',
            }, { root: true });
            this.$store.commit('main/switchIsSaving', false);
          } else {
            this.$store.commit('main/alert', {
              typ: 'success',
              text: 'Passwort Erfolgreich geändert',
            });
            this.$store.commit('main/switchIsSaving', false);
            this.userPW = '';
            this.userPW2 = '';
            this.userPWalt = '';
            this.$refs.form.resetValidation();
          }
        },
        timeout: this.$store.state.main.ajaxMaxTimeout,
        error: () => {
          // Nutzer konnte nicht gespeichert werden
          this.$store.commit('main/alert', {
            typ: 'error',
            text: 'Die Einstellungen konnten nicht gespeichert werden. Bitte die Netzwerkverbindung prüfen.',
          }, { root: true });
          this.$store.commit('main/switchIsSaving', false);
        },
      });
    },
  },
  created() {
    const user = JSON.stringify(this.$store.state.main.save.user);
    this.user = JSON.parse(user);
  },
};
</script>
