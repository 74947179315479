var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.doRender)?_c('div',{staticClass:"view view_V999Appinfo"},[(_vm.tabs.length > 1)?_c('viewTabsTop',{attrs:{"tabs":_vm.tabs}}):_vm._e(),(
      _vm.$store.state[("f" + _vm.sitecode)].loading.getData
      && !_vm.$store.state[("f" + _vm.sitecode)].offline
    )?_c('v-progress-linear',{staticStyle:{"transform":"translateY(-2px)"},attrs:{"indeterminate":"","absolute":"","color":"primary"}}):_vm._e(),(
      _vm.$store.state[("f" + _vm.sitecode)].offline
      && _vm.$store.state[("f" + _vm.sitecode)].offlineHideAlert
    )?_c('v-alert',{staticStyle:{"transform":"translateY(-2px)","border-radius":"0px"},attrs:{"dense":"","type":"error"}}):_vm._e(),(
      _vm.$store.state[("f" + _vm.sitecode)].offline
      && !_vm.$store.state[("f" + _vm.sitecode)].offlineHideAlert
    )?_c('v-container',[_c('v-alert',{staticClass:"ma-2",staticStyle:{"font-size":"80%"},attrs:{"dense":"","transition":"scale-transition","type":"error"}},[_c('b',[_vm._v("KEINE NETZWERKVERBINDUNG")]),_c('br'),_vm._v(" Die angezeigten Daten können veraltet sein. Bevor Änderungen vorgenommen werden, vergewissern Sie sich, dass die Daten aktuell sind. "),_c('div',{attrs:{"align":"right"}},[_c('v-btn',{attrs:{"small":"","text":""},on:{"click":function($event){return _vm.$store.commit(("f" + _vm.sitecode + "/offlineHideAlert"));}}},[_vm._v("verstanden")])],1)])],1):_vm._e(),(
      _vm.currentTab.code === ''
      && _vm.tabs.filter(function (o) { return o.erlaubt === ("x|" + _vm.sitecode + "|000"); }).length > 0
    )?_c('transition',{attrs:{"name":"custom-classes-transition","enter-active-class":_vm.transitionIn,"leave-active-class":_vm.transitionOut}},[_c('v-container',[_c('v-container',{staticStyle:{"-ms-user-select":"none","-webkit-user-select":"none","moz-user-select":"none","user-select":"none"}},[_c('div',{attrs:{"align":"center"}},[_c('img',{staticStyle:{"border-radius":"8px","pointer-events":"none"},attrs:{"src":"img/login/PNG_Logo_Black-WebulousEcho.png","width":"200px"}})]),_c('div',{staticStyle:{"-ms-user-select":"none","-webkit-user-select":"none","moz-user-select":"none","user-select":"none"},attrs:{"align":"center"}},[_c('p',[_vm._v(" Dieses System wird stetig weiterentwickelt und zur Verfügung gestellt durch Webulous Echo. ")]),_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","outlined":""}},[_vm._v("IT-Service")]),_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","outlined":""}},[_vm._v("Webentwicklung")]),_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","outlined":""}},[_vm._v("Hosting")])],1),_c('div',{staticClass:"pa-4",attrs:{"align":"center"}},[_c('v-row',[_c('v-col',[_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":"https://webulous-echo.com","target":"_blank"}},[_c('v-btn',{attrs:{"small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-open-in-new")]),_vm._v("www.webulous-echo.com")],1)],1)]),_c('v-col',[_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":"mailto:info@webulous-echo.com"}},[_c('v-btn',{attrs:{"small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-email")]),_vm._v("info@webulous-echo.com")],1)],1)])],1)],1)])],1)],1):(
      _vm.currentTab.code === '100'
      && _vm.tabs.filter(function (o) { return o.erlaubt === ("x|" + _vm.sitecode + "|100"); }).length > 0
    )?_c('transition',{attrs:{"name":"custom-classes-transition","enter-active-class":_vm.transitionIn,"leave-active-class":_vm.transitionOut}},[_c('v-container',[_c('v-container',[_c('p',[_vm._v(" Hier werden bald Antworten auf häufig gestellte Fragen angezeigt. ")])])],1)],1):_c('p',[_vm._v(" Zugriff verweigert ")]),(_vm.tabs.length > 1)?_c('viewTabsBottom',{attrs:{"tabs":_vm.tabs}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }