<template>
  <v-container fluid class="ma-0 pa-0">
    <v-container>
      <v-row
        align="center"
        class="mb-2"
      >
        <v-col cols="3">
          <v-btn
            small
            to="/100?u="
          >
            zurück
          </v-btn>
        </v-col>
        <v-col cols="9">
          <p
            v-if="nutzer"
            class="text-title text--secondary mb-0"
          >
            <!-- {{ nutzer.nn }} -->
          </p>
        </v-col>
      </v-row>
      <v-alert
        v-if="offline"
        dense transition="scale-transition"
        type="error"
        style="font-size:80%;"
      >
        <b>KEINE NETZWERKVERBINDUNG</b>
        <br>
        Die angezeigten Daten können veraltet sein.
      </v-alert>
      <v-progress-linear
        indeterminate
        v-if="loading"
      ></v-progress-linear>
      <v-container>
        <v-form
          v-model="valid"
        >
          <SelectNutzergruppen
            :disabled="!$store.state.main.save.user.rechte.includes('x|005|000|020')"
            v-model="nutzergruppen"
            label="Nutzergruppen"
          />
          <EditUserParameter
            v-if="nutzer"
            :user="nutzer"
            :bedingungen="['f100']"
            @change="changed = true"
          />
          <p
            v-else
          >
            Es konnten noch keine Daten zu diesem Nutzer offline gespeichert werden.
          </p>
        </v-form>
      </v-container>
      <v-container style="text-align:center;">
        <v-btn
        v-if="changed && valid"
        small
        color="success"
        class="ml-2"
        :loading="isSaving"
        @click="trySaveUser();"
        >
          <v-icon
          left
          >mdi-content-save</v-icon>
          Änderungen Speichern
        </v-btn>
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
import $ from 'jquery';
import SelectNutzergruppen from '@/components/Inputs/SelectNutzergruppen.vue';
import EditUserParameter from '@/components/Inputs/EditUserParameter.vue';

export default {
  components: {
    SelectNutzergruppen,
    EditUserParameter,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    save: {
      type: Object,
      default() { return {}; },
    },
  },
  data: () => ({
    nutzer: null,
    nutzergruppen: null,
    loading: true,
    offline: false,
    changed: false,
    valid: true,
  }),
  computed: {
    isSaving: { // Databinding mit vuex state
      get() {
        return this.$store.state.main.isSaving;
      },
      set(value) {
        this.$store.commit('main/switchIsSaving', value);
      },
    },
  },
  watch: {
    isSaving(neu) {
      if (neu === false) {
        this.changed = false;
      }
    },
    nutzergruppen(alt, neu) {
      if (neu && JSON.stringify(alt) !== JSON.stringify(neu)) {
        this.changed = true;
      }
    },
  },
  methods: {
    trySaveUser() {
      this.$store.commit('main/switchIsSaving', true);
      this.nutzer.gruppen = this.nutzergruppen;
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/settings/?func=saveNutzer`,
        type: 'post',
        data: {
          code: this.$store.state.login.session.code,
          data: JSON.stringify(this.nutzer),
          neuPW: '',
        },
        success: (response) => {
          if (response.status === 'accessError') {
            this.$store.commit('login/backendErrorSession');
          } else {
            let help = {
              nutzer: [],
            };
            if (localStorage.getItem('f100')) {
              help = JSON.parse(localStorage.getItem('f100'));
            }
            help[this.id] = this.nutzer;
            localStorage.setItem('f100', JSON.stringify(help));
            this.$store.commit('main/alert', {
              typ: 'success',
              text: 'Die Daten wurden erfolgreich gespeichert.',
            });
            this.$store.commit('main/switchIsSaving', false);
          }
        },
        timeout: this.$store.state.main.ajaxMaxTimeout,
        error: () => {
          // Nutzer konnte nicht gespeichert werden
          this.$store.commit('main/alert', {
            typ: 'error',
            text: 'Die Daten konnten nicht gespeichert werden. Bitte die Netzwerkverbindung prüfen.',
          });
          this.tryGetUser();
        },
      });
    },
    async tryGetUser() {
      if (localStorage.getItem('f100')) {
        const help = JSON.parse(localStorage.getItem('f100'));
        if (help[this.id]) {
          this.nutzer = help[this.id];
          this.nutzergruppen = help[`ng_${this.id}`];
        }
      }
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/100/100/?func=getMitglied`,
        type: 'post',
        data: {
          code: this.$store.state.login.session.code,
          id: this.id,
        },
        success: (response) => {
          if (response.status === 'accessError') {
            this.$store.commit('login/backendErrorSession');
          } else {
            if (!this.changed) {
              this.nutzer = response.nutzer;
              this.nutzergruppen = JSON.parse(JSON.stringify(response.nutzer.gruppen));
            }
            let help = {
              nutzer: [],
            };
            if (localStorage.getItem('f100')) {
              help = JSON.parse(localStorage.getItem('f100'));
            }
            help[this.id] = this.nutzer;
            help[`ng_${this.id}`] = this.nutzergruppen;
            localStorage.setItem('f100', JSON.stringify(help));
            this.loading = false;
          }
        },
        timeout: 10000,
        error: () => {
          // Benutzer konnten nicht geladen werden
          this.loading = false;
          this.offline = true;
        },
        complete: () => {
          this.$store.commit('main/switchIsSaving', false);
        },
      });
    },
  },
  created() {
    this.tryGetUser();
  },
};
</script>
