export default {
  namespaced: true,
  state: {
    sitepackagecode: '400',
    sitecode: '420',
    loading: {
      getData: false,
    },
    saving: false,
    offline: false,
    offlineHideAlert: false,
    store: {
      v: 0,
      mitarbeiter: [],
      settings: {},
    },
    events: [],
  },
  getters: {
  },
  mutations: {
    offlineHideAlert(state) {
      state.offlineHideAlert = true;
    },
    setLoading(state, { name, value }) {
      state.loading[name] = value;
    },
    setStore(state, value) {
      state.store = value;
    },
  },
  actions: {
    async tryGetOrSaveSettings({ state, commit }, { dataToSave }) {
      let help = {};
      // console.log('dataToSave', dataToSave);
      this.commit(`f${state.sitecode}/setLoading`, { name: 'getData', value: true });
      const body = new FormData();
      body.append('code', this.state.login.session.code);
      if (dataToSave) {
        state.saving = true;
        this.commit('main/switchIsSaving', true);
        this.commit(`f${state.sitecode}/setLoading`, { name: dataToSave.type, value: true });
        body.append('dataToSave', JSON.stringify(dataToSave));
      } else if (localStorage.getItem(`f${state.sitecode}`)) {
        help = JSON.parse(localStorage.getItem(`f${state.sitecode}`));
      }
      commit('setStore', help);
      await fetch(`${process.env.VUE_APP_API_HOST}/${state.sitepackagecode}/${state.sitecode}/?getOrSaveSettings`, {
        method: 'POST',
        body,
      })
        .then((response) => response.json())
        .then((res) => {
          console.log(res);
          help.settings = res.settings;
          help.mitarbeiter = res.mitarbeiter;
          commit('setStore', help);
          this.commit(`f${state.sitecode}/setLoading`, { name: 'getData', value: false });
          this.commit(`f${state.sitecode}/setLoading`, { name: dataToSave.type, value: false });
        })
        .catch((error) => {
          console.log(error);
          this.commit(`f${state.sitecode}/setLoading`, { name: 'getData', value: false });
          this.commit(`f${state.sitecode}/setLoading`, { name: dataToSave.type, value: false });
        });
    },
  },
};
