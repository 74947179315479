<template>
  <v-dialog
    fullscreen
    scrollable
    transition="dialog-bottom-transition"
    v-model="dialog_editKosten"
    width="1200"
  >
    <v-card>
      <v-card-title class="primary white--text" style="font-size:100%;">
        <v-container class="pa-0 d-flex justify-end">
          {{ editKosten.titel }}
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog_delKosten"
            width="500"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                text dark
                :loading="prop_isSaving_delKosten"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                Kosten löschen?
              </v-card-title>

              <v-card-text class="pt-4">
                Es werden sämtliche Daten und Dokumente gelöscht.
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-btn
                  color="primary"
                  text
                  @click="dialog_delKosten = false;"
                >
                  Nichts tun
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  text
                  @click="
                    dialog_delKosten = false;
                    $emit('del', editKosten.id);
                  "
                >
                  löschen
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn
            text dark
            @click="
              dialog_editKosten = false;
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-container>
      </v-card-title>
      <v-card-text class="pt-4">
        <v-tabs
          fixed-tabs
          v-if="$vuetify.breakpoint.smAndDown"
          v-model="tabs_kostendaten_mobile"
          show-arrows
          color="primary"
          next-icon="mdi-menu-right"
          prev-icon="mdi-menu-left"
          class="mb-2"
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab>Info</v-tab>
          <v-tab>Dokumente</v-tab>
        </v-tabs>
        <v-row>
          <v-col
            cols="12"
            md="6"
            v-if="
              tabs_kostendaten_mobile === 0 ||
              $vuetify.breakpoint.mdAndUp
            "
          >
            <v-container class="pa-0">
              <DatePicker
                v-model="editKosten.datum"
              />
              <v-select
                :items="prop_settings.kostenstellen"
                item-text="name"
                item-value="nummer"
                label="Kostenstelle"
                v-model="editKosten.kostenstelle"
              />
              <v-row>
                <v-col>
                  <v-text-field
                    type="number"
                    label="Wert [€]"
                    v-model="editKosten.betrag"
                    :rules="[
                      $store.state.main.inputValidationRules.nummer,
                    ]"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    type="number"
                    label="enthaltene MwSt [%]"
                    v-model="editKosten.mwst"
                    :rules="[
                      $store.state.main.inputValidationRules.nummer,
                    ]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-text-field
                type="text"
                label="Titel"
                v-model="editKosten.titel"
                :rules="[
                  $store.state.main.inputValidationRules.name,
                ]"
              ></v-text-field>
              <v-textarea
                auto-grow
                label="Beschreibung"
                v-model="editKosten.beschreibung"
                hint=""
              ></v-textarea>
            </v-container>
          </v-col>
          <v-col
            cols="12"
            md="6"
            v-if="
              tabs_kostendaten_mobile === 1 ||
              $vuetify.breakpoint.mdAndUp
            "
          >
            <v-dialog
              v-model="dialog_neuDokument"
              width="500"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn text color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon left>mdi-plus-box</v-icon>
                  hochladen
                </v-btn>
              </template>

              <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                  Dokument hochladen
                </v-card-title>

                <v-card-text class="pt-4">
                  <UploadBilderPDF
                    :apipath="
                      `400/410/kosten_upload.php?key=${this.$store.state.login.session.code}&fill=
                      &id_k=${editKosten.id}`
                    "
                    accept="application/pdf, image/*"
                    @neuDokumente="addDokumente($event);"
                  />
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-btn
                    text
                    @click="dialog_neuDokument = false;"
                  >
                    Schließen
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-data-table
              :headers="headers"
              :items="
                editKosten.dokumente
              "
              :search="search"
              :loading="loading"
              :items-per-page="10"
              :header-props="headerProps"
              :footer-props="footerProps"
              no-data-text="Keine Dokumente gefunden"
              no-results-text="Keine Dokumente gefunden"
              loading-text="Lade Daten... Bitte warten"
              mobile-breakpoint="600"
            >
              <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
                {{ pageStart }} - {{ pageStop }} von {{ itemsLength }}
              </template>
              <template v-slot:item.aktion="{ item }">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon small>mdi-menu</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-dialog
                      v-model="dialog_editDokument[item.id]"
                      width="500"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-list-item-title>
                            <v-icon left>mdi-pencil</v-icon>
                            Bearbeiten
                          </v-list-item-title>
                        </v-list-item>
                      </template>

                      <v-card>
                        <v-card-title class="text-h5 grey lighten-2">
                          Dokument bearbeiten
                        </v-card-title>

                        <v-card-text class="pt-4">
                          <v-select
                            label="Art des Dokuments"
                            :items="prop_settings.auftraegeDokumentarten
                              .filter((da) => da.fuerKosten === 1)"
                            v-model="item.dokumentart"
                            item-text="name"
                            item-value="nummer"
                          />
                          <DatePicker
                            v-model="item.dateUpload2"
                            @change="changeDokumentDatum(item);"
                          />
                          <v-text-field
                            type="text"
                            label="Titel"
                            v-model="item.name"
                            :rules="[
                              $store.state.main.inputValidationRules.name,
                            ]"
                          ></v-text-field>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-btn
                            text
                            @click="dialog_editDokument[item.id] = false;"
                          >
                            Schließen
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn
                            text color="primary"
                            @click="dialog_editDokument[item.id] = false;"
                          >
                            OK
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-list-item
                      @click="tryDownloadDokument(item.pfad, item.name, true);"
                    >
                      <v-list-item-title>
                        <v-icon left>mdi-eye-outline</v-icon>
                        Ansehen
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="tryDownloadDokument(item.pfad, item.name, false);"
                    >
                      <v-list-item-title>
                        <v-icon left>mdi-download</v-icon>
                        Download
                      </v-list-item-title>
                    </v-list-item>
                    <v-dialog
                      v-model="dialog_delDokument[item.id]"
                      width="500"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item
                          v-bind="attrs"
                          v-on="on"
                          :loading="item.loadingDelete"
                        >
                          <v-list-item-title>
                            <v-icon left>mdi-delete</v-icon>
                            Löschen
                          </v-list-item-title>
                        </v-list-item>
                      </template>

                      <v-card>
                        <v-card-title class="text-h5 grey lighten-2">
                          Dokument löschen?
                        </v-card-title>

                        <v-card-text class="pt-4">
                          Das Dokument wird unwiderruflich gelöscht.
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-btn
                            text
                            @click="dialog_delDokument[item.id] = false;"
                          >
                            Nichts tun
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="error"
                            text
                            @click="
                              dialog_delDokument[item.id] = false;
                              tryDelDokument(item.id, item.pfad);
                            "
                          >
                            löschen
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-list>
                </v-menu>
              </template>
              <template v-slot:item.dokumentart="{ item }">
                <v-icon
                  left color="red"
                  v-if="
                    item.dokumentart === 1
                    || item.dokumentart === 2
                  "
                >mdi-file-pdf-box</v-icon>
                <span
                  v-if="item.dokumentart === 1"
                >Rechnung</span>
                <span
                  v-if="item.dokumentart === 2"
                >Angebot</span>
                <v-icon
                  left color="success"
                  v-if="
                    item.dokumentart === 20
                  "
                >mdi-receipt-text-check-outline</v-icon>
                <span
                  v-if="item.dokumentart === 20"
                >Buchungsbeleg</span>
                <v-icon
                  left
                  v-if="
                    item.dokumentart === 100
                  "
                >mdi-upload</v-icon>
                <span
                  v-if="item.dokumentart === 100"
                >Hochgeladen</span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-container
          class="pa-0 d-flex justify-end"
        >
          <v-btn
            text small
            @click="
              dialog_editKosten = false;
            "
          >
            abbrechen
          </v-btn>
          <v-btn
            color="primary"
            text small
            @click="
              $emit('save', editKosten);
            "
            :disabled="xLoaded === JSON.stringify(editKosten)"
            :loading="prop_isSaving_editKosten"
          >
            Speichern
          </v-btn>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import $ from 'jquery';
import DatePicker from '@/components/Inputs/DatePicker.vue';
import UploadBilderPDF from '@/components/Inputs/UploadBilderPDF.vue';

export default {
  components: {
    UploadBilderPDF,
    DatePicker,
  },
  props: {
    prop_kosten: {
      type: Object,
      default() { return {}; },
    },
    prop_settings: {
      type: Object,
      default() { return {}; },
    },
    prop_isSaving_editKosten: {
      type: Boolean,
      default: false,
    },
    prop_isSaving_delKosten: {
      type: Boolean,
      default: false,
    },
    prop_kunden: {
      type: Array,
      default() { return []; },
    },
  },
  data: () => ({
    tabs_kostendaten_mobile: null,
    dialog_neuDokument: false,
    dialog_editKosten: false,
    dialog_delKosten: false,
    dialog_editDokument: {},
    editKosten: {},
    xLoaded: '',
    dialog_delDokument: {},
    loading: false,
    search: '',
    headers: [
      // { text: 'Auftrag', value: 'auftragsnummer', width: '150px' },
      { text: '', value: 'aktion', sortable: false },
      { text: 'Art', value: 'dokumentart', width: '150px' },
      { text: 'Name', value: 'name' },
      { text: 'Upload', value: 'upload' },
    ],
    headerProps: {
      sortByText: 'Sortiere nach',
    },
    footerProps: {
      itemsPerPageText: 'Elemente pro Seite:',
      itemsPerPageOptions: [5, 10, 20, 50, -1],
      itemsPerPageAllText: 'Alle',
    },
  }),
  computed: {
  },
  watch: {
    dialog_editKosten(neu) {
      if (neu === false) {
        setTimeout(() => {
          this.$emit('close');
        }, 400);
      }
    },
  },
  methods: {
    changeDokumentDatum(dokument) {
      const d = dokument.dateUpload2.split('-');
      dokument.dateUpload = `${d[2]}.${d[1]}.${d[0]}`;
      dokument.upload = `${d[2]}.${d[1]}.${d[0]} ${dokument.timeUpload}`;
    },
    addDokumente(dokumente) {
      dokumente.forEach((d) => {
        this.editKosten.dokumente.push(d);
        this.prop_kosten.dokumente.push(d);
      });
      this.dialog_neuDokument = false;
    },
    async tryDelDokument(id, pfad) {
      this.$store.commit('main/switchIsSaving', true);
      if (this.editKosten.dokumente.filter((o) => o.id === id).length > 0) {
        this.editKosten.dokumente.filter((o) => o.id === id)[0].loadingDelete = true;
      }
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/400/410/`,
        type: 'post',
        data: {
          code: this.$store.state.login.session.code,
          dataToSave: JSON.stringify({ type: 'kostenDelDokument', id, pfad }),
        },
        success: (response) => {
          if (response.status === 'accessError') {
            this.$store.commit('login/backendErrorSession');
          } else if (this.editKosten.dokumente.filter((o) => o.id === id).length > 0) {
            this.editKosten.dokumente.splice(
              this.editKosten.dokumente.indexOf(
                this.editKosten.dokumente.filter((o) => o.id === id)[0],
              ),
              1,
            );
            this.prop_kosten.dokumente.splice(
              this.prop_kosten.dokumente.indexOf(
                this.prop_kosten.dokumente.filter((o) => o.id === id)[0],
              ),
              1,
            );
          }
        },
        timeout: 10000,
        error: () => {
          // Einstellungen konnten nicht geladen werden
          this.$store.commit('main/alert', {
            typ: 'error',
            text: `Die Einstellungen konnten nicht gespeichert werden.
            Bitte die Netzwerkverbindung prüfen.`,
          }, { root: true });
        },
        complete: () => {
          this.$store.commit('main/switchIsSaving', false);
          if (this.editKosten.dokumente.filter((o) => o.id === id).length > 0) {
            this.editKosten.dokumente.filter((o) => o.id === id)[0].loadingDelete = false;
          }
        },
      });
    },
    async tryDownloadDokument(path, name, newTab) {
      this.$store.commit('main/switchIsSaving', true);
      if (this.editKosten.dokumente.filter((o) => o.pfad === path).length > 0) {
        this.editKosten.dokumente.filter((o) => o.pfad === path)[0].loadingDownload = true;
      }
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/data/download.php`,
        method: 'POST',
        data: {
          code: this.$store.state.login.session.code,
          file: path,
        },
        xhrFields: {
          responseType: 'blob',
        },
        success: (data) => {
          const a = document.createElement('a');
          const url = window.URL.createObjectURL(data);
          a.href = url;
          if (newTab) {
            a.target = '_blank';
          } else {
            a.download = name;
          }
          document.body.append(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
        },
        error: () => {
          this.$store.commit('main/alert', {
            typ: 'error',
            text: 'Die angeforderte Datei konnte nicht heruntergeladen werden. Bitte die Netzwerkverbindung prüfen.',
          }, { root: true });
        },
        complete: () => {
          this.$store.commit('main/switchIsSaving', false);
          if (this.editKosten.dokumente.filter((o) => o.pfad === path).length > 0) {
            this.editKosten.dokumente.filter((o) => o.pfad === path)[0].loadingDownload = false;
          }
        },
      });
    },
  },
  created() {
    this.editKosten = JSON.parse(JSON.stringify(this.prop_kosten));
    this.xLoaded = JSON.stringify(this.prop_kosten);
    this.dialog_editKosten = true;
  },
};
</script>
