var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('p',{staticClass:"text-title text--secondary mt-2"},[_vm._v(" Startposition ")]),(1 === 0)?_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"type":"text","label":"Straße","prepend-icon":"","rules":[
          _vm.$store.state.main.inputValidationRules.name ]},model:{value:(_vm.settings.strasse),callback:function ($$v) {_vm.$set(_vm.settings, "strasse", $$v)},expression:"settings.strasse"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"type":"number","label":"Hausnummer","prepend-icon":"","rules":[
          _vm.$store.state.main.inputValidationRules.nummer ]},model:{value:(_vm.settings.hausnummer),callback:function ($$v) {_vm.$set(_vm.settings, "hausnummer", $$v)},expression:"settings.hausnummer"}})],1)],1):_vm._e(),(1 === 0)?_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"type":"number","label":"PLZ","prepend-icon":"","rules":[
          _vm.$store.state.main.inputValidationRules.nummer ]},model:{value:(_vm.settings.plz),callback:function ($$v) {_vm.$set(_vm.settings, "plz", $$v)},expression:"settings.plz"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"type":"text","label":"Ort","prepend-icon":"","rules":[
          _vm.$store.state.main.inputValidationRules.name ]},model:{value:(_vm.settings.ort),callback:function ($$v) {_vm.$set(_vm.settings, "ort", $$v)},expression:"settings.ort"}})],1)],1):_vm._e(),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"type":"number","label":"Breitengrad","prepend-icon":"","rules":[
            _vm.$store.state.main.inputValidationRules.koordinaten ]},model:{value:(_vm.settings.coords_lat),callback:function ($$v) {_vm.$set(_vm.settings, "coords_lat", $$v)},expression:"settings.coords_lat"}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"type":"number","label":"Längengrad","prepend-icon":"","rules":[
          _vm.$store.state.main.inputValidationRules.koordinaten ]},model:{value:(_vm.settings.coords_lon),callback:function ($$v) {_vm.$set(_vm.settings, "coords_lon", $$v)},expression:"settings.coords_lon"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"type":"number","label":"Zoom","prepend-icon":"","rules":[
          _vm.$store.state.main.inputValidationRules.koordinaten ]},model:{value:(_vm.settings.zoom),callback:function ($$v) {_vm.$set(_vm.settings, "zoom", $$v)},expression:"settings.zoom"}})],1)],1),(1 === 0)?_c('v-row',[_c('v-col',{attrs:{"align":"center"}},[_c('v-btn',{attrs:{"small":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-content-save")]),_vm._v(" Speichern ")],1)],1)],1):_vm._e(),_c('p',{staticClass:"text-title text--secondary mt-2"},[_vm._v(" Aktionen ")]),_c('v-row',[_c('v-col',{attrs:{"align":"center"}},[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":"","color":"error"}},[_vm._v("mdi-fire-hydrant-alert")]),_vm._v(" Alle Hydranten"),_c('br'),_vm._v("ungeprüft schalten ")],1)]}}]),model:{value:(_vm.dialog_setAllHydrantsUngeprueft),callback:function ($$v) {_vm.dialog_setAllHydrantsUngeprueft=$$v},expression:"dialog_setAllHydrantsUngeprueft"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Sicher? ")]),_c('v-card-text',{staticClass:"pt-4"},[_vm._v(" Es werden sämtliche Hydranten als ungeprüft markiert. ")]),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog_setAllHydrantsUngeprueft = false;}}},[_vm._v(" Nichts tun ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog_setAllHydrantsUngeprueft = false;
                _vm.tryChangeStatusAll('2');}}},[_vm._v(" Bestätigen ")])],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"align":"center"}},[_c('v-btn',{on:{"click":function($event){return _vm.tryCreateAuswertung();}}},[_c('v-icon',{attrs:{"left":"","color":"error"}},[_vm._v("mdi-file-pdf-box")]),_vm._v(" Auswertung erstellen ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }