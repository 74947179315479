<template>
  <v-container fluid class="ma-0 pa-0 d-flex justify-center">
    <v-container class="ma-0 pa-2">
      <F420Lohnabrechnung
       :idMitarbeiter="parseInt(currentTab.query.la_idm, 10)"
      />
      <F420EditMitarbeiter
       :idMitarbeiter="parseInt(currentTab.query.em_idm, 10)"
      />
      <div>
        <v-btn
          x-small
          class="mx-1"
          @click="$store.dispatch('f420/tryGetOrSaveSettings', {});"
          :loading="$store.state.f420.loading.getData"
        >
          <v-icon left small>mdi-reload</v-icon>
          aktualisieren
        </v-btn>
        <v-btn
          color="primary"
          x-small dark text
          class="mx-1"
          @click="$router.push({ path: '/420?em_idm=0' });"
        >
          <v-icon left small>mdi-plus-box</v-icon>
          Neuer Mitarbeiter
        </v-btn>
      </div>
      <v-data-table
        :headers="headers"
        :items="
          $store.state.f420.store.mitarbeiter
        "
        :search="search"
        :loading="$store.state.f420.loading.getData"
        :items-per-page="50"
        :header-props="headerProps"
        :footer-props="footerProps"
        no-data-text="Keine Mitarbeiter gefunden"
        no-results-text="Keine Mitarbeiter gefunden"
        loading-text="Lade Daten... Bitte warten"
        mobile-breakpoint="600"
      >
        <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
          {{ pageStart }} - {{ pageStop }} von {{ itemsLength }}
        </template>
        <template v-slot:item.name="{ item }">
          <v-btn
            color="primary"
            dark small text
            class="mx-2"
            @click="$router.push({ path: `/420?em_idm=${item.id}` });"
          >
            {{ item.name }}
          </v-btn>
        </template>
        <template v-slot:item.aktion="{ item }">
          <v-btn
            color="primary"
            dark small
            class="mx-2"
            @click="$router.push({ path: `/420?la_idm=${item.id}` });"
            :loading="parseInt(currentTab.query.la_idm, 10) === item.id"
          >
            Lohnabrechnung
          </v-btn>
          <!-- <v-btn
            color="primary"
            dark small text
            class="mx-2"
            @click="$router.push({ path: `/420?em_idm=${item.id}` });"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn> -->
        </template>
      </v-data-table>
    </v-container>
  </v-container>
</template>

<script>
import F420Lohnabrechnung from '@/components/Funktionen/400/F420Mitarbeiter/F420Lohnabrechnung.vue';
import F420EditMitarbeiter from '@/components/Funktionen/400/F420Mitarbeiter/F420EditMitarbeiter.vue';

export default {
  components: {
    F420Lohnabrechnung,
    F420EditMitarbeiter,
  },
  props: {
    currentTab: {
      type: Object,
      default() { return {}; },
    },
  },
  data: () => ({
    search: '',
    headers: [
      { text: 'Name', value: 'name' },
      { text: '', value: 'aktion', sortable: false },
    ],
    headerProps: {
      sortByText: 'Sortiere nach',
    },
    footerProps: {
      itemsPerPageText: 'Elemente pro Seite:',
      itemsPerPageOptions: [5, 10, 20, 50, -1],
      itemsPerPageAllText: 'Alle',
    },
  }),
  computed: {
  },
  watch: {
  },
  methods: {
  },
  created() {
    this.$store.dispatch('f420/tryGetOrSaveSettings', {});
  },
};
</script>
