<template>
  <v-container fluid class="ma-0 pa-0 d-flex justify-center">
    <v-container class="ma-0 pa-2 pt-6">
      <v-row no-gutters>
        <v-col
          v-for="(idWidget, idx) in prop_settings.useWidgets.filter((o) => {
            if (prop_widgets[o].nutzer_sehen) {
              return o;
            }
          })"
          :key="idx"
          cols="12" :lg="colsLG"
        >
          <Widget1Newsfeed
            v-if="idWidget === 1"
            :prop_widget="prop_widgets['1']"
            :prop_settings="prop_settings"
          />
          <Widget2KinderAusnahmen
            v-if="idWidget === 2"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
// import $ from 'jquery';
import Widget1Newsfeed from '@/components/Funktionen/000/000-Dashboard/Widgets/Widget1Newsfeed.vue';
import Widget2KinderAusnahmen from '@/components/Funktionen/000/000-Dashboard/Widgets/Widget2KinderAusnahmen.vue';

export default {
  components: {
    Widget1Newsfeed,
    Widget2KinderAusnahmen,
  },
  props: {
    prop_settings: {
      type: Object,
      default() { return {}; },
    },
    prop_widgets: {
      type: Object,
      default() { return {}; },
    },
  },
  data: () => ({
    colsLG: '12',
  }),
  computed: {
  },
  watch: {
  },
  methods: {
  },
  created() {
    let anz = 0;
    Object.keys(this.prop_widgets).forEach((k) => {
      if (this.prop_widgets[k].bedingung_f000_sehen) {
        if (this.prop_widgets[k].bedingung_f000_sehen.nutzer) {
          anz += 1;
        }
      }
    });
    // console.log(anz);
    if (anz <= 1) {
      this.colsLG = '12';
    } else {
      this.colsLG = '6';
    }
  },
};
</script>
