var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ma-2"},[_c('v-tabs',{staticClass:"d-md-none mb-6",attrs:{"fixed-tabs":""},model:{value:(_vm.tabs_eigenschaftenRechte),callback:function ($$v) {_vm.tabs_eigenschaftenRechte=$$v},expression:"tabs_eigenschaftenRechte"}},[_c('v-tab',[_vm._v("Eigenschaften")]),_c('v-tab',[_vm._v("Rechte")])],1),_c('v-row',[(
        _vm.tabs_eigenschaftenRechte === 0 ||
        _vm.$vuetify.breakpoint.mdAndUp
      )?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-container',{staticClass:"text-title text--secondary text-uppercase text-center d-none d-md-block"},[_vm._v(" Eigenschaften ")]),_c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"type":"text","label":"Name","rules":[
            _vm.$store.state.main.inputValidationRules.required,
            _vm.$store.state.main.inputValidationRules.tinytext,
            _vm.$store.state.main.inputValidationRules.name ]},model:{value:(_vm.gruppe.name),callback:function ($$v) {_vm.$set(_vm.gruppe, "name", $$v)},expression:"gruppe.name"}})],1)],1),_c('v-row',{staticClass:"my-0",attrs:{"align":"center"}},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"1"}},[_c('v-icon',[_vm._v(_vm._s(_vm.gruppe.icon))])],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"11"}},[_c('v-combobox',{attrs:{"items":_vm.$store.state.main.save.settings.icons.parameter,"label":"Icon"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(data.item))]),_vm._v(" "+_vm._s(data.item)+" ")]}}],null,false,4271007648),model:{value:(_vm.gruppe.icon),callback:function ($$v) {_vm.$set(_vm.gruppe, "icon", $$v)},expression:"gruppe.icon"}})],1)],1),_c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.$store.state.main.save.nutzerparameter.map(function (a) { return a.id; }),"attach":"","chips":"","multiple":"","hide-selected":"","label":"Nutzerparameter"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
          var attrs = ref.attrs;
          var item = ref.item;
          var parent = ref.parent;
          var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"label":"","small":""}},'v-chip',attrs,false),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" "+_vm._s(_vm.$store.state.main.save.nutzerparameter .filter(function (o) { return o.id == item; })[0].icon)+" ")]),_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(_vm.$store.state.main.save.nutzerparameter .filter(function (o) { return o.id == item; })[0].name)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" $delete ")])],1)]}},{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.$store.state.main.save.nutzerparameter .filter(function (o) { return o.id == item; })[0].icon)+" ")]),_vm._v(" "+_vm._s(_vm.$store.state.main.save.nutzerparameter .filter(function (o) { return o.id == item; })[0].name)+" ")]}},{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Keine weiteren Parameter. ")])],1)],1)]},proxy:true}],null,false,3299719191),model:{value:(_vm.gruppe.nutzerparameter),callback:function ($$v) {_vm.$set(_vm.gruppe, "nutzerparameter", $$v)},expression:"gruppe.nutzerparameter"}})],1)],1),_c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"label":"Standardgruppe"},model:{value:(_vm.gruppe.standard),callback:function ($$v) {_vm.$set(_vm.gruppe, "standard", $$v)},expression:"gruppe.standard"}}),_c('v-checkbox',{attrs:{"label":"Passwort zurücksetzen erzwingen"},model:{value:(_vm.gruppe.forcePwReset),callback:function ($$v) {_vm.$set(_vm.gruppe, "forcePwReset", $$v)},expression:"gruppe.forcePwReset"}})],1)],1)],1):_vm._e(),(
        _vm.tabs_eigenschaftenRechte === 1 ||
        _vm.$vuetify.breakpoint.mdAndUp
      )?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-container',{staticClass:"text-title text--secondary text-uppercase text-center d-none d-md-block"},[_vm._v(" Rechte ")]),_c('p',{staticClass:"text-caption text--secondary"},[_vm._v(" Mitglieder der Gruppe besitzen automatisch die folgenden Rechte: ")]),_c('v-container',{staticClass:"pa-0",staticStyle:{"max-height":"500px","overflow-y":"auto"}},[(_vm.rechte.length <= 0)?_c('v-skeleton-loader',{attrs:{"type":"list-item-three-line"}}):_c('v-treeview',{attrs:{"selectable":"","open-all":"","item-disabled":"locked","items":_vm.rechte},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{class:{ 'disabled': _vm.disabled }},[_vm._v(" "+_vm._s(item.icon ? item.icon : 'mdi-key')+" ")])]}}],null,false,366683021),model:{value:(_vm.gruppe.rechte),callback:function ($$v) {_vm.$set(_vm.gruppe, "rechte", $$v)},expression:"gruppe.rechte"}})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }