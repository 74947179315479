var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-row',{staticClass:"my-0",attrs:{"justify":"center"}},[_c('v-form',{staticStyle:{"width":"100%"},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-expansion-panels',{attrs:{"accordion":""},model:{value:(_vm.expansionPanelStatus),callback:function ($$v) {_vm.expansionPanelStatus=$$v},expression:"expansionPanelStatus"}},_vm._l((_vm.$store.state.main.save.nutzergruppen),function(grp,idx){return _c('v-expansion-panel',{key:idx,staticClass:"grey lighten-5"},[_c('v-expansion-panel-header',[_c('v-icon',{staticStyle:{"flex":"none"},attrs:{"left":""}},[_vm._v(_vm._s(grp.icon))]),_vm._v(" "+_vm._s(grp.name)+" ")],1),_c('v-expansion-panel-content',{staticClass:"white"},[_c('Edit',{attrs:{"gruppe":grp},on:{"grpsChanged":function($event){_vm.grpsChanged = true}}}),_c('v-row',{staticClass:"my-0",attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","color":"error"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v(" \""+_vm._s(grp.name)+"\" Löschen ")],1)]}}],null,true),model:{value:(_vm.dialog_delGruppe),callback:function ($$v) {_vm.dialog_delGruppe=$$v},expression:"dialog_delGruppe"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Sicher? ")]),_c('v-card-text',{staticClass:"pt-4"},[_vm._v(" Es werden sämtliche mit dieser Gruppe zusammenhängende Daten und Informationen gelöscht. ")]),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog_delGruppe = false;}}},[_vm._v(" Nichts tun ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.$store.commit('main/nutzergruppen_delNutzergrp', { idx: idx });
                          _vm.grpsChanged = true;
                          _vm.dialog_delGruppe = false;
                          _vm.expansionPanelStatus = null;}}},[_vm._v(" Ja, löschen ")])],1)],1)],1)],1)],1)],1)}),1)],1)],1),_c('v-row',{staticClass:"mb-2",attrs:{"justify":"center"}},[_c('v-btn',{attrs:{"small":""},on:{"click":function($event){_vm.$store.commit('main/nutzergruppen_addNutzergruppe');
        _vm.grpsChanged = true;
        _vm.expansionPanelStatus = _vm.$store.state.main.save.nutzergruppen.length - 1;}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-box")]),_vm._v(" Gruppe ")],1),(_vm.grpsChanged && _vm.valid)?_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","color":"success","loading":_vm.isSaving},on:{"click":function($event){_vm.$store.dispatch('main/trySaveSettings', { query: 'Nutzergruppen' });
        _vm.expansionPanelStatus = null;}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v(" Änderungen Speichern ")],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }